/*.content-body{

  border: solid 2px coral;
}
*/
h6.contact_heading {
    color: #999;
    font-weight: 600;
}
h4.org-id {
    font-size: 16px;
    color: #999;
}
.card_margin{padding-top:0;}
ul.contact_informations {
    position: relative;
    padding: 0;
    margin: 0 0 10px 0;
    list-style: none;
}
ul.contact_informations li {
    display: inline-block;
    margin: 0 18px 0px 0;
}
ul.contact_informations li span {
    color: #999;
    margin: 0 6px 0 0;
}
span.start_date_color, span.end_date_color {
    display: block;
    font-size: 14px;
    color: #45bbfe;
    margin: 10px 0 0 0;
}
span.color-Blue {
    color: #6b6f80;
    display: block;
}
.text-muted.font-small-3 {
    font-size: 1.12rem !important;
    line-height: 22px;
}
.bg-gradient-x-info {
    background-image: -webkit-gradient(linear, left top, right top, from(#8f84ff ), to(#808fff));
    background-image: -webkit-linear-gradient(left, #8f84ff 0%, #808fff 100%);
    background-image: -moz-linear-gradient(left, #1e9fc4 0%, #69c7de 100%);
    background-image: -o-linear-gradient(left, #1e9fc4 0%, #69c7de 100%);
    background-image: linear-gradient(to right, #8f84ff 0%, #808fff 100%);
    background-repeat: repeat-x;
}
/*#recent-projects .table-responsive {
    height: 300px;
    overflow-y: auto;
}
#recent-projects .table-responsive::-webkit-scrollbar{width:4px; height:4px;}
#recent-projects .table-responsive::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.3);}
#recent-projects .table-responsive::-webkit-scrollbar-thumb{background-color:#139dff;outline:1px solid slategrey;}*/
a.add_btn {
    background: #9c78fc;
    color: #fff !important;
    border-radius: 4px;
    padding: 8px 12px;
    position: relative;
    top: -4px;
    font-size: 1.14rem;
    font-weight: 700;
}

div#createReports {
    padding: 15px;
    background-image: linear-gradient(to right, #f3a2a7, #fa626b);
    background-repeat: repeat-x;
    border-radius: 0 0 calc(0.35rem - 1px) calc(0.35rem - 1px);
}
.cr_reports h5.card-title {
    color: #fff;
}
/* The container */
.container_kkk {
    display: inline-block;
    position: relative;
    padding: 6px 35px 6px 13px;
    background: #f7f7f7;
    margin: 0px 1px 10px;
    cursor: pointer;
    font-size: 14px;
    color: #000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 100px;
}

/* Hide the browser's default checkbox */
.container_kkk input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  background-color: transparent;
}

/* On mouse-over, add a grey background color */
.container_kkk:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.container_kkk input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container_kkk input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container_kkk .checkmark:after {
    left: 9px;
    top: 1px;
    width: 8px;
    height: 15px;
    border: solid #3fbffe;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*==========select All=============*/
.selectAll {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.selectAll input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.selectAll .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border:2px solid #fff;
}

/* On mouse-over, add a grey background color */
.selectAll:hover input ~ .checkmark {
  background-color: #transparent;
}

/* When the checkbox is checked, add a blue background */
.selectAll input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.selectAll .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.selectAll input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.selectAll .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*-------------------edit organization------------------------*/
.card .card {
    -webkit-box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
    box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
}
html body .content .content-wrapper .content-header-title {
    color: #5fa9fe;
}
.breadcrumb .breadcrumb-item a {
    color: rgb(68, 188, 254);
}
.breadcrumb .breadcrumb-item.active {
    color: #000000;
}
.breadcrumb-item + .breadcrumb-item::before {
    color: #828385;
}
button.btn.dropdown-toggle.selectpicker.btn-default {
    position: relative;
    top: 14px;
background: #fff;}

ul.dropdown-menu.inner.selectpicker {
    padding: 0 20px !IMPORTANT;
}
ul.dropdown-menu.inner.selectpicker li a {
    display: block;
}
.contract_pdf {
    text-align: center;
    box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
    position: relative;
    top: 0px;
    border-radius: 6px;
}
.pdf_files {
    font-size: 54px;
    color: #fe3030;
    margin: 0 0 13px 0;
}
.edit_organization_form .col-6{float:left;}
.org_btns {
    padding-top: 35px !IMPORTANT;
}
form label span {
    color: #fe3030;
}
.my_accounts{padding-top:15px; padding-bottom:15px;}
.c_title_t{font-size:14px !important;     color: #999;}
/*===========================================*/
.select2-container{width:100% !important;}
.form-group.select_p_tags {
    position: relative;
}
.form-group.select_p_tags .select2-container{padding-right:127px;}
.btn_create {
    position: absolute;
    right: 0px;
    top: 28px;
}
.form-group span{color:#fe3030;}
/*================================view-school   31/7/19==================================*/
.tags_div {
    overflow: hidden;
    height: 150px;
    overflow-y: auto;
    text-align: left;
    width: 100%;
    position: relative;
}
.admin_tags_report{height: 100px;}
.tags_div::-webkit-scrollbar{width:4px; height:4px;}
.tags_div::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.3);}
.tags_div::-webkit-scrollbar-thumb{background-color:#139dff;outline:1px solid slategrey;}
.tags_div p {
    display: inline-block;
    margin: 5px 10px 10px 0;
    background: #03A9F4;
    color: #fff;
    padding: 2px 12px;
    border-radius: 23px;
}
.school_ststas{margin-top:63px;}
.irreversable_text{ text-transform:uppercase; color:#fe3030;}
img.profile_img {
    background: #fff;
    padding: 10px 10px;
    box-shadow: 0px 1px 2px #ccc;
    display: block;
    width: 180px;
    margin: 15px 15px 15px 15px;
    float: left;
    height: 180px;
}
.class_card{display:block;}
.clearfix{clear:both;}
.class_p_tags{overflow-x:hidden; height:auto; margin: 0 0 10px 0;}
.class_description p{margin-bottom:6px;}
.class_description .contact_heading{margin-bottom:15px;}
img.instructor_img {
    width: 42px;
    height: 42px;
    border-radius: 100px;
    border: 2px solid #fff;
    box-shadow: 0px 2px 3px #ccc;
}
.fa-check-square {
    color: #71d866;
}
span.status_pending {
    color: #fe3030;
}
span.status_approved {
    color: #31de1f;
}
span.status_completed {
    color: #6f6f6f;
}
span.status_draft {
    color: #139dff;
}

/*===========================admin panel starts here================================*/

.generate_report {
    position: absolute !important;
    top: 12px !important;
    right: 12px;
    z-index: 1;
}
.org_detail_pdf {
    text-align: right;
    position: absolute;
    right: 15px;
    top: 67px;
    z-index: 1;
}
.pdf_container {
    margin: 0 0 20px 0;
    padding: 15px;
    border: 1px solid #cacfe7;
    height: 110px;
    overflow-x: auto;
}
.pdf_container ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
	position:relative;
}
.pdf_container ul li a {
    display: block;
    margin: 0 0 9px 0;
    color: #8b8d96;
}
.pdf_container ul li a i {
    display: inline-block;
    margin: 0 25px 0px 0;
    color: #fe3030;
    font-size: 22px;
    vertical-align: top;
}
.edit_organization_form .col-4{float:left;}
/*===============================principal dashboard===========================*/
.display_card{display:block;}
.display_card .add_btn {
    top: 10px;
    right: 14px;
}


a.dropdown-toggle.nav-link.dropdown-user-link {
    top: 6px;
    position: relative;
}


span.status_approved {
color: #31de1f;
}span.status_pending {
color: #fe3030;
}

#noty_layout__topRight {
    position: absolute;
    top: 30px;
    right: 14px;
    background: #ffffff5e;
    padding: 8px 16px;
    border-radius: 26px;
    color: #fff;
    font-weight: 600;
}
.hide{
  display:none;
}
.date_ranger .input-group .react-datepicker-wrapper {
    width: 48%;
    margin: 0 5px 0 0;
}
@-moz-document url-prefix() {
  input.form-control.square.file-type {
    height: auto;
  }
}

@media all and (-ms-high-contrast:none)
     {
     input.form-control.square.file-type { height: auto; } /* IE10 */
     *::-ms-backdrop, input.form-control.square.file-type { height: auto; } /* IE11 */
     }


.table-responsive header {
    height: auto;
    min-height: 8px;
    padding: 0;
}

.sc-ifAKCX {
    text-align: left !important;
    justify-content: center !important;
    word-break: break-all;
}
.sc-ifAKCX:first-child {
    text-align: left !important;
    justify-content: left !important;
    word-break: break-word;
}
.rdt_TableCol_Sortable div {
    font-weight: 600;
    font-size: 14px;
    color: #5e5e5e;
    word-break: break-word;
}
.rdt_TableHead.sc-bwzfXH.gXdTHx {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 11;
    height: 50px;
    border-bottom: 1px solid #ccc;
}
.rdt_TableBody.sc-kpOJdX.bIxPIp {
    overflow-y: auto;
    height: 300px;
    margin: 49px 0 0 0;
    overflow-x: auto;
}

.rdt_TableBody.sc-kpOJdX.bIxPIp ::-webkit-scrollbar{width:4px; height:4px;}
.rdt_TableBody.sc-kpOJdX.bIxPIp ::-webkit-scrollbar-track{-webkit-box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.3);}
.rdt_TableBody.sc-kpOJdX.bIxPIp ::-webkit-scrollbar-thumb{background-color:#139dff;outline:1px solid slategrey;}
.btn .text-center {
    display: inline-block !important;
}
.btn .text-center img{width: 18px !important; height: 18px !important;}
.date_ranger{margin-bottom: 1.8rem;}
.date_ranger .input-group .invalid-feedback {

    display: inline-block;
    width: 48%;
    position: absolute;
    bottom: -20px !important;
    line-height: 9px;

}
.invalid-feedback{display: block;}
.footer.footer-static.footer-light.navbar-border.navbar-shadow {
    display: none;
}

.blue-grey.lighten-2.mb-0 {

    font-size: 1.12em;
    font-weight: 600;

}
.l_acti{ display: block; }
/*=====================responsive media=====================*/
@media only screen and (min-width:1200px) and (max-width:1366px){ul.contact_informations{width:80%;}.generate_report {
    position: absolute!important;
    top: -48px!important;
    right: 0px;
    z-index: 1;
}
.rdt_TableCol_Sortable div {
    font-size: 13px;
}

.blue-grey.lighten-2.mb-0 {

    font-size: 1em;
    font-weight: 600;

}

}
.react-datepicker__input-container{display:block !important;}
.end_feedback {
    position: relative !important;
    bottom: -3px !Important;
    left: 50%;
}
.start_feedback {
    bottom: -3px !Important;
}
/*================05/09/19==============*/
.login-danger {
    position: relative;
    margin: -16px 0 12px 0 !important;
    font-size: 15px;
}
.f-p-detail span {
    font-size: 16px;
}
.email_send {
    margin: -20px 0 15px 0;
    font-size: 15px !important;
    font-weight: 600;
    color: #3ec4fe!important;
}
.org_btns .text-danger {
    text-align: left !important;
}
.resetPassword .invalid-feedback strong{font-size: 16px;}
.main-menu .navigation li.has-sub > a:not(.mm-next):after {
    content: "\f112";
    font-family: 'LineAwesome';
    font-size: 0.85rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 14px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out;
}
.status_pending {
    color: #fe3030 !important;
}
.status_approved {
    color: #31de1f !important;
}

span.footer_text {
    position: fixed;
    bottom: 10px;
    padding: 15px;
    z-index: 1;
    font-size: 12.5px;
}
.react-confirm-alert-body, .react-confirm-alert-button-group{    text-align: center !important;
    justify-content: center !important;
}
.breadcrumb .breadcrumb-item a:hover {
    color: #245DA4;
}
html body .content .content-wrapper {
    padding: 7.2rem 2.2rem 0;
}
p.address_Org{width: 80%;}
.rdt_TableRow:last-child {
    border-bottom: 1px solid rgba(0,0,0,.12);
}

.ffiUQl{text-align: center;}

.school-list-organization-details .iMLKlc:nth-child(5) {
    display: block;
    text-align: center;
}
.school-list-organization-details` .iMLKlc:nth-child(5) span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}
/*
.class-list-school-details .iMLKlc:nth-child(4) {
    display: block;
    text-align: center;
}
.class-list-school-details .iMLKlc:nth-child(4) span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}
.class-list-school-details .iMLKlc:nth-child(5) {
    display: block;
    text-align: center;
}
.class-list-school-details .iMLKlc:nth-child(5) span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}
*/
/*
.instructor-list-school-details .iMLKlc:nth-child(5) {
    display: block;
    text-align: center;
}
.instructor-list-school-details .iMLKlc:nth-child(5) span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}
*/

.class-list-organization .iMLKlc:nth-child(4) {
    display: block;
    text-align: center;
}
.class-list-organization .iMLKlc:nth-child(4) span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}
.class-list-organization .iMLKlc:nth-child(5) {
    display: block;
    text-align: center;
}
.class-list-organization .iMLKlc:nth-child(5) span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}
.class-list-organization .iMLKlc:nth-child(6) {
    display: block;
    text-align: left;
    margin: 15px 0 3px 0;
}


.instructor-list-organization .iMLKlc:nth-child(5) {
    display: block;
    text-align: center;
}
.instructor-list-organization .iMLKlc:nth-child(5) span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}

.class-list-instructor-detail .iMLKlc:nth-child(3) {
    display: block;
    text-align: center;
}
.class-list-instructor-detail .iMLKlc:nth-child(3) span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}
.class-list-instructor-detail .iMLKlc:nth-child(4) {
    display: block;
    text-align: center;
}
.class-list-instructor-detail .iMLKlc:nth-child(4) span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}
/*
.instructor-list-school-details .sc-ifAKCX:nth-child(2) {
    text-align: left !important;
    justify-content: left !Important;
}
*/
.class-list-school-details .sc-ifAKCX:nth-child(2) {
    text-align: left !important;
    justify-content: left !Important;
}

.sub-admin-list .sc-ifAKCX:nth-child(1), .sub-admin-list .sc-ifAKCX:nth-child(2) {
    text-align: left !important;
    justify-content: left !Important;
}



.class-list-school-details .sc-ifAKCX:nth-child(5) {
min-width: 190px;
}
span.text-center {
    width: 100%;
    display: block;
    margin: 4px 0 3px 0;
}
.table th, .table td{ vertical-align: middle; }
.ReactModal__Overlay.ReactModal__Overlay--after-open{background: #fff; z-index: 9999;}
.react-confirm-alert-overlay{z-index: 10000 !Important;}
.rw-multiselect-tag span {
    color: #111;
}

.was-validated .form-control:invalid, .form-control.is-invalid{
    background-image:none;
}


.disabled-link {
  pointer-events: none;
}

a[disabled] {
    pointer-events: none;
}
.main-menu.menu-light .navigation > li > label{    padding: 12px 28px 12px 14px;    display: block;}
.main-menu.menu-light .navigation > li > label i {
    display: inline-block;
    margin-right: 20px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 12%;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
}
.main-menu .navigation li.has-sub > label:not(.mm-next)::after {
    content: "\f112";
    font-family: 'LineAwesome';
    font-size: 0.85rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 14px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out;
}


.saved-reports .iMLKlc{
    text-align: left;
    justify-content: left !important;
}
.saved-reports .iMLKlc span.text-center{text-align: left !important;}
.saved-reports .sc-ifAKCX {
    text-align: left !important;
    justify-content: left !important;
    word-break: break-all;
}
.saved-reports .iMLKlc:last-child {
    justify-content: center !IMPORTANT;
}
.saved-reports .ftSrFl:last-child{justify-content: center !IMPORTANT;}
.edit-calendar .react-datepicker-wrapper {
    width: 100% !important;
    margin: 0 !important;
}
.edit-calendar .invalid-feedback.start_feedback {
    width: 100% !important;
}
.edit-calendar .date_ranger .input-group .invalid-feedback{
    width: 100%;
    display: block;
    left: 0;
}
.edit-calendar .invalid-feedback.end_feedback {
    bottom: -2px !important;
}
.main-menu.menu-light .navigation > li > label .menu-title {
    color: #9d7aff;
}
.btn-floating {
    position: fixed !important;
    top: auto !important;
    bottom: 25px;
    right: 29px;
}
.react-confirm-alert-overlay{z-index: 110000 !important;}
.modal{top:0;}
.la-check-square-o{font-size: 1.15em !important;}


@media only screen and (max-width:479px){
.classes-list .rdt_TableCell:nth-child(5) span.text-center{width:240px;}
.classes-list-principal .rdt_TableCell:nth-child(3) span.text-center, .instructors-list .rdt_TableCell:nth-child(5) span.text-center {
    width: 90px;
}
.classes-list-principal .rdt_TableCell:nth-child(4) span.text-center, .instructor-classes-list .rdt_TableCell:nth-child(4) span.text-center, .class-list-school-details .rdt_TableCell:nth-child(5) span.text-center, .class-list-instructor-detail .rdt_TableCell:nth-child(4) span.text-center{width:190px;}
.reports-list .rdt_TableCell:nth-child(3) span.text-center{width:320px;}
html body .content .content-wrapper {
    padding: 5rem 0.2rem 0;
}

.card{width: 100%;}
.card-title.text-bold-700.my-2 {
    font-size: 15px;
}
a.add_btn{font-size: 13px;}
.dropdown-menu.dropdown-menu-right.show {

    padding: 0px 0px;
    margin: 0 auto;
        margin-top: 0px;
    width: 230px !important;

}
.class_description {
    padding: 0 15px;
}
.vertical-layout.vertical-menu.menu-expanded .main-menu {
    width: 240px;}
    .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
}
.card .card-title {
    font-size: 1rem;
}
.text-muted.font-small-3 {
    font-size: 1rem !important;
}
.col-4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
}
.col-12 {
    padding: 0;
}
.col-md-12 .row.match-height .col-md-12 {
    padding: 0;
}
.header-navbar{min-height: 3rem;}
form.form .col-12 {
    padding: 0 15px;
}
.content-header.row {
    padding: 0 15px;
}
.btn.round.btn-block.btn-glow.btn-bg-gradient-x-purple-blue.col-12.mr-1.mb-1 {
    padding: 0.8em 1em;
}
.text-center a img {
    width: 80%;
}
.font-large-1 {
    font-size: 1.5rem !important;
}
#navbar-mobile .flag-select{top:16px;}
a.dropdown-toggle.nav-link.dropdown-user-link {
    top: 8px;
    margin-left: 9px;
}
#navbar-mobile .flag-select {
    padding-top: 2px;
    height: 30px;
}
.nav-link.close-navbar {
    position: absolute;
    right: 20px;
    top: 0;
    color: #fff;
    background: #66cef4;
    padding: 0px 5px;
    font-weight: 600;
}
.navbar-header .navbar-brand {
    padding: 17px 10px;
    margin-top: 15px;
}
.brand-logo1 {
    width: 160px !important;}
.org_detail_pdf {
    text-align: center;
    position: relative;
    right: auto;
    top: 0;
}
.generate_report {
    right:0 !important;
    top: -48px !important;}
    .list-inline {
    display: block !important;
}
.heading-elements-toggle{display: none;}
.Toastify__toast-container {
    width: 100% !important;
}
.border-right-blue-grey.border-right-lighten-5{border: 0 !important;}
.display_card .add_btn{z-index: 1 !important;}
img.profile_img {
    background: #fff;
padding: 10px 10px;
box-shadow: 0px 1px 2px #ccc;
    display: block;
    width: 90%;
    margin: 0px auto 15px;
    float: none;
    height: auto;
    position: relative;
    top: 20px;
}

}


.menu-collapsed .main-menu.menu-light .navigation > li.active a::after {
    right: -3px !important;
    top: 20px !important;}






















/*  For Organization  */
.principal-list .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.principal-list .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.principal-list .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.principal-list .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}
.principal-list .rdt_TableHeadRow .rdt_TableCol{text-align: left !important;
    justify-content: left !important;}
    .principal-list .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}
.principal-list .rdt_TableHeadRow .rdt_TableCol:nth-child(4), .principal-list .rdt_TableHeadRow .rdt_TableCol:nth-child(5){
    text-align: left !important;
    justify-content: center !important;
}

.school-list .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.school-list .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.school-list .rdt_TableCell:nth-child(3) {
    text-align: center !important;
    justify-content: center !important;
}
.school-list .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}
.school-list .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
    display:block;
}
.school-list .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;
}
.school-list .rdt_TableCell:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;
}

.school-list .rdt_TableHeadRow .rdt_TableCol:nth-child(3), .school-list .rdt_TableHeadRow .rdt_TableCol:nth-child(4), .school-list .rdt_TableHeadRow .rdt_TableCol:nth-child(6) {
    justify-content: center !important;
}
.school-list .iMLKlc:last-child {
    padding-right: calc(48px / 6);
    justify-content: left !important;
    text-align: left !important;
}


.instructors-list .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.instructors-list .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.instructors-list .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.instructors-list .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}
.instructors-list .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
     display:block;
}
.instructors-list .rdt_TableCell:nth-child(6) {
    text-align: left !important;
    justify-content: left !important;
}
.instructors-list .rdt_TableCell:nth-child(7) {
    text-align: center !important;
    justify-content: center !important;
    width: 200px;
}
.instructors-list .rdt_TableHeadRow .rdt_TableCol{text-align: left !important;
    justify-content: left !important;}
    .instructors-list .rdt_TableCell:nth-child(8) {
    text-align: center !important;
    justify-content: center !important;
}
.instructors-list .rdt_TableHeadRow .rdt_TableCol:nth-child(8){text-align: left !important;
    justify-content: center !important;
}
.instructors-list .rdt_TableHeadRow .rdt_TableCol:nth-child(7), .instructors-list .rdt_TableHeadRow .rdt_TableCol:nth-child(4) {
    justify-content: center !important;
}


.classes-list .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.classes-list .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.classes-list .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.classes-list .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
    display: block;
}
.classes-list .rdt_TableCell:nth-child(5) {
    text-align: left !important;
    justify-content: left !important;
    display: block;
}

.classes-list .rdt_TableHeadRow .rdt_TableCol:nth-child(5) {
    justify-content: center !important;
}

.classes-list .rdt_TableCell:nth-child(6) {
    text-align: left !important;
    justify-content: left !important;
}
.classes-list .rdt_TableCell:nth-child(7) {
    text-align: center !important;
    justify-content: center !important;
}
.classes-list .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;
}
.classes-list .rdt_TableCell:nth-child(8) {
    text-align: center !important;
    justify-content: center !important;
}
.classes-list .rdt_TableHeadRow .rdt_TableCol:nth-child(7), .classes-list .rdt_TableHeadRow .rdt_TableCol:nth-child(8){text-align: left !important;
    justify-content: center !important;
}

.instructor-classes-list .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.instructor-classes-list .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.instructor-classes-list .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
     display:block;
}
.instructor-classes-list .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
     display:block;
}
.instructor-classes-list .rdt_TableCell:nth-child(5) {
    text-align: left !important;
    justify-content: left !important;

}
.instructor-classes-list .rdt_TableCell:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;
    width:200px;
}

.instructor-classes-list .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;}
    .instructor-classes-list .rdt_TableCell:nth-child(7) {
    text-align: center !important;
    justify-content: center !important;
}
.instructor-classes-list .rdt_TableHeadRow .rdt_TableCol:nth-child(6), .instructor-classes-list .rdt_TableHeadRow .rdt_TableCol:nth-child(7){
    text-align: left !important;
    justify-content: center !important;
}

.instructor-classes-list .rdt_TableHeadRow .rdt_TableCol:nth-child(4) {
    justify-content: center !important;

}

.reports-list .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.reports-list .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
    width:150px;
}
.reports-list .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.reports-list .rdt_TableCell:nth-child(3) .text-center {
    text-align: left !important;
    justify-content: left !important;
}

.reports-list .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
    width:150px;
}

.reports-list .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;}
.reports-list .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}
.reports-list .rdt_TableHeadRow .rdt_TableCol:nth-child(4), .reports-list .rdt_TableHeadRow .rdt_TableCol:nth-child(5){text-align: left !important;
    justify-content: center !important;
}




/* for Admin */
.organization-list .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.organization-list .rdt_TableCell:nth-child(2) {
    text-align: center !important;
    justify-content: center !important;
}
.organization-list .rdt_TableCell:nth-child(3) {
    text-align: center !important;
    justify-content: center !important;
}
.organization-list .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}

.organization-list .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}
.organization-list .rdt_TableCell:nth-child(6) {
    text-align: left !important;
    justify-content: left !important;
}

.organization-list .rdt_TableCell:nth-child(7) {
    text-align: left !important;
    justify-content: left !important;
}

.organization-list .rdt_TableCell:nth-child(8) {
    text-align: left !important;
    justify-content: left !important;
}
.organization-list .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;
}
    .organization-list .rdt_TableCell:nth-child(9) {
    text-align: center !important;
    justify-content: center !important;
}
.organization-list .rdt_TableHeadRow .rdt_TableCol:nth-child(9){text-align: left !important;
    justify-content: center !important;
}


.school-list-organization-details.rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.school-list-organization-details .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.school-list-organization-details .rdt_TableCell:nth-child(3) {
    text-align: center !important;
    justify-content: center !important;
}
.school-list-organization-details .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}
.school-list-organization-details .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
    display:block;
}
.school-list-organization-details .rdt_TableCell:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;
}
.school-list-organization-details .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;
}
.school-list-organization-details .rdt_TableHeadRow .rdt_TableCol:nth-child(3), .school-list-organization-details .rdt_TableHeadRow .rdt_TableCol:nth-child(4), .school-list-organization-details .rdt_TableHeadRow .rdt_TableCol:nth-child(6) {
    justify-content: center !important;
}
.school-list-organization-details .iMLKlc:last-child {
    padding-right: calc(48px / 6);
    justify-content: left !important;
    text-align: left !important;
}


.instructor-list-school-details .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.instructor-list-school-details .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.instructor-list-school-details .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.instructor-list-school-details .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}
.instructor-list-school-details .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
     display:block;
}
.instructor-list-school-details .rdt_TableCell:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;
}

.instructor-list-school-details .rdt_TableHeadRow .rdt_TableCol{text-align: left !important;
    justify-content: left !important;}

.instructor-list-school-details .rdt_TableHeadRow .rdt_TableCol:nth-child(4), .instructor-list-school-details .rdt_TableHeadRow .rdt_TableCol:nth-child(6) {
    justify-content: center !important;
}




.class-list-school-details .rdt_TableHeadRow .rdt_TableCol:nth-child(5){
    text-align: left !important;
    justify-content: left !important;
}

.class-list-school-details .rdt_TableCell:nth-child(3) {
    display: block;
}


.class-list-school-details .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.class-list-school-details .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}

.class-list-school-details .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}

.class-list-school-details .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}
.class-list-school-details .rdt_TableHeadRow .rdt_TableCol:nth-child(3),.class-list-school-details .rdt_TableHeadRow .rdt_TableCol:nth-child(4), .class-list-school-details .rdt_TableHeadRow .rdt_TableCol:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}



.class-list-school-details .rdt_TableCell:nth-child(6) {
    text-align: left !important;
    justify-content: left !important;
}

.class-list-instructor-detail .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;
}

.class-list-instructor-detail .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.class-list-instructor-detail .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.class-list-instructor-detail .rdt_TableCell:nth-child(3) {
    text-align: center !important;
    justify-content: center !important;
}
.class-list-instructor-detail .rdt_TableCell:nth-child(4) {
    text-align: left !important;
    justify-content: left !important;
    display: block;
}

.class-list-instructor-detail .rdt_TableHeadRow .rdt_TableCol:nth-child(4){
    text-align: center !important;
    justify-content: center !important;
}

.class-list-instructor-detail .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}

.class-list-instructor-detail .rdt_TableCell:nth-child(5) .text-center {
    text-align: left !important;
    justify-content: left !important;
}
.class-list-instructor-detail .rdt_TableCell:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;
}
.class-list-instructor-detail .rdt_TableHeadRow .rdt_TableCol:nth-child(5), .class-list-instructor-detail .rdt_TableHeadRow .rdt_TableCol:nth-child(6){
    text-align: center !important;
    justify-content: center !important;
}




.instructor-list-admin-organization .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.instructor-list-admin-organization .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.instructor-list-admin-organization .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.instructor-list-admin-organization .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}
.instructor-list-admin-organization .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
     display:block;
}
.instructor-list-admin-organization .rdt_TableCell:nth-child(6) {
    text-align: left !important;
    justify-content: left !important;
}
.instructor-list-admin-organization .rdt_TableCell:nth-child(7) {
    text-align: center !important;
    justify-content: center !important;
    width: 200px;
}
.instructor-list-admin-organization .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;
}
.instructor-list-admin-organization .rdt_TableHeadRow .rdt_TableCol:nth-child(4), .instructor-list-admin-organization .rdt_TableHeadRow .rdt_TableCol:nth-child(7) {
    justify-content: center !important;
}



.class-list-admin-organization .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.class-list-admin-organization .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.class-list-admin-organization .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.class-list-admin-organization .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
    display: block;
}
.class-list-admin-organization .rdt_TableCell:nth-child(5) {
    text-align: left !important;
    justify-content: left !important;
    display: block;
}

.class-list-admin-organization.rdt_TableHeadRow .rdt_TableCol:nth-child(5) {
    justify-content: center !important;
}

.class-list-admin-organization .rdt_TableCell:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;
}
.class-list-admin-organization .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;
}
.class-list-admin-organization .rdt_TableHeadRow .rdt_TableCol:nth-child(6){
    text-align: center !important;
    justify-content: center !important;
}

.saved-reports .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.saved-reports .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
    width:150px;
}
.saved-reports .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.saved-reports .rdt_TableCell:nth-child(3) .text-center {
    text-align: left !important;
    justify-content: left !important;
}

.saved-reports .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
    width:150px;
}

.saved-reports .rdt_TableHeadRow .rdt_TableCol{text-align: left !important;
    justify-content: left !important;}
.saved-reports .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}


.saved-reports .rdt_TableHeadRow .rdt_TableCol:nth-child(4), .saved-reports .rdt_TableHeadRow .rdt_TableCol:nth-child(5){
    text-align: left !important;
    justify-content: center !important;
}




.vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > i {
    line-height: 40px;

}
.navbar-header .navbar-brand .brand-logo {
    width: 36px;
    position: relative;
    top: -5px;
}
.brand-logo1 {
    width: 174px;
    position: relative;
    top: -14px;
}
.add_btn.pull-right.btn_delete {
    background: #fa626b;
}
.text-muted.font-small-3.view_c {
    font-size: .9em !important;
}
.ReactModal__Content.ReactModal__Content--after-open{border:1px solid #555 !important;}
.header-navbar.navbar-expand-md.navbar.navbar-with-menu.navbar-without-dd-arrow.fixed-top.navbar-semi-light {

    padding: 0 !important;

}
.rw-multiselect-tag {
    position: relative;
    top: -5px;
}
.rw-multiselect-taglist {
    padding: 4px 0 !important;
}

.react-confirm-alert-button-group > button{
    background-color: #fa626b;
    border-color: #fa626b;
    padding: 0.75rem 1.5rem !important;
font-size: 1rem !important;
line-height: 1.25;
    border-radius: 0.25rem;
}
.react-confirm-alert-button-group > button:first-child {
    background-color: #fa626b;
    border-color: #fa626b;
}
.react-confirm-alert-button-group>button, .react-confirm-alert-button-group>button:last-child {
    background: #333;
    border-color: #333;
}


.react-confirm-alert-body {
    border-radius: 0 !important;
    color: #666 !important;
    border: 1px solid #ccc !important;
    max-width: 500px !important;
margin: 1.75rem auto !important;

}
.Toastify__toast-container{width:450px ;}
.Toastify__close-button.Toastify__close-button--success {
    top: -11px;
    position: relative;
    right: -6px;
    font-size: 20px;
}
.contract_pdf{min-height: 166px;}



.pis {display: none;}

.uploadpreview {
    width: 120px;
    height: 120px;
    display: block;
    border: 1px solid #ccc;
    margin: 0 auto 15px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100px;
}
.banner_pic {
    margin: 0 auto;
    display: block;
    text-align: center;
    position: relative;
}
.upload-wrap {
    float: none;
    width: 26%;
    position: relative;
    margin: 0 auto 20px;
    display: block;
    height: auto;
    text-align: center;
}

.upload-wrap input[type="file"] {
    color: transparent;
    width: 120px;
    margin: 0 auto;
    position: absolute;
    display: block;
    text-align: center !IMPORTANT;
    top: 0px;
    left: 0%;
    border: 0 !important;
    z-index: 0;
    background: transparent;
    font-size: 0;
    padding: 0;
    height: 120px !important;
    box-shadow: 0px 0px;
    border-radius: 100%;
    opacity: 0;
}

.upload-wrap:focus, .upload-wrap input[type="file"]:focus {
    outline: 0;
}





.classes-list-principal .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.classes-list-principal .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}

.classes-list-principal .rdt_TableCell:nth-child(3) {
    text-align: center !important;
    justify-content: center !important;
    display: block;
}
.classes-list-principal .rdt_TableCell:nth-child(4) {
    text-align: left !important;
    justify-content: left !important;
    display: block;
}

.classes-list-principal .rdt_TableHeadRow .rdt_TableCol:nth-child(4) {
    justify-content: center !important;
}

.classes-list-principal .rdt_TableCell:nth-child(5) {
    text-align: left !important;
    justify-content: left !important;
}
.classes-list-principal .rdt_TableCell:nth-child(6) {
    text-align: left !important;
    justify-content: left !important;
    width: 200px;
}
.classes-list-principal .rdt_TableHeadRow .rdt_TableCol{text-align: left !important;
    justify-content: left !important;}
    .classes-list-principal .rdt_TableCell:nth-child(7) {
    text-align: center !important;
    justify-content: center !important;
}
.classes-list-principal .rdt_TableHeadRow .rdt_TableCol:nth-child(7){text-align: left !important;
    justify-content: center !important;
}
.card-header-status .card-title {
    margin: 0 0 3px 0;
}
.card-header.card-header_name {
    padding-bottom: 8px;
}
.ins_pri_card{min-height:200px;}
.col-md-4.col-12.text-center .blue-grey.lighten-2.mb-0 {
    font-weight: 400;
}
.ins_pri_card .contact_informations {
    width: 100% !important;
    line-height: 25px;
}
.ins_pri_card .contact_informations li{ margin-right: 0px !important;word-break: break-all; }

.principal-license{ margin-bottom: 15px;  }
.principal-card{min-height: 220px;}
.modal p { color: #111111 }

a.multiple_i_show {
    color: #2196F3 !IMPORTANT;
}
span.exmp {
    display: inline-block;
    margin: 0 40px 10px 0;
}
.stats_principal .principal-card{min-height: auto !important;}
.ins_pri_card .col-12.principal-license.text-left {
    padding: 0 20px;
}



.classes-list-teacher .rdt_TableCol{justify-content: left !important;}
.classes-list-teacher .rdt_TableCol:nth-child(2),.classes-list-teacher .rdt_TableCol:nth-child(3){justify-content: center !important;}
.classes-list-teacher .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.classes-list-teacher .rdt_TableCell:nth-child(2) {
    text-align: center !important;
    justify-content: center !important;
     display: block;
}
.classes-list-teacher .rdt_TableCell:nth-child(3) {
    text-align: center !important;
    justify-content: center !important;
    display: block;
}
.classes-list-teacher .rdt_TableCell:nth-child(4) {
    text-align: left !important;
    justify-content: left !important;
}
.classes-list-teacher .rdt_TableCell:nth-child(5) {
    text-align: left !important;
    justify-content: left !important;
    width: 200px;
}


.class-student-list-teacher .rdt_TableCol{justify-content: left !important;}
.class-student-list-teacher .rdt_TableCol:nth-child(5), .class-student-list-teacher .rdt_TableCol:nth-child(4){justify-content: center !important;}
.class-student-list-teacher .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.class-student-list-teacher .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.class-student-list-teacher .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.class-student-list-teacher .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}
.class-student-list-teacher .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;

}
.class-student-list-teacher .rdt_TableCell:nth-child(2){ min-width: 250px; }
.class-student-list-teacher .rdt_TableCol:nth-child(2){ min-width: 250px; }


.student-list-teacher .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.student-list-teacher .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.student-list-teacher .rdt_TableCell:nth-child(3) {
    text-align: left !important;
justify-content: left !important;
}
.student-list-teacher .rdt_TableCell:nth-child(4) {
    text-align: left !important;
    justify-content: left !important;
}
.student-list-teacher .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}

.student-list-teacher .rdt_TableCol:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;}

.form_table .form-control.square {
    width: 160px;
    float: left;
}
.student-list-teacher .rdt_TableCol{justify-content: left !important;}
.student-list-teacher .rdt_TableCell:nth-child(2){ min-width: 250px; }
.student-list-teacher .rdt_TableCol:nth-child(2){ min-width: 250px; }
.student-list-teacher .rdt_TableCol:nth-child(5){ justify-content: center !important; }


/*===========================teacher panel=======================*/
.teacher_edit .add_btn.pull-right {
    top: 8px;
    right: 8px;
}
.div_phone{position: relative;}
.div_phone select.form-control.square {
    width: 90px;
    position: absolute;
    padding: 0 10px;
        padding-top: 0px;
        padding-bottom: 0px;
}
.div_phone input.form-control.square {
    width: 80%;
    position: relative;
    left: 19%;
}
.scroll_sessions {
    height: 100px;
    overflow: hidden;
        overflow-y: hidden;
    overflow-y: auto;
}

.aggregate_list {
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
}
.chart_student {
    position: relative;
    left: -200px;
    margin-right: -300px;
}


.css-df17o1{
  background: rgba(29, 97, 225, 0.57) !important;
}
.div_phone_organization label{display: block;}
.div_phone_organization select.form-control.square {
    float: left;
    width: 32%;
    padding-right: 5px;
    padding-left: 5px;
}
.div_phone_organization input.form-control.square {
    float: right;
    width: 68%;
}

.div_phone_admin label{display: block;}
.div_phone_admin select.form-control.square {
    float: left;
    width: 30%;
    padding-right: 5px;
    padding-left: 5px;
}
.div_phone_admin input.form-control.square {
    float: right;
    width: 70%;
}
.student_name{font-weight: 400; color: #717f86 !important;}
.student_status_heading .card-title {
    color:#999;
    font-weight: 600;
    font-size: 1rem;
}
.student_s_name .address_Org {
    color:
    #999;
    font-size: 1rem;
    font-weight: 600;
}
.student_s_name h6{font-size: 16px;
color: #999; font-weight: 400;
}
.tag_headings {
    color: #999 !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
}
.class_description.inst_card_des {
    width: 66%;
    float: right;
    position: relative;
    margin-top: -41px;
    background:
    transparent;
    z-index: 0;
    left: -16px;
}
.add_btn{z-index: 10;}

.school-list-organization-details-height ._loading_overlay_wrapper,
.organization-list-height ._loading_overlay_wrapper,
.school-list-organization-details-height ._loading_overlay_wrapper,
.instructor-list-school-details-height ._loading_overlay_wrapper,
.class-list-school-details-height ._loading_overlay_wrapper,
.instructor-list-admin-organization-height ._loading_overlay_wrapper,
.class-list-instructor-detail-height ._loading_overlay_wrapper,
.class-list-admin-organization-height ._loading_overlay_wrapper,
.saved-reports-height ._loading_overlay_wrapper,
.principal-list-height ._loading_overlay_wrapper,
.school-list-height ._loading_overlay_wrapper,
.instructors-list-height ._loading_overlay_wrapper,
.instructor-classes-list-height ._loading_overlay_wrapper,
.classes-list-height ._loading_overlay_wrapper,
.reports-list-height ._loading_overlay_wrapper,
.instructors-list-height ._loading_overlay_wrapper,
.instructor-classes-list-height ._loading_overlay_wrapper,
.classes-list-principal-height ._loading_overlay_wrapper,
.reports-list-height ._loading_overlay_wrapper,
.classes-list-teacher-height ._loading_overlay_wrapper,
.class-student-list-teacher-height ._loading_overlay_wrapper,
.student-list-teacher-height ._loading_overlay_wrapper
{min-height: 350px;}

.table-height {
    min-height: 350px;
}

._loading_overlay_wrapper.css-79elbk {
    width: 100%; position: relative; padding: 0px;
    top:0; bottom:0; left:0; height:100%;
}

.school-agg_table .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.school-agg_table .rdt_TableCell:nth-child(2) {
    text-align: center !important;
    justify-content: center !important;
}
.school-agg_table .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.school-agg_table .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}

.school-agg_table .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}
.school-agg_table .rdt_TableCell:nth-child(6) {
    text-align: left !important;
    justify-content: left !important;
}

.school-agg_table .rdt_TableCell:nth-child(7) {
    text-align: left !important;
    justify-content: left !important;
}

.school-agg_table .rdt_TableCell:nth-child(8) {
    text-align: center !important;
    justify-content: center !important;
}

.school-agg_table .rdt_TableCell:nth-child(9) {
    text-align: center !important;
    justify-content: center !important;
}

.school-agg_table .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;
}
.school-agg_table .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
.school-agg_table .rdt_TableHeadRow .rdt_TableCol:nth-child(4),
.school-agg_table .rdt_TableHeadRow .rdt_TableCol:nth-child(5),
.school-agg_table .rdt_TableHeadRow .rdt_TableCol:nth-child(8),
.school-agg_table .rdt_TableHeadRow .rdt_TableCol:nth-child(9)
{
    text-align: left !important;
    justify-content: center !important;
}

.school-agg_table .sc-kAzzGY.gXlqCi.rdt_TableHeader {
    display: none;
}

.aggregate_school_table .sc-kpOJdX.bIxPIp.rdt_TableBody {
    height: 140px;
}
.aggregate_school_table .sc-kAzzGY.gXlqCi.rdt_TableHeader {
    display: none;
}

.aggregate_school_table {
    border: 1px solid
    #ccc;
    padding: 10px 0px;
    margin: 19px auto;
    width: 91% !important;
}


.aggregate_school_table .rdt_TableCell:nth-child(1) {
    text-align: left !important;
}
.aggregate_school_table .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}
.school-agg_table .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.aggregate_school_table .rdt_TableCell:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}

.aggregate_school_table .rdt_TableCell:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}
.aggregate_school_table .rdt_TableHeadRow .rdt_TableCol{
    text-align: left !important;
    justify-content: left !important;
}
.aggregate_school_table .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
.aggregate_school_table .rdt_TableHeadRow .rdt_TableCol:nth-child(4),
.aggregate_school_table .rdt_TableHeadRow .rdt_TableCol:nth-child(5)
{
    text-align: left !important;
    justify-content: center !important;
}
text {
    font-size: 14px;
}

.sc-ifAKCX.sc-EHOje.sc-VigVT.keiMEg.rdt_TableCol:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;}
.aggregate_school_table .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
    padding: 0 0 0 30px;
}
.card-header:nth-child(n+3) {
    border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
}
.contact_heading.status_heading {
    display: inline-block;
    margin-right: 10px;
}
.pace-done.menu-collapsed .main-menu .navigation li.has-sub > label:not(.mm-next)::after {
    display: none;
}




.classes-adventure-stats .rdt_TableHeadRow .rdt_TableCol
{
    text-align: left !important;
    justify-content: left !important;
}
.classes-adventure-stats .rdt_TableCell:nth-child(1) {
    text-align: left !important;
    justify-content: left !important;
}

.classes-adventure-stats .rdt_TableCell:nth-child(2) {
    text-align: left !important;
    justify-content: left !important;
}

.classes-adventure-stats .rdt_TableCell:nth-child(3) {
    text-align: left !important;
    justify-content: left !important;
}
.classes-adventure-stats .rdt_TableCell:nth-child(4) {
    text-align: left !important;
    justify-content: left !important;
}

.classes-adventure-stats .rdt_TableCell:nth-child(5) {
    text-align: left !important;
    justify-content: left !important;
}

.classes-adventure-stats .rdt_TableCell:nth-child(6) {
    text-align: left !important;
    justify-content: left !important;
}


.classes-adventure-stats .rdt_TableCell:nth-child(7) {
    text-align: left !important;
    justify-content: left !important;
}

.classes-adventure-stats .rdt_TableCell:nth-child(8) {
    text-align: left !important;
    justify-content: left !important;
}

.classes-adventure-stats .rdt_TableCell:nth-child(9) {
    text-align: left !important;
    justify-content: left !important;
}

.mrg-btn-2 {
    margin-bottom: 2em !important;
}







.react-confirm-alert-button-group > button:last-child {
    background: #6967ce !important;
    border-color:#6967ce !important;
}




.org-pad {
    padding-left: 10px;
    padding-right: 10px;
}


span.footer_text {

    font-size: 10.5px;
}

.fa-star-half {

    margin: 0 6px 0 0;
    position: relative;

}


div.tableContainer {
    clear: both;
    overflow: auto;
    width: 100%;
}

/* Reset overflow value to hidden for all non-IE browsers. */
html>body div.tableContainer {
    overflow: hidden;
    width: 100%;
}

/* define width of table. IE browsers only                 */
div.tableContainer table {
    float: left;
    /* width: 740px */
}

/* define width of table. Add 16px to width for scrollbar.           */
/* All other non-IE browsers.                                        */
html>body div.tableContainer table {
    /* width: 756px */
}

/* set table header to a fixed position. WinIE 6.x only                                       */
/* In WinIE 6.x, any element with a position property set to relative and is a child of       */
/* an element that has an overflow property set, the relative value translates into fixed.    */
/* Ex: parent element DIV with a class of tableContainer has an overflow property set to auto */

thead.fixedHeader tr {
    position: relative;
}

/* set THEAD element to have block level attributes. All other non-IE browsers            */
/* this enables overflow to work on TBODY element. All other non-IE, non-Mozilla browsers */

/* make the TH elements pretty */
thead.fixedHeader th {
background:#FFF;
    font-weight: normal;
    padding: 15px 8px 15px 8px;
    text-align: left;
    position: relative;
    font-weight: 600;
font-size: 14px;
color:
#5e5e5e;
}

html>body tbody.scrollContent {
    display: block;
    overflow-y: auto;
    width: 100%;
    max-height: 625px;
}

html>body thead.fixedHeader {
    display: table;
    overflow: auto;
    width: 100%;
    border-bottom: 1px solid #ccc;
}

/* make TD elements pretty. Provide alternating classes for striping the table */
/* http://www.alistapart.com/articles/zebratables/                             */
tbody.scrollContent td, tbody.scrollContent tr.normalRow td {
    background: #FFF;
    border-bottom: none;
    border-left: none;
    border-top: 1px solid #DDD;
    padding: 10px 8px 10px 8px;
    font-size: 13px;
font-weight: 400;
color: rgba(0, 0, 0, 0.87);
}



tbody.scrollContent tr.alternateRow td {
    background: #EEE;
    border-bottom: none;
    border-left: none;
    border-right: 1px solid #CCC;
    border-top: 1px solid #DDD;
    padding: 2px 3px 3px 4px
}
tbody.scrollContent tr {
    width: 100%;
    display: inline-table;
    margin: 0 0 0 0px;
}
.fas.fa-sort-up {
    display: none;
    position: absolute;
    right: 1%;
    top: 22px;
    color: #8d85ff;
}
thead.fixedHeader th:hover .fas.fa-sort-up {
    display: inline-block;
}

.table-no-record{
    text-align: center;
    padding: 50px 0px !important;
}
.ascending .fas.fa-sort-up {
display: inline-block;
}
.desending .fas.fa-sort-up {
  display: inline-block;
    transform:rotateZ(180deg);
    top: 16px;
}

.organization-list thead th:nth-child(2), .organization-list thead th:nth-child(3), .organization-list thead th:nth-child(4), .organization-list thead th:nth-child(5), .organization-list thead th:nth-child(6), .organization-list thead th:nth-child(7), .organization-list thead th:nth-child(8) {
    text-align: center !important;
    justify-content: center !important;
}

.school-list-organization-details thead th:nth-child(3), .school-list-organization-details thead th:nth-child(4), .school-list-organization-details thead th:nth-child(5), .school-list-organization-details thead th:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;
}
.instructor-list-school-details thead th:nth-child(4), .instructor-list-school-details thead th:nth-child(5), .instructor-list-school-details thead th:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;
}
.class-list-school-details thead th:nth-child(3), .class-list-school-details thead th:nth-child(4), .class-list-school-details thead th:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}
.instructor-list-admin-organization thead th:nth-child(4), .instructor-list-admin-organization thead th:nth-child(5), .instructor-list-admin-organization thead th:nth-child(7)  {
    text-align: center !important;
    justify-content: center !important;
}
.class-list-instructor-detail thead th:nth-child(2), .class-list-instructor-detail thead th:nth-child(3), .class-list-instructor-detail thead th:nth-child(4), .class-list-instructor-detail thead th:nth-child(5), .class-list-instructor-detail thead th:nth-child(6)  {
    text-align: center !important;
    justify-content: center !important;
}

 .class-list-admin-organization thead th:nth-child(4), .class-list-admin-organization thead th:nth-child(5), .class-list-admin-organization thead th:nth-child(6)  {
    text-align: center !important;
    justify-content: center !important;
}
 .reports-list thead th:nth-child(3), .reports-list thead th:nth-child(4), .reports-list thead th:nth-child(5)  {
    text-align: center !important;
    justify-content: center !important;
}
 .principal-list thead th:nth-child(4) {
    text-align: center !important;
    justify-content: center !important;
}
 .school-list thead th:nth-child(3), .school-list thead th:nth-child(4), .school-list thead th:nth-child(5), .school-list thead th:nth-child(6)  {
    text-align: center !important;
    justify-content: center !important;
}
 .instructors-list thead th:nth-child(4), .instructors-list thead th:nth-child(5),  .instructors-list thead th:nth-child(7) , .instructors-list thead th:nth-child(8) {
    text-align: center !important;
    justify-content: center !important;
}
 .instructor-classes-list thead th:nth-child(2), .instructor-classes-list thead th:nth-child(3), .instructor-classes-list thead th:nth-child(4), .instructor-classes-list thead th:nth-child(5) , .instructor-classes-list thead th:nth-child(6) , .instructor-classes-list thead th:nth-child(7) {
    text-align: center !important;
    justify-content: center !important;
}
 .classes-list thead th:nth-child(3), .classes-list thead th:nth-child(4), .classes-list thead th:nth-child(5), .classes-list thead th:nth-child(6) , .classes-list thead th:nth-child(7) , .classes-list thead th:nth-child(8) {
    text-align: center !important;
    justify-content: center !important;
}
 .classes-list-principal thead th:nth-child(3), .classes-list-principal thead th:nth-child(4), .classes-list-principal thead th:nth-child(5), .classes-list-principal thead th:nth-child(6) , .classes-list-principal thead th:nth-child(7)  {
    text-align: center !important;
    justify-content: center !important;
}
 .classes-list-teacher thead th:nth-child(2), .classes-list-teacher thead th:nth-child(3), .classes-list-teacher thead th:nth-child(4), .classes-list-teacher thead th:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}
.class-student-list-teacher thead th:nth-child(3), .class-student-list-teacher thead th:nth-child(4), .class-student-list-teacher thead th:nth-child(5) {
    text-align: center !important;
    justify-content: center !important;
}
.student-list-teacher thead th:nth-child(4), .student-list-teacher thead th:nth-child(5), .student-list-teacher thead th:nth-child(6) {
    text-align: center !important;
    justify-content: center !important;
}

.paginateContainer {
  flex: 1;
  flex-direction: row;
  text-align: right;
}

.paginateContainer > li {
  display: inline;
  margin: 1.25em;
}

.activePage {
  font-weight: 600;
  font-size: 1.5em;
  text-decoration: underline;
}
