/* ================================================================================================
// 	File Name: style.scss
// 	Description: Style for customization.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
// 	Version: 1.0
// 	Author: ThemeSelection
// 	Author URL: https://themeselection.com/
// ================================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
