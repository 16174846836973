.bg-gradient-x-white .card-header,
.bg-gradient-x-white .card-footer,
.bg-gradient-y-white .card-header,
.bg-gradient-y-white .card-footer,
.bg-gradient-directional-white .card-header,
.bg-gradient-directional-white .card-footer,
.bg-gradient-radial-white .card-header,
.bg-gradient-radial-white .card-footer,
.bg-gradient-striped-white .card-header,
.bg-gradient-striped-white .card-footer,
.bg-gradient-x2-white .card-header,
.bg-gradient-x2-white .card-footer,
.bg-gradient-y2-white .card-header,
.bg-gradient-y2-white .card-footer {
  background-color: transparent; }

.bg-gradient-x-black .card-header,
.bg-gradient-x-black .card-footer,
.bg-gradient-y-black .card-header,
.bg-gradient-y-black .card-footer,
.bg-gradient-directional-black .card-header,
.bg-gradient-directional-black .card-footer,
.bg-gradient-radial-black .card-header,
.bg-gradient-radial-black .card-footer,
.bg-gradient-striped-black .card-header,
.bg-gradient-striped-black .card-footer,
.bg-gradient-x2-black .card-header,
.bg-gradient-x2-black .card-footer,
.bg-gradient-y2-black .card-header,
.bg-gradient-y2-black .card-footer {
  background-color: transparent; }

.bg-gradient-x-chartbg .card-header,
.bg-gradient-x-chartbg .card-footer,
.bg-gradient-y-chartbg .card-header,
.bg-gradient-y-chartbg .card-footer,
.bg-gradient-directional-chartbg .card-header,
.bg-gradient-directional-chartbg .card-footer,
.bg-gradient-radial-chartbg .card-header,
.bg-gradient-radial-chartbg .card-footer,
.bg-gradient-striped-chartbg .card-header,
.bg-gradient-striped-chartbg .card-footer,
.bg-gradient-x2-chartbg .card-header,
.bg-gradient-x2-chartbg .card-footer,
.bg-gradient-y2-chartbg .card-header,
.bg-gradient-y2-chartbg .card-footer {
  background-color: transparent; }

.bg-gradient-x-primary {
  background-image: -webkit-gradient(linear, left top, right top, from(#5654c2), to(#9695dd));
  background-image: -webkit-linear-gradient(left, #5654c2 0%, #9695dd 100%);
  background-image: -moz-linear-gradient(left, #5654c2 0%, #9695dd 100%);
  background-image: -o-linear-gradient(left, #5654c2 0%, #9695dd 100%);
  background-image: linear-gradient(to right, #5654c2 0%, #9695dd 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-primary {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5654c2), to(#9695dd));
  background-image: -webkit-linear-gradient(top, #5654c2 0%, #9695dd 100%);
  background-image: -moz-linear-gradient(top, #5654c2 0%, #9695dd 100%);
  background-image: -o-linear-gradient(top, #5654c2 0%, #9695dd 100%);
  background-image: linear-gradient(to bottom, #5654c2 0%, #9695dd 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-primary {
  background-image: -webkit-linear-gradient(45deg, #5654c2, #9695dd);
  background-image: -moz-linear-gradient(45deg, #5654c2, #9695dd);
  background-image: -o-linear-gradient(45deg, #5654c2, #9695dd);
  background-image: linear-gradient(45deg, #5654c2, #9695dd);
  background-repeat: repeat-x; }

.bg-gradient-x2-primary {
  background-image: -webkit-gradient(linear, left top, right top, from(#9695dd), color-stop(50%, #6967CE), to(#b4b3e7));
  background-image: -webkit-linear-gradient(left, #9695dd, #6967CE 50%, #b4b3e7);
  background-image: -moz-linear-gradient(left, #9695dd, #6967CE 50%, #b4b3e7);
  background-image: -o-linear-gradient(left, #9695dd, #6967CE 50%, #b4b3e7);
  background-image: linear-gradient(to right, #9695dd, #6967CE 50%, #b4b3e7);
  background-repeat: no-repeat; }

.bg-gradient-y2-primary {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#9695dd), color-stop(50%, #6967CE), to(#b4b3e7));
  background-image: -webkit-linear-gradient(#9695dd, #6967CE 50%, #b4b3e7);
  background-image: -moz-linear-gradient(#9695dd, #6967CE 50%, #b4b3e7);
  background-image: -o-linear-gradient(#9695dd, #6967CE 50%, #b4b3e7);
  background-image: linear-gradient(#9695dd, #6967CE 50%, #b4b3e7);
  background-repeat: no-repeat; }

.bg-gradient-radial-primary {
  background-image: -webkit-radial-gradient(circle, #5654c2, #9695dd);
  background-image: -moz-radial-gradient(circle, #5654c2, #9695dd);
  background-image: -o-radial-gradient(circle, #5654c2, #9695dd);
  background-image: radial-gradient(circle, #5654c2, #9695dd);
  background-repeat: no-repeat; }

.bg-gradient-striped-primary {
  background-image: -webkit-linear-gradient(45deg, #b4b3e7 25%, transparent 25%, transparent 50%, #b4b3e7 50%, #b4b3e7 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #b4b3e7 25%, transparent 25%, transparent 50%, #b4b3e7 50%, #b4b3e7 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #b4b3e7 25%, transparent 25%, transparent 50%, #b4b3e7 50%, #b4b3e7 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #b4b3e7 25%, transparent 25%, transparent 50%, #b4b3e7 50%, #b4b3e7 75%, transparent 75%, transparent); }

.bg-gradient-x-primary .card-header,
.bg-gradient-x-primary .card-footer,
.bg-gradient-y-primary .card-header,
.bg-gradient-y-primary .card-footer,
.bg-gradient-directional-primary .card-header,
.bg-gradient-directional-primary .card-footer,
.bg-gradient-radial-primary .card-header,
.bg-gradient-radial-primary .card-footer,
.bg-gradient-striped-primary .card-header,
.bg-gradient-striped-primary .card-footer,
.bg-gradient-x2-primary .card-header,
.bg-gradient-x2-primary .card-footer,
.bg-gradient-y2-primary .card-header,
.bg-gradient-y2-primary .card-footer {
  background-color: transparent; }

.bg-gradient-x-success {
  background-image: -webkit-gradient(linear, left top, right top, from(#63d457), to(#9fe797));
  background-image: -webkit-linear-gradient(left, #63d457 0%, #9fe797 100%);
  background-image: -moz-linear-gradient(left, #63d457 0%, #9fe797 100%);
  background-image: -o-linear-gradient(left, #63d457 0%, #9fe797 100%);
  background-image: linear-gradient(to right, #63d457 0%, #9fe797 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-success {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#63d457), to(#9fe797));
  background-image: -webkit-linear-gradient(top, #63d457 0%, #9fe797 100%);
  background-image: -moz-linear-gradient(top, #63d457 0%, #9fe797 100%);
  background-image: -o-linear-gradient(top, #63d457 0%, #9fe797 100%);
  background-image: linear-gradient(to bottom, #63d457 0%, #9fe797 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-success {
  background-image: -webkit-linear-gradient(45deg, #63d457, #9fe797);
  background-image: -moz-linear-gradient(45deg, #63d457, #9fe797);
  background-image: -o-linear-gradient(45deg, #63d457, #9fe797);
  background-image: linear-gradient(45deg, #63d457, #9fe797);
  background-repeat: repeat-x; }

.bg-gradient-x2-success {
  background-image: -webkit-gradient(linear, left top, right top, from(#9fe797), color-stop(50%, #5ed84f), to(#bbeeb5));
  background-image: -webkit-linear-gradient(left, #9fe797, #5ed84f 50%, #bbeeb5);
  background-image: -moz-linear-gradient(left, #9fe797, #5ed84f 50%, #bbeeb5);
  background-image: -o-linear-gradient(left, #9fe797, #5ed84f 50%, #bbeeb5);
  background-image: linear-gradient(to right, #9fe797, #5ed84f 50%, #bbeeb5);
  background-repeat: no-repeat; }

.bg-gradient-y2-success {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#9fe797), color-stop(50%, #5ed84f), to(#bbeeb5));
  background-image: -webkit-linear-gradient(#9fe797, #5ed84f 50%, #bbeeb5);
  background-image: -moz-linear-gradient(#9fe797, #5ed84f 50%, #bbeeb5);
  background-image: -o-linear-gradient(#9fe797, #5ed84f 50%, #bbeeb5);
  background-image: linear-gradient(#9fe797, #5ed84f 50%, #bbeeb5);
  background-repeat: no-repeat; }

.bg-gradient-radial-success {
  background-image: -webkit-radial-gradient(circle, #63d457, #9fe797);
  background-image: -moz-radial-gradient(circle, #63d457, #9fe797);
  background-image: -o-radial-gradient(circle, #63d457, #9fe797);
  background-image: radial-gradient(circle, #63d457, #9fe797);
  background-repeat: no-repeat; }

.bg-gradient-striped-success {
  background-image: -webkit-linear-gradient(45deg, #bbeeb5 25%, transparent 25%, transparent 50%, #bbeeb5 50%, #bbeeb5 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #bbeeb5 25%, transparent 25%, transparent 50%, #bbeeb5 50%, #bbeeb5 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #bbeeb5 25%, transparent 25%, transparent 50%, #bbeeb5 50%, #bbeeb5 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #bbeeb5 25%, transparent 25%, transparent 50%, #bbeeb5 50%, #bbeeb5 75%, transparent 75%, transparent); }

.bg-gradient-x-success .card-header,
.bg-gradient-x-success .card-footer,
.bg-gradient-y-success .card-header,
.bg-gradient-y-success .card-footer,
.bg-gradient-directional-success .card-header,
.bg-gradient-directional-success .card-footer,
.bg-gradient-radial-success .card-header,
.bg-gradient-radial-success .card-footer,
.bg-gradient-striped-success .card-header,
.bg-gradient-striped-success .card-footer,
.bg-gradient-x2-success .card-header,
.bg-gradient-x2-success .card-footer,
.bg-gradient-y2-success .card-header,
.bg-gradient-y2-success .card-footer {
  background-color: transparent; }

.bg-gradient-x-info {
  background-image: -webkit-gradient(linear, left top, right top, from(#1e9fc4), to(#69c7de));
  background-image: -webkit-linear-gradient(left, #1e9fc4 0%, #69c7de 100%);
  background-image: -moz-linear-gradient(left, #1e9fc4 0%, #69c7de 100%);
  background-image: -o-linear-gradient(left, #1e9fc4 0%, #69c7de 100%);
  background-image: linear-gradient(to right, #1e9fc4 0%, #69c7de 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-info {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1e9fc4), to(#69c7de));
  background-image: -webkit-linear-gradient(top, #1e9fc4 0%, #69c7de 100%);
  background-image: -moz-linear-gradient(top, #1e9fc4 0%, #69c7de 100%);
  background-image: -o-linear-gradient(top, #1e9fc4 0%, #69c7de 100%);
  background-image: linear-gradient(to bottom, #1e9fc4 0%, #69c7de 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-info {
  background-image: -webkit-linear-gradient(45deg, #1e9fc4, #69c7de);
  background-image: -moz-linear-gradient(45deg, #1e9fc4, #69c7de);
  background-image: -o-linear-gradient(45deg, #1e9fc4, #69c7de);
  background-image: linear-gradient(45deg, #1e9fc4, #69c7de);
  background-repeat: repeat-x; }

.bg-gradient-x2-info {
  background-image: -webkit-gradient(linear, left top, right top, from(#69c7de), color-stop(50%, #28afd0), to(#94d7e8));
  background-image: -webkit-linear-gradient(left, #69c7de, #28afd0 50%, #94d7e8);
  background-image: -moz-linear-gradient(left, #69c7de, #28afd0 50%, #94d7e8);
  background-image: -o-linear-gradient(left, #69c7de, #28afd0 50%, #94d7e8);
  background-image: linear-gradient(to right, #69c7de, #28afd0 50%, #94d7e8);
  background-repeat: no-repeat; }

.bg-gradient-y2-info {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#69c7de), color-stop(50%, #28afd0), to(#94d7e8));
  background-image: -webkit-linear-gradient(#69c7de, #28afd0 50%, #94d7e8);
  background-image: -moz-linear-gradient(#69c7de, #28afd0 50%, #94d7e8);
  background-image: -o-linear-gradient(#69c7de, #28afd0 50%, #94d7e8);
  background-image: linear-gradient(#69c7de, #28afd0 50%, #94d7e8);
  background-repeat: no-repeat; }

.bg-gradient-radial-info {
  background-image: -webkit-radial-gradient(circle, #1e9fc4, #69c7de);
  background-image: -moz-radial-gradient(circle, #1e9fc4, #69c7de);
  background-image: -o-radial-gradient(circle, #1e9fc4, #69c7de);
  background-image: radial-gradient(circle, #1e9fc4, #69c7de);
  background-repeat: no-repeat; }

.bg-gradient-striped-info {
  background-image: -webkit-linear-gradient(45deg, #94d7e8 25%, transparent 25%, transparent 50%, #94d7e8 50%, #94d7e8 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #94d7e8 25%, transparent 25%, transparent 50%, #94d7e8 50%, #94d7e8 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #94d7e8 25%, transparent 25%, transparent 50%, #94d7e8 50%, #94d7e8 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #94d7e8 25%, transparent 25%, transparent 50%, #94d7e8 50%, #94d7e8 75%, transparent 75%, transparent); }

.bg-gradient-x-info .card-header,
.bg-gradient-x-info .card-footer,
.bg-gradient-y-info .card-header,
.bg-gradient-y-info .card-footer,
.bg-gradient-directional-info .card-header,
.bg-gradient-directional-info .card-footer,
.bg-gradient-radial-info .card-header,
.bg-gradient-radial-info .card-footer,
.bg-gradient-striped-info .card-header,
.bg-gradient-striped-info .card-footer,
.bg-gradient-x2-info .card-header,
.bg-gradient-x2-info .card-footer,
.bg-gradient-y2-info .card-header,
.bg-gradient-y2-info .card-footer {
  background-color: transparent; }

.bg-gradient-x-warning {
  background-image: -webkit-gradient(linear, left top, right top, from(#fcaa01), to(#fece4d));
  background-image: -webkit-linear-gradient(left, #fcaa01 0%, #fece4d 100%);
  background-image: -moz-linear-gradient(left, #fcaa01 0%, #fece4d 100%);
  background-image: -o-linear-gradient(left, #fcaa01 0%, #fece4d 100%);
  background-image: linear-gradient(to right, #fcaa01 0%, #fece4d 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-warning {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fcaa01), to(#fece4d));
  background-image: -webkit-linear-gradient(top, #fcaa01 0%, #fece4d 100%);
  background-image: -moz-linear-gradient(top, #fcaa01 0%, #fece4d 100%);
  background-image: -o-linear-gradient(top, #fcaa01 0%, #fece4d 100%);
  background-image: linear-gradient(to bottom, #fcaa01 0%, #fece4d 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-warning {
  background-image: -webkit-linear-gradient(45deg, #fcaa01, #fece4d);
  background-image: -moz-linear-gradient(45deg, #fcaa01, #fece4d);
  background-image: -o-linear-gradient(45deg, #fcaa01, #fece4d);
  background-image: linear-gradient(45deg, #fcaa01, #fece4d);
  background-repeat: repeat-x; }

.bg-gradient-x2-warning {
  background-image: -webkit-gradient(linear, left top, right top, from(#fece4d), color-stop(50%, #fdb901), to(#fedc80));
  background-image: -webkit-linear-gradient(left, #fece4d, #fdb901 50%, #fedc80);
  background-image: -moz-linear-gradient(left, #fece4d, #fdb901 50%, #fedc80);
  background-image: -o-linear-gradient(left, #fece4d, #fdb901 50%, #fedc80);
  background-image: linear-gradient(to right, #fece4d, #fdb901 50%, #fedc80);
  background-repeat: no-repeat; }

.bg-gradient-y2-warning {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fece4d), color-stop(50%, #fdb901), to(#fedc80));
  background-image: -webkit-linear-gradient(#fece4d, #fdb901 50%, #fedc80);
  background-image: -moz-linear-gradient(#fece4d, #fdb901 50%, #fedc80);
  background-image: -o-linear-gradient(#fece4d, #fdb901 50%, #fedc80);
  background-image: linear-gradient(#fece4d, #fdb901 50%, #fedc80);
  background-repeat: no-repeat; }

.bg-gradient-radial-warning {
  background-image: -webkit-radial-gradient(circle, #fcaa01, #fece4d);
  background-image: -moz-radial-gradient(circle, #fcaa01, #fece4d);
  background-image: -o-radial-gradient(circle, #fcaa01, #fece4d);
  background-image: radial-gradient(circle, #fcaa01, #fece4d);
  background-repeat: no-repeat; }

.bg-gradient-striped-warning {
  background-image: -webkit-linear-gradient(45deg, #fedc80 25%, transparent 25%, transparent 50%, #fedc80 50%, #fedc80 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #fedc80 25%, transparent 25%, transparent 50%, #fedc80 50%, #fedc80 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #fedc80 25%, transparent 25%, transparent 50%, #fedc80 50%, #fedc80 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #fedc80 25%, transparent 25%, transparent 50%, #fedc80 50%, #fedc80 75%, transparent 75%, transparent); }

.bg-gradient-x-warning .card-header,
.bg-gradient-x-warning .card-footer,
.bg-gradient-y-warning .card-header,
.bg-gradient-y-warning .card-footer,
.bg-gradient-directional-warning .card-header,
.bg-gradient-directional-warning .card-footer,
.bg-gradient-radial-warning .card-header,
.bg-gradient-radial-warning .card-footer,
.bg-gradient-striped-warning .card-header,
.bg-gradient-striped-warning .card-footer,
.bg-gradient-x2-warning .card-header,
.bg-gradient-x2-warning .card-footer,
.bg-gradient-y2-warning .card-header,
.bg-gradient-y2-warning .card-footer {
  background-color: transparent; }

.bg-gradient-x-danger {
  background-image: -webkit-gradient(linear, left top, right top, from(#f95058), to(#fc9197));
  background-image: -webkit-linear-gradient(left, #f95058 0%, #fc9197 100%);
  background-image: -moz-linear-gradient(left, #f95058 0%, #fc9197 100%);
  background-image: -o-linear-gradient(left, #f95058 0%, #fc9197 100%);
  background-image: linear-gradient(to right, #f95058 0%, #fc9197 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-danger {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f95058), to(#fc9197));
  background-image: -webkit-linear-gradient(top, #f95058 0%, #fc9197 100%);
  background-image: -moz-linear-gradient(top, #f95058 0%, #fc9197 100%);
  background-image: -o-linear-gradient(top, #f95058 0%, #fc9197 100%);
  background-image: linear-gradient(to bottom, #f95058 0%, #fc9197 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-danger {
  background-image: -webkit-linear-gradient(45deg, #f95058, #fc9197);
  background-image: -moz-linear-gradient(45deg, #f95058, #fc9197);
  background-image: -o-linear-gradient(45deg, #f95058, #fc9197);
  background-image: linear-gradient(45deg, #f95058, #fc9197);
  background-repeat: repeat-x; }

.bg-gradient-x2-danger {
  background-image: -webkit-gradient(linear, left top, right top, from(#fc9197), color-stop(50%, #fa626b), to(#fdb1b5));
  background-image: -webkit-linear-gradient(left, #fc9197, #fa626b 50%, #fdb1b5);
  background-image: -moz-linear-gradient(left, #fc9197, #fa626b 50%, #fdb1b5);
  background-image: -o-linear-gradient(left, #fc9197, #fa626b 50%, #fdb1b5);
  background-image: linear-gradient(to right, #fc9197, #fa626b 50%, #fdb1b5);
  background-repeat: no-repeat; }

.bg-gradient-y2-danger {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fc9197), color-stop(50%, #fa626b), to(#fdb1b5));
  background-image: -webkit-linear-gradient(#fc9197, #fa626b 50%, #fdb1b5);
  background-image: -moz-linear-gradient(#fc9197, #fa626b 50%, #fdb1b5);
  background-image: -o-linear-gradient(#fc9197, #fa626b 50%, #fdb1b5);
  background-image: linear-gradient(#fc9197, #fa626b 50%, #fdb1b5);
  background-repeat: no-repeat; }

.bg-gradient-radial-danger {
  background-image: -webkit-radial-gradient(circle, #f95058, #fc9197);
  background-image: -moz-radial-gradient(circle, #f95058, #fc9197);
  background-image: -o-radial-gradient(circle, #f95058, #fc9197);
  background-image: radial-gradient(circle, #f95058, #fc9197);
  background-repeat: no-repeat; }

.bg-gradient-striped-danger {
  background-image: -webkit-linear-gradient(45deg, #fdb1b5 25%, transparent 25%, transparent 50%, #fdb1b5 50%, #fdb1b5 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #fdb1b5 25%, transparent 25%, transparent 50%, #fdb1b5 50%, #fdb1b5 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #fdb1b5 25%, transparent 25%, transparent 50%, #fdb1b5 50%, #fdb1b5 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #fdb1b5 25%, transparent 25%, transparent 50%, #fdb1b5 50%, #fdb1b5 75%, transparent 75%, transparent); }

.bg-gradient-x-danger .card-header,
.bg-gradient-x-danger .card-footer,
.bg-gradient-y-danger .card-header,
.bg-gradient-y-danger .card-footer,
.bg-gradient-directional-danger .card-header,
.bg-gradient-directional-danger .card-footer,
.bg-gradient-radial-danger .card-header,
.bg-gradient-radial-danger .card-footer,
.bg-gradient-striped-danger .card-header,
.bg-gradient-striped-danger .card-footer,
.bg-gradient-x2-danger .card-header,
.bg-gradient-x2-danger .card-footer,
.bg-gradient-y2-danger .card-header,
.bg-gradient-y2-danger .card-footer {
  background-color: transparent; }

.bg-gradient-x-red {
  background-image: -webkit-gradient(linear, left top, right top, from(#D32F2F), to(#E57373));
  background-image: -webkit-linear-gradient(left, #D32F2F 0%, #E57373 100%);
  background-image: -moz-linear-gradient(left, #D32F2F 0%, #E57373 100%);
  background-image: -o-linear-gradient(left, #D32F2F 0%, #E57373 100%);
  background-image: linear-gradient(to right, #D32F2F 0%, #E57373 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-red {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#D32F2F), to(#E57373));
  background-image: -webkit-linear-gradient(top, #D32F2F 0%, #E57373 100%);
  background-image: -moz-linear-gradient(top, #D32F2F 0%, #E57373 100%);
  background-image: -o-linear-gradient(top, #D32F2F 0%, #E57373 100%);
  background-image: linear-gradient(to bottom, #D32F2F 0%, #E57373 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-red {
  background-image: -webkit-linear-gradient(45deg, #D32F2F, #E57373);
  background-image: -moz-linear-gradient(45deg, #D32F2F, #E57373);
  background-image: -o-linear-gradient(45deg, #D32F2F, #E57373);
  background-image: linear-gradient(45deg, #D32F2F, #E57373);
  background-repeat: repeat-x; }

.bg-gradient-x2-red {
  background-image: -webkit-gradient(linear, left top, right top, from(#E57373), color-stop(50%, #F44336), to(#EF9A9A));
  background-image: -webkit-linear-gradient(left, #E57373, #F44336 50%, #EF9A9A);
  background-image: -moz-linear-gradient(left, #E57373, #F44336 50%, #EF9A9A);
  background-image: -o-linear-gradient(left, #E57373, #F44336 50%, #EF9A9A);
  background-image: linear-gradient(to right, #E57373, #F44336 50%, #EF9A9A);
  background-repeat: no-repeat; }

.bg-gradient-y2-red {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#E57373), color-stop(50%, #F44336), to(#EF9A9A));
  background-image: -webkit-linear-gradient(#E57373, #F44336 50%, #EF9A9A);
  background-image: -moz-linear-gradient(#E57373, #F44336 50%, #EF9A9A);
  background-image: -o-linear-gradient(#E57373, #F44336 50%, #EF9A9A);
  background-image: linear-gradient(#E57373, #F44336 50%, #EF9A9A);
  background-repeat: no-repeat; }

.bg-gradient-radial-red {
  background-image: -webkit-radial-gradient(circle, #D32F2F, #E57373);
  background-image: -moz-radial-gradient(circle, #D32F2F, #E57373);
  background-image: -o-radial-gradient(circle, #D32F2F, #E57373);
  background-image: radial-gradient(circle, #D32F2F, #E57373);
  background-repeat: no-repeat; }

.bg-gradient-striped-red {
  background-image: -webkit-linear-gradient(45deg, #EF9A9A 25%, transparent 25%, transparent 50%, #EF9A9A 50%, #EF9A9A 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #EF9A9A 25%, transparent 25%, transparent 50%, #EF9A9A 50%, #EF9A9A 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #EF9A9A 25%, transparent 25%, transparent 50%, #EF9A9A 50%, #EF9A9A 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #EF9A9A 25%, transparent 25%, transparent 50%, #EF9A9A 50%, #EF9A9A 75%, transparent 75%, transparent); }

.bg-gradient-x-red .card-header,
.bg-gradient-x-red .card-footer,
.bg-gradient-y-red .card-header,
.bg-gradient-y-red .card-footer,
.bg-gradient-directional-red .card-header,
.bg-gradient-directional-red .card-footer,
.bg-gradient-radial-red .card-header,
.bg-gradient-radial-red .card-footer,
.bg-gradient-striped-red .card-header,
.bg-gradient-striped-red .card-footer,
.bg-gradient-x2-red .card-header,
.bg-gradient-x2-red .card-footer,
.bg-gradient-y2-red .card-header,
.bg-gradient-y2-red .card-footer {
  background-color: transparent; }

.bg-gradient-x-pink {
  background-image: -webkit-gradient(linear, left top, right top, from(#C2185B), to(#F06292));
  background-image: -webkit-linear-gradient(left, #C2185B 0%, #F06292 100%);
  background-image: -moz-linear-gradient(left, #C2185B 0%, #F06292 100%);
  background-image: -o-linear-gradient(left, #C2185B 0%, #F06292 100%);
  background-image: linear-gradient(to right, #C2185B 0%, #F06292 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-pink {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#C2185B), to(#F06292));
  background-image: -webkit-linear-gradient(top, #C2185B 0%, #F06292 100%);
  background-image: -moz-linear-gradient(top, #C2185B 0%, #F06292 100%);
  background-image: -o-linear-gradient(top, #C2185B 0%, #F06292 100%);
  background-image: linear-gradient(to bottom, #C2185B 0%, #F06292 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-pink {
  background-image: -webkit-linear-gradient(45deg, #C2185B, #F06292);
  background-image: -moz-linear-gradient(45deg, #C2185B, #F06292);
  background-image: -o-linear-gradient(45deg, #C2185B, #F06292);
  background-image: linear-gradient(45deg, #C2185B, #F06292);
  background-repeat: repeat-x; }

.bg-gradient-x2-pink {
  background-image: -webkit-gradient(linear, left top, right top, from(#F06292), color-stop(50%, #E91E63), to(#F48FB1));
  background-image: -webkit-linear-gradient(left, #F06292, #E91E63 50%, #F48FB1);
  background-image: -moz-linear-gradient(left, #F06292, #E91E63 50%, #F48FB1);
  background-image: -o-linear-gradient(left, #F06292, #E91E63 50%, #F48FB1);
  background-image: linear-gradient(to right, #F06292, #E91E63 50%, #F48FB1);
  background-repeat: no-repeat; }

.bg-gradient-y2-pink {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F06292), color-stop(50%, #E91E63), to(#F48FB1));
  background-image: -webkit-linear-gradient(#F06292, #E91E63 50%, #F48FB1);
  background-image: -moz-linear-gradient(#F06292, #E91E63 50%, #F48FB1);
  background-image: -o-linear-gradient(#F06292, #E91E63 50%, #F48FB1);
  background-image: linear-gradient(#F06292, #E91E63 50%, #F48FB1);
  background-repeat: no-repeat; }

.bg-gradient-radial-pink {
  background-image: -webkit-radial-gradient(circle, #C2185B, #F06292);
  background-image: -moz-radial-gradient(circle, #C2185B, #F06292);
  background-image: -o-radial-gradient(circle, #C2185B, #F06292);
  background-image: radial-gradient(circle, #C2185B, #F06292);
  background-repeat: no-repeat; }

.bg-gradient-striped-pink {
  background-image: -webkit-linear-gradient(45deg, #F48FB1 25%, transparent 25%, transparent 50%, #F48FB1 50%, #F48FB1 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #F48FB1 25%, transparent 25%, transparent 50%, #F48FB1 50%, #F48FB1 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #F48FB1 25%, transparent 25%, transparent 50%, #F48FB1 50%, #F48FB1 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #F48FB1 25%, transparent 25%, transparent 50%, #F48FB1 50%, #F48FB1 75%, transparent 75%, transparent); }

.bg-gradient-x-pink .card-header,
.bg-gradient-x-pink .card-footer,
.bg-gradient-y-pink .card-header,
.bg-gradient-y-pink .card-footer,
.bg-gradient-directional-pink .card-header,
.bg-gradient-directional-pink .card-footer,
.bg-gradient-radial-pink .card-header,
.bg-gradient-radial-pink .card-footer,
.bg-gradient-striped-pink .card-header,
.bg-gradient-striped-pink .card-footer,
.bg-gradient-x2-pink .card-header,
.bg-gradient-x2-pink .card-footer,
.bg-gradient-y2-pink .card-header,
.bg-gradient-y2-pink .card-footer {
  background-color: transparent; }

.bg-gradient-x-purple {
  background-image: -webkit-gradient(linear, left top, right top, from(#7B1FA2), to(#BA68C8));
  background-image: -webkit-linear-gradient(left, #7B1FA2 0%, #BA68C8 100%);
  background-image: -moz-linear-gradient(left, #7B1FA2 0%, #BA68C8 100%);
  background-image: -o-linear-gradient(left, #7B1FA2 0%, #BA68C8 100%);
  background-image: linear-gradient(to right, #7B1FA2 0%, #BA68C8 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-purple {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7B1FA2), to(#BA68C8));
  background-image: -webkit-linear-gradient(top, #7B1FA2 0%, #BA68C8 100%);
  background-image: -moz-linear-gradient(top, #7B1FA2 0%, #BA68C8 100%);
  background-image: -o-linear-gradient(top, #7B1FA2 0%, #BA68C8 100%);
  background-image: linear-gradient(to bottom, #7B1FA2 0%, #BA68C8 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-purple {
  background-image: -webkit-linear-gradient(45deg, #7B1FA2, #BA68C8);
  background-image: -moz-linear-gradient(45deg, #7B1FA2, #BA68C8);
  background-image: -o-linear-gradient(45deg, #7B1FA2, #BA68C8);
  background-image: linear-gradient(45deg, #7B1FA2, #BA68C8);
  background-repeat: repeat-x; }

.bg-gradient-x2-purple {
  background-image: -webkit-gradient(linear, left top, right top, from(#BA68C8), color-stop(50%, #9C27B0), to(#CE93D8));
  background-image: -webkit-linear-gradient(left, #BA68C8, #9C27B0 50%, #CE93D8);
  background-image: -moz-linear-gradient(left, #BA68C8, #9C27B0 50%, #CE93D8);
  background-image: -o-linear-gradient(left, #BA68C8, #9C27B0 50%, #CE93D8);
  background-image: linear-gradient(to right, #BA68C8, #9C27B0 50%, #CE93D8);
  background-repeat: no-repeat; }

.bg-gradient-y2-purple {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#BA68C8), color-stop(50%, #9C27B0), to(#CE93D8));
  background-image: -webkit-linear-gradient(#BA68C8, #9C27B0 50%, #CE93D8);
  background-image: -moz-linear-gradient(#BA68C8, #9C27B0 50%, #CE93D8);
  background-image: -o-linear-gradient(#BA68C8, #9C27B0 50%, #CE93D8);
  background-image: linear-gradient(#BA68C8, #9C27B0 50%, #CE93D8);
  background-repeat: no-repeat; }

.bg-gradient-radial-purple {
  background-image: -webkit-radial-gradient(circle, #7B1FA2, #BA68C8);
  background-image: -moz-radial-gradient(circle, #7B1FA2, #BA68C8);
  background-image: -o-radial-gradient(circle, #7B1FA2, #BA68C8);
  background-image: radial-gradient(circle, #7B1FA2, #BA68C8);
  background-repeat: no-repeat; }

.bg-gradient-striped-purple {
  background-image: -webkit-linear-gradient(45deg, #CE93D8 25%, transparent 25%, transparent 50%, #CE93D8 50%, #CE93D8 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #CE93D8 25%, transparent 25%, transparent 50%, #CE93D8 50%, #CE93D8 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #CE93D8 25%, transparent 25%, transparent 50%, #CE93D8 50%, #CE93D8 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #CE93D8 25%, transparent 25%, transparent 50%, #CE93D8 50%, #CE93D8 75%, transparent 75%, transparent); }

.bg-gradient-x-purple .card-header,
.bg-gradient-x-purple .card-footer,
.bg-gradient-y-purple .card-header,
.bg-gradient-y-purple .card-footer,
.bg-gradient-directional-purple .card-header,
.bg-gradient-directional-purple .card-footer,
.bg-gradient-radial-purple .card-header,
.bg-gradient-radial-purple .card-footer,
.bg-gradient-striped-purple .card-header,
.bg-gradient-striped-purple .card-footer,
.bg-gradient-x2-purple .card-header,
.bg-gradient-x2-purple .card-footer,
.bg-gradient-y2-purple .card-header,
.bg-gradient-y2-purple .card-footer {
  background-color: transparent; }

.bg-gradient-x-blue {
  background-image: -webkit-gradient(linear, left top, right top, from(#1976D2), to(#64B5F6));
  background-image: -webkit-linear-gradient(left, #1976D2 0%, #64B5F6 100%);
  background-image: -moz-linear-gradient(left, #1976D2 0%, #64B5F6 100%);
  background-image: -o-linear-gradient(left, #1976D2 0%, #64B5F6 100%);
  background-image: linear-gradient(to right, #1976D2 0%, #64B5F6 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-blue {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1976D2), to(#64B5F6));
  background-image: -webkit-linear-gradient(top, #1976D2 0%, #64B5F6 100%);
  background-image: -moz-linear-gradient(top, #1976D2 0%, #64B5F6 100%);
  background-image: -o-linear-gradient(top, #1976D2 0%, #64B5F6 100%);
  background-image: linear-gradient(to bottom, #1976D2 0%, #64B5F6 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-blue {
  background-image: -webkit-linear-gradient(45deg, #1976D2, #64B5F6);
  background-image: -moz-linear-gradient(45deg, #1976D2, #64B5F6);
  background-image: -o-linear-gradient(45deg, #1976D2, #64B5F6);
  background-image: linear-gradient(45deg, #1976D2, #64B5F6);
  background-repeat: repeat-x; }

.bg-gradient-x2-blue {
  background-image: -webkit-gradient(linear, left top, right top, from(#64B5F6), color-stop(50%, #2196F3), to(#90CAF9));
  background-image: -webkit-linear-gradient(left, #64B5F6, #2196F3 50%, #90CAF9);
  background-image: -moz-linear-gradient(left, #64B5F6, #2196F3 50%, #90CAF9);
  background-image: -o-linear-gradient(left, #64B5F6, #2196F3 50%, #90CAF9);
  background-image: linear-gradient(to right, #64B5F6, #2196F3 50%, #90CAF9);
  background-repeat: no-repeat; }

.bg-gradient-y2-blue {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#64B5F6), color-stop(50%, #2196F3), to(#90CAF9));
  background-image: -webkit-linear-gradient(#64B5F6, #2196F3 50%, #90CAF9);
  background-image: -moz-linear-gradient(#64B5F6, #2196F3 50%, #90CAF9);
  background-image: -o-linear-gradient(#64B5F6, #2196F3 50%, #90CAF9);
  background-image: linear-gradient(#64B5F6, #2196F3 50%, #90CAF9);
  background-repeat: no-repeat; }

.bg-gradient-radial-blue {
  background-image: -webkit-radial-gradient(circle, #1976D2, #64B5F6);
  background-image: -moz-radial-gradient(circle, #1976D2, #64B5F6);
  background-image: -o-radial-gradient(circle, #1976D2, #64B5F6);
  background-image: radial-gradient(circle, #1976D2, #64B5F6);
  background-repeat: no-repeat; }

.bg-gradient-striped-blue {
  background-image: -webkit-linear-gradient(45deg, #90CAF9 25%, transparent 25%, transparent 50%, #90CAF9 50%, #90CAF9 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #90CAF9 25%, transparent 25%, transparent 50%, #90CAF9 50%, #90CAF9 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #90CAF9 25%, transparent 25%, transparent 50%, #90CAF9 50%, #90CAF9 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #90CAF9 25%, transparent 25%, transparent 50%, #90CAF9 50%, #90CAF9 75%, transparent 75%, transparent); }

.bg-gradient-x-blue .card-header,
.bg-gradient-x-blue .card-footer,
.bg-gradient-y-blue .card-header,
.bg-gradient-y-blue .card-footer,
.bg-gradient-directional-blue .card-header,
.bg-gradient-directional-blue .card-footer,
.bg-gradient-radial-blue .card-header,
.bg-gradient-radial-blue .card-footer,
.bg-gradient-striped-blue .card-header,
.bg-gradient-striped-blue .card-footer,
.bg-gradient-x2-blue .card-header,
.bg-gradient-x2-blue .card-footer,
.bg-gradient-y2-blue .card-header,
.bg-gradient-y2-blue .card-footer {
  background-color: transparent; }

.bg-gradient-x-cyan {
  background-image: -webkit-gradient(linear, left top, right top, from(#0097A7), to(#4DD0E1));
  background-image: -webkit-linear-gradient(left, #0097A7 0%, #4DD0E1 100%);
  background-image: -moz-linear-gradient(left, #0097A7 0%, #4DD0E1 100%);
  background-image: -o-linear-gradient(left, #0097A7 0%, #4DD0E1 100%);
  background-image: linear-gradient(to right, #0097A7 0%, #4DD0E1 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-cyan {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#0097A7), to(#4DD0E1));
  background-image: -webkit-linear-gradient(top, #0097A7 0%, #4DD0E1 100%);
  background-image: -moz-linear-gradient(top, #0097A7 0%, #4DD0E1 100%);
  background-image: -o-linear-gradient(top, #0097A7 0%, #4DD0E1 100%);
  background-image: linear-gradient(to bottom, #0097A7 0%, #4DD0E1 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-cyan {
  background-image: -webkit-linear-gradient(45deg, #0097A7, #4DD0E1);
  background-image: -moz-linear-gradient(45deg, #0097A7, #4DD0E1);
  background-image: -o-linear-gradient(45deg, #0097A7, #4DD0E1);
  background-image: linear-gradient(45deg, #0097A7, #4DD0E1);
  background-repeat: repeat-x; }

.bg-gradient-x2-cyan {
  background-image: -webkit-gradient(linear, left top, right top, from(#4DD0E1), color-stop(50%, #00BCD4), to(#80DEEA));
  background-image: -webkit-linear-gradient(left, #4DD0E1, #00BCD4 50%, #80DEEA);
  background-image: -moz-linear-gradient(left, #4DD0E1, #00BCD4 50%, #80DEEA);
  background-image: -o-linear-gradient(left, #4DD0E1, #00BCD4 50%, #80DEEA);
  background-image: linear-gradient(to right, #4DD0E1, #00BCD4 50%, #80DEEA);
  background-repeat: no-repeat; }

.bg-gradient-y2-cyan {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4DD0E1), color-stop(50%, #00BCD4), to(#80DEEA));
  background-image: -webkit-linear-gradient(#4DD0E1, #00BCD4 50%, #80DEEA);
  background-image: -moz-linear-gradient(#4DD0E1, #00BCD4 50%, #80DEEA);
  background-image: -o-linear-gradient(#4DD0E1, #00BCD4 50%, #80DEEA);
  background-image: linear-gradient(#4DD0E1, #00BCD4 50%, #80DEEA);
  background-repeat: no-repeat; }

.bg-gradient-radial-cyan {
  background-image: -webkit-radial-gradient(circle, #0097A7, #4DD0E1);
  background-image: -moz-radial-gradient(circle, #0097A7, #4DD0E1);
  background-image: -o-radial-gradient(circle, #0097A7, #4DD0E1);
  background-image: radial-gradient(circle, #0097A7, #4DD0E1);
  background-repeat: no-repeat; }

.bg-gradient-striped-cyan {
  background-image: -webkit-linear-gradient(45deg, #80DEEA 25%, transparent 25%, transparent 50%, #80DEEA 50%, #80DEEA 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #80DEEA 25%, transparent 25%, transparent 50%, #80DEEA 50%, #80DEEA 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #80DEEA 25%, transparent 25%, transparent 50%, #80DEEA 50%, #80DEEA 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #80DEEA 25%, transparent 25%, transparent 50%, #80DEEA 50%, #80DEEA 75%, transparent 75%, transparent); }

.bg-gradient-x-cyan .card-header,
.bg-gradient-x-cyan .card-footer,
.bg-gradient-y-cyan .card-header,
.bg-gradient-y-cyan .card-footer,
.bg-gradient-directional-cyan .card-header,
.bg-gradient-directional-cyan .card-footer,
.bg-gradient-radial-cyan .card-header,
.bg-gradient-radial-cyan .card-footer,
.bg-gradient-striped-cyan .card-header,
.bg-gradient-striped-cyan .card-footer,
.bg-gradient-x2-cyan .card-header,
.bg-gradient-x2-cyan .card-footer,
.bg-gradient-y2-cyan .card-header,
.bg-gradient-y2-cyan .card-footer {
  background-color: transparent; }

.bg-gradient-x-teal {
  background-image: -webkit-gradient(linear, left top, right top, from(#00796B), to(#4DB6AC));
  background-image: -webkit-linear-gradient(left, #00796B 0%, #4DB6AC 100%);
  background-image: -moz-linear-gradient(left, #00796B 0%, #4DB6AC 100%);
  background-image: -o-linear-gradient(left, #00796B 0%, #4DB6AC 100%);
  background-image: linear-gradient(to right, #00796B 0%, #4DB6AC 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-teal {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#00796B), to(#4DB6AC));
  background-image: -webkit-linear-gradient(top, #00796B 0%, #4DB6AC 100%);
  background-image: -moz-linear-gradient(top, #00796B 0%, #4DB6AC 100%);
  background-image: -o-linear-gradient(top, #00796B 0%, #4DB6AC 100%);
  background-image: linear-gradient(to bottom, #00796B 0%, #4DB6AC 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-teal {
  background-image: -webkit-linear-gradient(45deg, #00796B, #4DB6AC);
  background-image: -moz-linear-gradient(45deg, #00796B, #4DB6AC);
  background-image: -o-linear-gradient(45deg, #00796B, #4DB6AC);
  background-image: linear-gradient(45deg, #00796B, #4DB6AC);
  background-repeat: repeat-x; }

.bg-gradient-x2-teal {
  background-image: -webkit-gradient(linear, left top, right top, from(#4DB6AC), color-stop(50%, #009688), to(#80CBC4));
  background-image: -webkit-linear-gradient(left, #4DB6AC, #009688 50%, #80CBC4);
  background-image: -moz-linear-gradient(left, #4DB6AC, #009688 50%, #80CBC4);
  background-image: -o-linear-gradient(left, #4DB6AC, #009688 50%, #80CBC4);
  background-image: linear-gradient(to right, #4DB6AC, #009688 50%, #80CBC4);
  background-repeat: no-repeat; }

.bg-gradient-y2-teal {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4DB6AC), color-stop(50%, #009688), to(#80CBC4));
  background-image: -webkit-linear-gradient(#4DB6AC, #009688 50%, #80CBC4);
  background-image: -moz-linear-gradient(#4DB6AC, #009688 50%, #80CBC4);
  background-image: -o-linear-gradient(#4DB6AC, #009688 50%, #80CBC4);
  background-image: linear-gradient(#4DB6AC, #009688 50%, #80CBC4);
  background-repeat: no-repeat; }

.bg-gradient-radial-teal {
  background-image: -webkit-radial-gradient(circle, #00796B, #4DB6AC);
  background-image: -moz-radial-gradient(circle, #00796B, #4DB6AC);
  background-image: -o-radial-gradient(circle, #00796B, #4DB6AC);
  background-image: radial-gradient(circle, #00796B, #4DB6AC);
  background-repeat: no-repeat; }

.bg-gradient-striped-teal {
  background-image: -webkit-linear-gradient(45deg, #80CBC4 25%, transparent 25%, transparent 50%, #80CBC4 50%, #80CBC4 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #80CBC4 25%, transparent 25%, transparent 50%, #80CBC4 50%, #80CBC4 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #80CBC4 25%, transparent 25%, transparent 50%, #80CBC4 50%, #80CBC4 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #80CBC4 25%, transparent 25%, transparent 50%, #80CBC4 50%, #80CBC4 75%, transparent 75%, transparent); }

.bg-gradient-x-teal .card-header,
.bg-gradient-x-teal .card-footer,
.bg-gradient-y-teal .card-header,
.bg-gradient-y-teal .card-footer,
.bg-gradient-directional-teal .card-header,
.bg-gradient-directional-teal .card-footer,
.bg-gradient-radial-teal .card-header,
.bg-gradient-radial-teal .card-footer,
.bg-gradient-striped-teal .card-header,
.bg-gradient-striped-teal .card-footer,
.bg-gradient-x2-teal .card-header,
.bg-gradient-x2-teal .card-footer,
.bg-gradient-y2-teal .card-header,
.bg-gradient-y2-teal .card-footer {
  background-color: transparent; }

.bg-gradient-x-yellow {
  background-image: -webkit-gradient(linear, left top, right top, from(#FBC02D), to(#FFF176));
  background-image: -webkit-linear-gradient(left, #FBC02D 0%, #FFF176 100%);
  background-image: -moz-linear-gradient(left, #FBC02D 0%, #FFF176 100%);
  background-image: -o-linear-gradient(left, #FBC02D 0%, #FFF176 100%);
  background-image: linear-gradient(to right, #FBC02D 0%, #FFF176 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-yellow {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FBC02D), to(#FFF176));
  background-image: -webkit-linear-gradient(top, #FBC02D 0%, #FFF176 100%);
  background-image: -moz-linear-gradient(top, #FBC02D 0%, #FFF176 100%);
  background-image: -o-linear-gradient(top, #FBC02D 0%, #FFF176 100%);
  background-image: linear-gradient(to bottom, #FBC02D 0%, #FFF176 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-yellow {
  background-image: -webkit-linear-gradient(45deg, #FBC02D, #FFF176);
  background-image: -moz-linear-gradient(45deg, #FBC02D, #FFF176);
  background-image: -o-linear-gradient(45deg, #FBC02D, #FFF176);
  background-image: linear-gradient(45deg, #FBC02D, #FFF176);
  background-repeat: repeat-x; }

.bg-gradient-x2-yellow {
  background-image: -webkit-gradient(linear, left top, right top, from(#FFF176), color-stop(50%, #FFEB3B), to(#FFF59D));
  background-image: -webkit-linear-gradient(left, #FFF176, #FFEB3B 50%, #FFF59D);
  background-image: -moz-linear-gradient(left, #FFF176, #FFEB3B 50%, #FFF59D);
  background-image: -o-linear-gradient(left, #FFF176, #FFEB3B 50%, #FFF59D);
  background-image: linear-gradient(to right, #FFF176, #FFEB3B 50%, #FFF59D);
  background-repeat: no-repeat; }

.bg-gradient-y2-yellow {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFF176), color-stop(50%, #FFEB3B), to(#FFF59D));
  background-image: -webkit-linear-gradient(#FFF176, #FFEB3B 50%, #FFF59D);
  background-image: -moz-linear-gradient(#FFF176, #FFEB3B 50%, #FFF59D);
  background-image: -o-linear-gradient(#FFF176, #FFEB3B 50%, #FFF59D);
  background-image: linear-gradient(#FFF176, #FFEB3B 50%, #FFF59D);
  background-repeat: no-repeat; }

.bg-gradient-radial-yellow {
  background-image: -webkit-radial-gradient(circle, #FBC02D, #FFF176);
  background-image: -moz-radial-gradient(circle, #FBC02D, #FFF176);
  background-image: -o-radial-gradient(circle, #FBC02D, #FFF176);
  background-image: radial-gradient(circle, #FBC02D, #FFF176);
  background-repeat: no-repeat; }

.bg-gradient-striped-yellow {
  background-image: -webkit-linear-gradient(45deg, #FFF59D 25%, transparent 25%, transparent 50%, #FFF59D 50%, #FFF59D 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #FFF59D 25%, transparent 25%, transparent 50%, #FFF59D 50%, #FFF59D 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #FFF59D 25%, transparent 25%, transparent 50%, #FFF59D 50%, #FFF59D 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #FFF59D 25%, transparent 25%, transparent 50%, #FFF59D 50%, #FFF59D 75%, transparent 75%, transparent); }

.bg-gradient-x-yellow .card-header,
.bg-gradient-x-yellow .card-footer,
.bg-gradient-y-yellow .card-header,
.bg-gradient-y-yellow .card-footer,
.bg-gradient-directional-yellow .card-header,
.bg-gradient-directional-yellow .card-footer,
.bg-gradient-radial-yellow .card-header,
.bg-gradient-radial-yellow .card-footer,
.bg-gradient-striped-yellow .card-header,
.bg-gradient-striped-yellow .card-footer,
.bg-gradient-x2-yellow .card-header,
.bg-gradient-x2-yellow .card-footer,
.bg-gradient-y2-yellow .card-header,
.bg-gradient-y2-yellow .card-footer {
  background-color: transparent; }

.bg-gradient-x-amber {
  background-image: -webkit-gradient(linear, left top, right top, from(#FFA000), to(#FFD54F));
  background-image: -webkit-linear-gradient(left, #FFA000 0%, #FFD54F 100%);
  background-image: -moz-linear-gradient(left, #FFA000 0%, #FFD54F 100%);
  background-image: -o-linear-gradient(left, #FFA000 0%, #FFD54F 100%);
  background-image: linear-gradient(to right, #FFA000 0%, #FFD54F 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-amber {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFA000), to(#FFD54F));
  background-image: -webkit-linear-gradient(top, #FFA000 0%, #FFD54F 100%);
  background-image: -moz-linear-gradient(top, #FFA000 0%, #FFD54F 100%);
  background-image: -o-linear-gradient(top, #FFA000 0%, #FFD54F 100%);
  background-image: linear-gradient(to bottom, #FFA000 0%, #FFD54F 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-amber {
  background-image: -webkit-linear-gradient(45deg, #FFA000, #FFD54F);
  background-image: -moz-linear-gradient(45deg, #FFA000, #FFD54F);
  background-image: -o-linear-gradient(45deg, #FFA000, #FFD54F);
  background-image: linear-gradient(45deg, #FFA000, #FFD54F);
  background-repeat: repeat-x; }

.bg-gradient-x2-amber {
  background-image: -webkit-gradient(linear, left top, right top, from(#FFD54F), color-stop(50%, #FFC107), to(#FFE082));
  background-image: -webkit-linear-gradient(left, #FFD54F, #FFC107 50%, #FFE082);
  background-image: -moz-linear-gradient(left, #FFD54F, #FFC107 50%, #FFE082);
  background-image: -o-linear-gradient(left, #FFD54F, #FFC107 50%, #FFE082);
  background-image: linear-gradient(to right, #FFD54F, #FFC107 50%, #FFE082);
  background-repeat: no-repeat; }

.bg-gradient-y2-amber {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFD54F), color-stop(50%, #FFC107), to(#FFE082));
  background-image: -webkit-linear-gradient(#FFD54F, #FFC107 50%, #FFE082);
  background-image: -moz-linear-gradient(#FFD54F, #FFC107 50%, #FFE082);
  background-image: -o-linear-gradient(#FFD54F, #FFC107 50%, #FFE082);
  background-image: linear-gradient(#FFD54F, #FFC107 50%, #FFE082);
  background-repeat: no-repeat; }

.bg-gradient-radial-amber {
  background-image: -webkit-radial-gradient(circle, #FFA000, #FFD54F);
  background-image: -moz-radial-gradient(circle, #FFA000, #FFD54F);
  background-image: -o-radial-gradient(circle, #FFA000, #FFD54F);
  background-image: radial-gradient(circle, #FFA000, #FFD54F);
  background-repeat: no-repeat; }

.bg-gradient-striped-amber {
  background-image: -webkit-linear-gradient(45deg, #FFE082 25%, transparent 25%, transparent 50%, #FFE082 50%, #FFE082 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #FFE082 25%, transparent 25%, transparent 50%, #FFE082 50%, #FFE082 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #FFE082 25%, transparent 25%, transparent 50%, #FFE082 50%, #FFE082 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #FFE082 25%, transparent 25%, transparent 50%, #FFE082 50%, #FFE082 75%, transparent 75%, transparent); }

.bg-gradient-x-amber .card-header,
.bg-gradient-x-amber .card-footer,
.bg-gradient-y-amber .card-header,
.bg-gradient-y-amber .card-footer,
.bg-gradient-directional-amber .card-header,
.bg-gradient-directional-amber .card-footer,
.bg-gradient-radial-amber .card-header,
.bg-gradient-radial-amber .card-footer,
.bg-gradient-striped-amber .card-header,
.bg-gradient-striped-amber .card-footer,
.bg-gradient-x2-amber .card-header,
.bg-gradient-x2-amber .card-footer,
.bg-gradient-y2-amber .card-header,
.bg-gradient-y2-amber .card-footer {
  background-color: transparent; }

.bg-gradient-x-blue-grey {
  background-image: -webkit-gradient(linear, left top, right top, from(#455A64), to(#90A4AE));
  background-image: -webkit-linear-gradient(left, #455A64 0%, #90A4AE 100%);
  background-image: -moz-linear-gradient(left, #455A64 0%, #90A4AE 100%);
  background-image: -o-linear-gradient(left, #455A64 0%, #90A4AE 100%);
  background-image: linear-gradient(to right, #455A64 0%, #90A4AE 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-blue-grey {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#455A64), to(#90A4AE));
  background-image: -webkit-linear-gradient(top, #455A64 0%, #90A4AE 100%);
  background-image: -moz-linear-gradient(top, #455A64 0%, #90A4AE 100%);
  background-image: -o-linear-gradient(top, #455A64 0%, #90A4AE 100%);
  background-image: linear-gradient(to bottom, #455A64 0%, #90A4AE 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-blue-grey {
  background-image: -webkit-linear-gradient(45deg, #455A64, #90A4AE);
  background-image: -moz-linear-gradient(45deg, #455A64, #90A4AE);
  background-image: -o-linear-gradient(45deg, #455A64, #90A4AE);
  background-image: linear-gradient(45deg, #455A64, #90A4AE);
  background-repeat: repeat-x; }

.bg-gradient-x2-blue-grey {
  background-image: -webkit-gradient(linear, left top, right top, from(#90A4AE), color-stop(50%, #607D8B), to(#B0BEC5));
  background-image: -webkit-linear-gradient(left, #90A4AE, #607D8B 50%, #B0BEC5);
  background-image: -moz-linear-gradient(left, #90A4AE, #607D8B 50%, #B0BEC5);
  background-image: -o-linear-gradient(left, #90A4AE, #607D8B 50%, #B0BEC5);
  background-image: linear-gradient(to right, #90A4AE, #607D8B 50%, #B0BEC5);
  background-repeat: no-repeat; }

.bg-gradient-y2-blue-grey {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#90A4AE), color-stop(50%, #607D8B), to(#B0BEC5));
  background-image: -webkit-linear-gradient(#90A4AE, #607D8B 50%, #B0BEC5);
  background-image: -moz-linear-gradient(#90A4AE, #607D8B 50%, #B0BEC5);
  background-image: -o-linear-gradient(#90A4AE, #607D8B 50%, #B0BEC5);
  background-image: linear-gradient(#90A4AE, #607D8B 50%, #B0BEC5);
  background-repeat: no-repeat; }

.bg-gradient-radial-blue-grey {
  background-image: -webkit-radial-gradient(circle, #455A64, #90A4AE);
  background-image: -moz-radial-gradient(circle, #455A64, #90A4AE);
  background-image: -o-radial-gradient(circle, #455A64, #90A4AE);
  background-image: radial-gradient(circle, #455A64, #90A4AE);
  background-repeat: no-repeat; }

.bg-gradient-striped-blue-grey {
  background-image: -webkit-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent); }

.bg-gradient-x-blue-grey .card-header,
.bg-gradient-x-blue-grey .card-footer,
.bg-gradient-y-blue-grey .card-header,
.bg-gradient-y-blue-grey .card-footer,
.bg-gradient-directional-blue-grey .card-header,
.bg-gradient-directional-blue-grey .card-footer,
.bg-gradient-radial-blue-grey .card-header,
.bg-gradient-radial-blue-grey .card-footer,
.bg-gradient-striped-blue-grey .card-header,
.bg-gradient-striped-blue-grey .card-footer,
.bg-gradient-x2-blue-grey .card-header,
.bg-gradient-x2-blue-grey .card-footer,
.bg-gradient-y2-blue-grey .card-header,
.bg-gradient-y2-blue-grey .card-footer {
  background-color: transparent; }

.bg-gradient-x-grey-blue {
  background-image: -webkit-gradient(linear, left top, right top, from(#2C303B), to(#6F85AD));
  background-image: -webkit-linear-gradient(left, #2C303B 0%, #6F85AD 100%);
  background-image: -moz-linear-gradient(left, #2C303B 0%, #6F85AD 100%);
  background-image: -o-linear-gradient(left, #2C303B 0%, #6F85AD 100%);
  background-image: linear-gradient(to right, #2C303B 0%, #6F85AD 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-grey-blue {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#2C303B), to(#6F85AD));
  background-image: -webkit-linear-gradient(top, #2C303B 0%, #6F85AD 100%);
  background-image: -moz-linear-gradient(top, #2C303B 0%, #6F85AD 100%);
  background-image: -o-linear-gradient(top, #2C303B 0%, #6F85AD 100%);
  background-image: linear-gradient(to bottom, #2C303B 0%, #6F85AD 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-grey-blue {
  background-image: -webkit-linear-gradient(45deg, #2C303B, #6F85AD);
  background-image: -moz-linear-gradient(45deg, #2C303B, #6F85AD);
  background-image: -o-linear-gradient(45deg, #2C303B, #6F85AD);
  background-image: linear-gradient(45deg, #2C303B, #6F85AD);
  background-repeat: repeat-x; }

.bg-gradient-x2-grey-blue {
  background-image: -webkit-gradient(linear, left top, right top, from(#6F85AD), color-stop(50%, #1B2942), to(#B0BEC5));
  background-image: -webkit-linear-gradient(left, #6F85AD, #1B2942 50%, #B0BEC5);
  background-image: -moz-linear-gradient(left, #6F85AD, #1B2942 50%, #B0BEC5);
  background-image: -o-linear-gradient(left, #6F85AD, #1B2942 50%, #B0BEC5);
  background-image: linear-gradient(to right, #6F85AD, #1B2942 50%, #B0BEC5);
  background-repeat: no-repeat; }

.bg-gradient-y2-grey-blue {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#6F85AD), color-stop(50%, #1B2942), to(#B0BEC5));
  background-image: -webkit-linear-gradient(#6F85AD, #1B2942 50%, #B0BEC5);
  background-image: -moz-linear-gradient(#6F85AD, #1B2942 50%, #B0BEC5);
  background-image: -o-linear-gradient(#6F85AD, #1B2942 50%, #B0BEC5);
  background-image: linear-gradient(#6F85AD, #1B2942 50%, #B0BEC5);
  background-repeat: no-repeat; }

.bg-gradient-radial-grey-blue {
  background-image: -webkit-radial-gradient(circle, #2C303B, #6F85AD);
  background-image: -moz-radial-gradient(circle, #2C303B, #6F85AD);
  background-image: -o-radial-gradient(circle, #2C303B, #6F85AD);
  background-image: radial-gradient(circle, #2C303B, #6F85AD);
  background-repeat: no-repeat; }

.bg-gradient-striped-grey-blue {
  background-image: -webkit-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent); }

.bg-gradient-x-grey-blue .card-header,
.bg-gradient-x-grey-blue .card-footer,
.bg-gradient-y-grey-blue .card-header,
.bg-gradient-y-grey-blue .card-footer,
.bg-gradient-directional-grey-blue .card-header,
.bg-gradient-directional-grey-blue .card-footer,
.bg-gradient-radial-grey-blue .card-header,
.bg-gradient-radial-grey-blue .card-footer,
.bg-gradient-striped-grey-blue .card-header,
.bg-gradient-striped-grey-blue .card-footer,
.bg-gradient-x2-grey-blue .card-header,
.bg-gradient-x2-grey-blue .card-footer,
.bg-gradient-y2-grey-blue .card-header,
.bg-gradient-y2-grey-blue .card-footer {
  background-color: transparent; }

/*
  Gradient color mixin
*/
.bg-gradient-x-purple-blue {
  background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), to(#32cafe));
  background-image: -webkit-linear-gradient(left, #9f78ff, #32cafe);
  background-image: -moz-linear-gradient(left, #9f78ff, #32cafe);
  background-image: -o-linear-gradient(left, #9f78ff, #32cafe);
  background-image: linear-gradient(to right, #9f78ff, #32cafe);
  background-repeat: repeat-x; }

.btn-bg-gradient-x-purple-blue {
  border: none;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), color-stop(50%, #32cafe), to(#9f78ff));
  background-image: -webkit-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%);
  background-image: -moz-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%);
  background-image: -o-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%);
  background-image: linear-gradient(90deg, #9f78ff 0%, #32cafe 50%, #9f78ff 100%);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s; }
  .btn-bg-gradient-x-purple-blue.btn-glow {
    -webkit-box-shadow: 0 0 12px 0 #9f78ff;
    box-shadow: 0 0 12px 0 #9f78ff; }
  .btn-bg-gradient-x-purple-blue.active, .btn-bg-gradient-x-purple-blue:active, .btn-bg-gradient-x-purple-blue:hover {
    background-position: right center;
    background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), color-stop(50%, #32cafe), to(#9f78ff)) !important;
    background-image: -webkit-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%) !important;
    background-image: -moz-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%) !important;
    background-image: -o-linear-gradient(left, #9f78ff 0%, #32cafe 50%, #9f78ff 100%) !important;
    background-image: linear-gradient(90deg, #9f78ff 0%, #32cafe 50%, #9f78ff 100%) !important; }

.vertical-layout[data-color=bg-gradient-x-purple-blue] .navbar-container,
.vertical-layout[data-color=bg-gradient-x-purple-blue] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), to(#32cafe));
  background-image: -webkit-linear-gradient(left, #9f78ff, #32cafe);
  background-image: -moz-linear-gradient(left, #9f78ff, #32cafe);
  background-image: -o-linear-gradient(left, #9f78ff, #32cafe);
  background-image: linear-gradient(to right, #9f78ff, #32cafe);
  background-repeat: repeat-x; }

.horizontal-layout[data-color=bg-gradient-x-purple-blue] .navbar-horizontal,
.horizontal-layout[data-color=bg-gradient-x-purple-blue] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#9f78ff), to(#32cafe));
  background-image: -webkit-linear-gradient(left, #9f78ff, #32cafe);
  background-image: -moz-linear-gradient(left, #9f78ff, #32cafe);
  background-image: -o-linear-gradient(left, #9f78ff, #32cafe);
  background-image: linear-gradient(to right, #9f78ff, #32cafe);
  background-repeat: repeat-x; }

.bg-gradient-x-purple-red {
  background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), to(#f96f9b));
  background-image: -webkit-linear-gradient(left, #a376fc, #f96f9b);
  background-image: -moz-linear-gradient(left, #a376fc, #f96f9b);
  background-image: -o-linear-gradient(left, #a376fc, #f96f9b);
  background-image: linear-gradient(to right, #a376fc, #f96f9b);
  background-repeat: repeat-x; }

.btn-bg-gradient-x-purple-red {
  border: none;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), color-stop(50%, #f96f9b), to(#a376fc));
  background-image: -webkit-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%);
  background-image: -moz-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%);
  background-image: -o-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%);
  background-image: linear-gradient(90deg, #a376fc 0%, #f96f9b 50%, #a376fc 100%);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s; }
  .btn-bg-gradient-x-purple-red.btn-glow {
    -webkit-box-shadow: 0 0 12px 0 #a376fc;
    box-shadow: 0 0 12px 0 #a376fc; }
  .btn-bg-gradient-x-purple-red.active, .btn-bg-gradient-x-purple-red:active, .btn-bg-gradient-x-purple-red:hover {
    background-position: right center;
    background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), color-stop(50%, #f96f9b), to(#a376fc)) !important;
    background-image: -webkit-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%) !important;
    background-image: -moz-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%) !important;
    background-image: -o-linear-gradient(left, #a376fc 0%, #f96f9b 50%, #a376fc 100%) !important;
    background-image: linear-gradient(90deg, #a376fc 0%, #f96f9b 50%, #a376fc 100%) !important; }

.vertical-layout[data-color=bg-gradient-x-purple-red] .navbar-container,
.vertical-layout[data-color=bg-gradient-x-purple-red] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), to(#f96f9b));
  background-image: -webkit-linear-gradient(left, #a376fc, #f96f9b);
  background-image: -moz-linear-gradient(left, #a376fc, #f96f9b);
  background-image: -o-linear-gradient(left, #a376fc, #f96f9b);
  background-image: linear-gradient(to right, #a376fc, #f96f9b);
  background-repeat: repeat-x; }

.horizontal-layout[data-color=bg-gradient-x-purple-red] .navbar-horizontal,
.horizontal-layout[data-color=bg-gradient-x-purple-red] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#a376fc), to(#f96f9b));
  background-image: -webkit-linear-gradient(left, #a376fc, #f96f9b);
  background-image: -moz-linear-gradient(left, #a376fc, #f96f9b);
  background-image: -o-linear-gradient(left, #a376fc, #f96f9b);
  background-image: linear-gradient(to right, #a376fc, #f96f9b);
  background-repeat: repeat-x; }

.bg-gradient-x-blue-green {
  background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), to(#00e6af));
  background-image: -webkit-linear-gradient(left, #00cef9, #00e6af);
  background-image: -moz-linear-gradient(left, #00cef9, #00e6af);
  background-image: -o-linear-gradient(left, #00cef9, #00e6af);
  background-image: linear-gradient(to right, #00cef9, #00e6af);
  background-repeat: repeat-x; }

.btn-bg-gradient-x-blue-green {
  border: none;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), color-stop(50%, #00e6af), to(#00cef9));
  background-image: -webkit-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%);
  background-image: -moz-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%);
  background-image: -o-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%);
  background-image: linear-gradient(90deg, #00cef9 0%, #00e6af 50%, #00cef9 100%);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s; }
  .btn-bg-gradient-x-blue-green.btn-glow {
    -webkit-box-shadow: 0 0 12px 0 #00cef9;
    box-shadow: 0 0 12px 0 #00cef9; }
  .btn-bg-gradient-x-blue-green.active, .btn-bg-gradient-x-blue-green:active, .btn-bg-gradient-x-blue-green:hover {
    background-position: right center;
    background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), color-stop(50%, #00e6af), to(#00cef9)) !important;
    background-image: -webkit-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%) !important;
    background-image: -moz-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%) !important;
    background-image: -o-linear-gradient(left, #00cef9 0%, #00e6af 50%, #00cef9 100%) !important;
    background-image: linear-gradient(90deg, #00cef9 0%, #00e6af 50%, #00cef9 100%) !important; }

.vertical-layout[data-color=bg-gradient-x-blue-green] .navbar-container,
.vertical-layout[data-color=bg-gradient-x-blue-green] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), to(#00e6af));
  background-image: -webkit-linear-gradient(left, #00cef9, #00e6af);
  background-image: -moz-linear-gradient(left, #00cef9, #00e6af);
  background-image: -o-linear-gradient(left, #00cef9, #00e6af);
  background-image: linear-gradient(to right, #00cef9, #00e6af);
  background-repeat: repeat-x; }

.horizontal-layout[data-color=bg-gradient-x-blue-green] .navbar-horizontal,
.horizontal-layout[data-color=bg-gradient-x-blue-green] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#00cef9), to(#00e6af));
  background-image: -webkit-linear-gradient(left, #00cef9, #00e6af);
  background-image: -moz-linear-gradient(left, #00cef9, #00e6af);
  background-image: -o-linear-gradient(left, #00cef9, #00e6af);
  background-image: linear-gradient(to right, #00cef9, #00e6af);
  background-repeat: repeat-x; }

.bg-gradient-x-orange-yellow {
  background-image: -webkit-gradient(linear, left top, right top, from(#FF8008), to(#FFC837));
  background-image: -webkit-linear-gradient(left, #FF8008, #FFC837);
  background-image: -moz-linear-gradient(left, #FF8008, #FFC837);
  background-image: -o-linear-gradient(left, #FF8008, #FFC837);
  background-image: linear-gradient(to right, #FF8008, #FFC837);
  background-repeat: repeat-x; }

.btn-bg-gradient-x-orange-yellow {
  border: none;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#FF8008), color-stop(50%, #FFC837), to(#FF8008));
  background-image: -webkit-linear-gradient(left, #FF8008 0%, #FFC837 50%, #FF8008 100%);
  background-image: -moz-linear-gradient(left, #FF8008 0%, #FFC837 50%, #FF8008 100%);
  background-image: -o-linear-gradient(left, #FF8008 0%, #FFC837 50%, #FF8008 100%);
  background-image: linear-gradient(90deg, #FF8008 0%, #FFC837 50%, #FF8008 100%);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s; }
  .btn-bg-gradient-x-orange-yellow.btn-glow {
    -webkit-box-shadow: 0 0 12px 0 #FF8008;
    box-shadow: 0 0 12px 0 #FF8008; }
  .btn-bg-gradient-x-orange-yellow.active, .btn-bg-gradient-x-orange-yellow:active, .btn-bg-gradient-x-orange-yellow:hover {
    background-position: right center;
    background-image: -webkit-gradient(linear, left top, right top, from(#FF8008), color-stop(50%, #FFC837), to(#FF8008)) !important;
    background-image: -webkit-linear-gradient(left, #FF8008 0%, #FFC837 50%, #FF8008 100%) !important;
    background-image: -moz-linear-gradient(left, #FF8008 0%, #FFC837 50%, #FF8008 100%) !important;
    background-image: -o-linear-gradient(left, #FF8008 0%, #FFC837 50%, #FF8008 100%) !important;
    background-image: linear-gradient(90deg, #FF8008 0%, #FFC837 50%, #FF8008 100%) !important; }

.vertical-layout[data-color=bg-gradient-x-orange-yellow] .navbar-container,
.vertical-layout[data-color=bg-gradient-x-orange-yellow] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#FF8008), to(#FFC837));
  background-image: -webkit-linear-gradient(left, #FF8008, #FFC837);
  background-image: -moz-linear-gradient(left, #FF8008, #FFC837);
  background-image: -o-linear-gradient(left, #FF8008, #FFC837);
  background-image: linear-gradient(to right, #FF8008, #FFC837);
  background-repeat: repeat-x; }

.horizontal-layout[data-color=bg-gradient-x-orange-yellow] .navbar-horizontal,
.horizontal-layout[data-color=bg-gradient-x-orange-yellow] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#FF8008), to(#FFC837));
  background-image: -webkit-linear-gradient(left, #FF8008, #FFC837);
  background-image: -moz-linear-gradient(left, #FF8008, #FFC837);
  background-image: -o-linear-gradient(left, #FF8008, #FFC837);
  background-image: linear-gradient(to right, #FF8008, #FFC837);
  background-repeat: repeat-x; }

.bg-gradient-x-blue-cyan {
  background-image: -webkit-gradient(linear, left top, right top, from(#514A9D), to(#24C6DC));
  background-image: -webkit-linear-gradient(left, #514A9D, #24C6DC);
  background-image: -moz-linear-gradient(left, #514A9D, #24C6DC);
  background-image: -o-linear-gradient(left, #514A9D, #24C6DC);
  background-image: linear-gradient(to right, #514A9D, #24C6DC);
  background-repeat: repeat-x; }

.btn-bg-gradient-x-blue-cyan {
  border: none;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#514A9D), color-stop(50%, #24C6DC), to(#514A9D));
  background-image: -webkit-linear-gradient(left, #514A9D 0%, #24C6DC 50%, #514A9D 100%);
  background-image: -moz-linear-gradient(left, #514A9D 0%, #24C6DC 50%, #514A9D 100%);
  background-image: -o-linear-gradient(left, #514A9D 0%, #24C6DC 50%, #514A9D 100%);
  background-image: linear-gradient(90deg, #514A9D 0%, #24C6DC 50%, #514A9D 100%);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s; }
  .btn-bg-gradient-x-blue-cyan.btn-glow {
    -webkit-box-shadow: 0 0 12px 0 #514A9D;
    box-shadow: 0 0 12px 0 #514A9D; }
  .btn-bg-gradient-x-blue-cyan.active, .btn-bg-gradient-x-blue-cyan:active, .btn-bg-gradient-x-blue-cyan:hover {
    background-position: right center;
    background-image: -webkit-gradient(linear, left top, right top, from(#514A9D), color-stop(50%, #24C6DC), to(#514A9D)) !important;
    background-image: -webkit-linear-gradient(left, #514A9D 0%, #24C6DC 50%, #514A9D 100%) !important;
    background-image: -moz-linear-gradient(left, #514A9D 0%, #24C6DC 50%, #514A9D 100%) !important;
    background-image: -o-linear-gradient(left, #514A9D 0%, #24C6DC 50%, #514A9D 100%) !important;
    background-image: linear-gradient(90deg, #514A9D 0%, #24C6DC 50%, #514A9D 100%) !important; }

.vertical-layout[data-color=bg-gradient-x-blue-cyan] .navbar-container,
.vertical-layout[data-color=bg-gradient-x-blue-cyan] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#514A9D), to(#24C6DC));
  background-image: -webkit-linear-gradient(left, #514A9D, #24C6DC);
  background-image: -moz-linear-gradient(left, #514A9D, #24C6DC);
  background-image: -o-linear-gradient(left, #514A9D, #24C6DC);
  background-image: linear-gradient(to right, #514A9D, #24C6DC);
  background-repeat: repeat-x; }

.horizontal-layout[data-color=bg-gradient-x-blue-cyan] .navbar-horizontal,
.horizontal-layout[data-color=bg-gradient-x-blue-cyan] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#514A9D), to(#24C6DC));
  background-image: -webkit-linear-gradient(left, #514A9D, #24C6DC);
  background-image: -moz-linear-gradient(left, #514A9D, #24C6DC);
  background-image: -o-linear-gradient(left, #514A9D, #24C6DC);
  background-image: linear-gradient(to right, #514A9D, #24C6DC);
  background-repeat: repeat-x; }

.bg-gradient-x-red-pink {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), to(#e888b7));
  background-image: -webkit-linear-gradient(left, #ff5858, #e888b7);
  background-image: -moz-linear-gradient(left, #ff5858, #e888b7);
  background-image: -o-linear-gradient(left, #ff5858, #e888b7);
  background-image: linear-gradient(to right, #ff5858, #e888b7);
  background-repeat: repeat-x; }

.btn-bg-gradient-x-red-pink {
  border: none;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), color-stop(50%, #e888b7), to(#ff5858));
  background-image: -webkit-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%);
  background-image: -moz-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%);
  background-image: -o-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%);
  background-image: linear-gradient(90deg, #ff5858 0%, #e888b7 50%, #ff5858 100%);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s; }
  .btn-bg-gradient-x-red-pink.btn-glow {
    -webkit-box-shadow: 0 0 12px 0 #ff5858;
    box-shadow: 0 0 12px 0 #ff5858; }
  .btn-bg-gradient-x-red-pink.active, .btn-bg-gradient-x-red-pink:active, .btn-bg-gradient-x-red-pink:hover {
    background-position: right center;
    background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), color-stop(50%, #e888b7), to(#ff5858)) !important;
    background-image: -webkit-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%) !important;
    background-image: -moz-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%) !important;
    background-image: -o-linear-gradient(left, #ff5858 0%, #e888b7 50%, #ff5858 100%) !important;
    background-image: linear-gradient(90deg, #ff5858 0%, #e888b7 50%, #ff5858 100%) !important; }

.vertical-layout[data-color=bg-gradient-x-red-pink] .navbar-container,
.vertical-layout[data-color=bg-gradient-x-red-pink] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), to(#e888b7));
  background-image: -webkit-linear-gradient(left, #ff5858, #e888b7);
  background-image: -moz-linear-gradient(left, #ff5858, #e888b7);
  background-image: -o-linear-gradient(left, #ff5858, #e888b7);
  background-image: linear-gradient(to right, #ff5858, #e888b7);
  background-repeat: repeat-x; }

.horizontal-layout[data-color=bg-gradient-x-red-pink] .navbar-horizontal,
.horizontal-layout[data-color=bg-gradient-x-red-pink] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff5858), to(#e888b7));
  background-image: -webkit-linear-gradient(left, #ff5858, #e888b7);
  background-image: -moz-linear-gradient(left, #ff5858, #e888b7);
  background-image: -o-linear-gradient(left, #ff5858, #e888b7);
  background-image: linear-gradient(to right, #ff5858, #e888b7);
  background-repeat: repeat-x; }

.bg-gradient-x-blue-purple-1 {
  background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), to(#f8a1ec));
  background-image: -webkit-linear-gradient(left, #7396ff, #f8a1ec);
  background-image: -moz-linear-gradient(left, #7396ff, #f8a1ec);
  background-image: -o-linear-gradient(left, #7396ff, #f8a1ec);
  background-image: linear-gradient(to right, #7396ff, #f8a1ec);
  background-repeat: repeat-x; }

.btn-bg-gradient-x-blue-purple-1 {
  border: none;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), color-stop(50%, #f8a1ec), to(#7396ff));
  background-image: -webkit-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%);
  background-image: -moz-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%);
  background-image: -o-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%);
  background-image: linear-gradient(90deg, #7396ff 0%, #f8a1ec 50%, #7396ff 100%);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s; }
  .btn-bg-gradient-x-blue-purple-1.btn-glow {
    -webkit-box-shadow: 0 0 12px 0 #7396ff;
    box-shadow: 0 0 12px 0 #7396ff; }
  .btn-bg-gradient-x-blue-purple-1.active, .btn-bg-gradient-x-blue-purple-1:active, .btn-bg-gradient-x-blue-purple-1:hover {
    background-position: right center;
    background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), color-stop(50%, #f8a1ec), to(#7396ff)) !important;
    background-image: -webkit-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%) !important;
    background-image: -moz-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%) !important;
    background-image: -o-linear-gradient(left, #7396ff 0%, #f8a1ec 50%, #7396ff 100%) !important;
    background-image: linear-gradient(90deg, #7396ff 0%, #f8a1ec 50%, #7396ff 100%) !important; }

.vertical-layout[data-color=bg-gradient-x-blue-purple-1] .navbar-container,
.vertical-layout[data-color=bg-gradient-x-blue-purple-1] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), to(#f8a1ec));
  background-image: -webkit-linear-gradient(left, #7396ff, #f8a1ec);
  background-image: -moz-linear-gradient(left, #7396ff, #f8a1ec);
  background-image: -o-linear-gradient(left, #7396ff, #f8a1ec);
  background-image: linear-gradient(to right, #7396ff, #f8a1ec);
  background-repeat: repeat-x; }

.horizontal-layout[data-color=bg-gradient-x-blue-purple-1] .navbar-horizontal,
.horizontal-layout[data-color=bg-gradient-x-blue-purple-1] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#7396ff), to(#f8a1ec));
  background-image: -webkit-linear-gradient(left, #7396ff, #f8a1ec);
  background-image: -moz-linear-gradient(left, #7396ff, #f8a1ec);
  background-image: -o-linear-gradient(left, #7396ff, #f8a1ec);
  background-image: linear-gradient(to right, #7396ff, #f8a1ec);
  background-repeat: repeat-x; }

.bg-gradient-x-blue-purple-2 {
  background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), to(#d7b9f1));
  background-image: -webkit-linear-gradient(left, #85a6f3, #d7b9f1);
  background-image: -moz-linear-gradient(left, #85a6f3, #d7b9f1);
  background-image: -o-linear-gradient(left, #85a6f3, #d7b9f1);
  background-image: linear-gradient(to right, #85a6f3, #d7b9f1);
  background-repeat: repeat-x; }

.btn-bg-gradient-x-blue-purple-2 {
  border: none;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), color-stop(50%, #d7b9f1), to(#85a6f3));
  background-image: -webkit-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%);
  background-image: -moz-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%);
  background-image: -o-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%);
  background-image: linear-gradient(90deg, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s; }
  .btn-bg-gradient-x-blue-purple-2.btn-glow {
    -webkit-box-shadow: 0 0 12px 0 #85a6f3;
    box-shadow: 0 0 12px 0 #85a6f3; }
  .btn-bg-gradient-x-blue-purple-2.active, .btn-bg-gradient-x-blue-purple-2:active, .btn-bg-gradient-x-blue-purple-2:hover {
    background-position: right center;
    background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), color-stop(50%, #d7b9f1), to(#85a6f3)) !important;
    background-image: -webkit-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%) !important;
    background-image: -moz-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%) !important;
    background-image: -o-linear-gradient(left, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%) !important;
    background-image: linear-gradient(90deg, #85a6f3 0%, #d7b9f1 50%, #85a6f3 100%) !important; }

.vertical-layout[data-color=bg-gradient-x-blue-purple-2] .navbar-container,
.vertical-layout[data-color=bg-gradient-x-blue-purple-2] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), to(#d7b9f1));
  background-image: -webkit-linear-gradient(left, #85a6f3, #d7b9f1);
  background-image: -moz-linear-gradient(left, #85a6f3, #d7b9f1);
  background-image: -o-linear-gradient(left, #85a6f3, #d7b9f1);
  background-image: linear-gradient(to right, #85a6f3, #d7b9f1);
  background-repeat: repeat-x; }

.horizontal-layout[data-color=bg-gradient-x-blue-purple-2] .navbar-horizontal,
.horizontal-layout[data-color=bg-gradient-x-blue-purple-2] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#85a6f3), to(#d7b9f1));
  background-image: -webkit-linear-gradient(left, #85a6f3, #d7b9f1);
  background-image: -moz-linear-gradient(left, #85a6f3, #d7b9f1);
  background-image: -o-linear-gradient(left, #85a6f3, #d7b9f1);
  background-image: linear-gradient(to right, #85a6f3, #d7b9f1);
  background-repeat: repeat-x; }

.bg-gradient-x-blue-purple-3 {
  background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), to(#ecd2f8));
  background-image: -webkit-linear-gradient(left, #bccdff, #ecd2f8);
  background-image: -moz-linear-gradient(left, #bccdff, #ecd2f8);
  background-image: -o-linear-gradient(left, #bccdff, #ecd2f8);
  background-image: linear-gradient(to right, #bccdff, #ecd2f8);
  background-repeat: repeat-x; }

.btn-bg-gradient-x-blue-purple-3 {
  border: none;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), color-stop(50%, #ecd2f8), to(#bccdff));
  background-image: -webkit-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%);
  background-image: -moz-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%);
  background-image: -o-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%);
  background-image: linear-gradient(90deg, #bccdff 0%, #ecd2f8 50%, #bccdff 100%);
  -webkit-background-size: 200% auto;
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s; }
  .btn-bg-gradient-x-blue-purple-3.btn-glow {
    -webkit-box-shadow: 0 0 12px 0 #bccdff;
    box-shadow: 0 0 12px 0 #bccdff; }
  .btn-bg-gradient-x-blue-purple-3.active, .btn-bg-gradient-x-blue-purple-3:active, .btn-bg-gradient-x-blue-purple-3:hover {
    background-position: right center;
    background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), color-stop(50%, #ecd2f8), to(#bccdff)) !important;
    background-image: -webkit-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%) !important;
    background-image: -moz-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%) !important;
    background-image: -o-linear-gradient(left, #bccdff 0%, #ecd2f8 50%, #bccdff 100%) !important;
    background-image: linear-gradient(90deg, #bccdff 0%, #ecd2f8 50%, #bccdff 100%) !important; }

.vertical-layout[data-color=bg-gradient-x-blue-purple-3] .navbar-container,
.vertical-layout[data-color=bg-gradient-x-blue-purple-3] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), to(#ecd2f8));
  background-image: -webkit-linear-gradient(left, #bccdff, #ecd2f8);
  background-image: -moz-linear-gradient(left, #bccdff, #ecd2f8);
  background-image: -o-linear-gradient(left, #bccdff, #ecd2f8);
  background-image: linear-gradient(to right, #bccdff, #ecd2f8);
  background-repeat: repeat-x; }

.horizontal-layout[data-color=bg-gradient-x-blue-purple-3] .navbar-horizontal,
.horizontal-layout[data-color=bg-gradient-x-blue-purple-3] .content-wrapper-before {
  background-image: -webkit-gradient(linear, left top, right top, from(#bccdff), to(#ecd2f8));
  background-image: -webkit-linear-gradient(left, #bccdff, #ecd2f8);
  background-image: -moz-linear-gradient(left, #bccdff, #ecd2f8);
  background-image: -o-linear-gradient(left, #bccdff, #ecd2f8);
  background-image: linear-gradient(to right, #bccdff, #ecd2f8);
  background-repeat: repeat-x; }

@media (max-width: 767.98px) {
  .horizontal-layout .main-menu-content {
    background: #fff !important; } }
