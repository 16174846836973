#project-stats .ct-series-a .ct-area {
  fill-opacity: 1;
  fill: url(#area-gradient) !important; }

#project-stats .ct-series-b .ct-area {
  fill-opacity: 0.3;
  fill: url(#area-gradient) !important; }

#project-stats .ct-series-c .ct-area {
  fill-opacity: 0.15;
  fill: url(#area-gradient) !important; }

#project-stats .ct-line {
  stroke-width: 0px; }

#project-stats .ct-series-b .ct-point {
  stroke-width: 0px; }

#project-stats .ct-series-b .ct-point-circle {
  display: none; }

#project-stats .ct-series-b .ct-point-circle-transperent {
  display: none; }

#project-stats .ct-point {
  fill: transparent;
  stroke: transparent; }

#project-stats .ct-series-a .ct-point-circle {
  fill: #7396ff;
  stroke-width: 7;
  stroke: #fff; }

#project-stats .ct-series-c .ct-point-circle {
  fill: #f8a1ec;
  stroke-width: 7;
  stroke: #fff; }

#project-stats .ct-series-b .ct-point-circle {
  fill: transparent; }

.areaGradientShadow1 {
  -webkit-filter: drop-shadow(0px -4px 4px rgba(115, 150, 255, 0.5));
  filter: drop-shadow(0px -4px 4px rgba(115, 150, 255, 0.5));
  /* Same syntax as box-shadow, except for the spread property */ }

#project-stats .ct-series-a .ct-point-circle-transperent {
  fill: transparent; }

#project-stats .ct-series-b .ct-point-circle-transperent {
  fill: transparent; }

#project-stats .ct-series-c .ct-point-circle-transperent {
  fill: transparent; }

#Analytics-donut-chart .ct-slice-donut-solid {
  fill: url(#donutGradient1); }

#Analytics-donut-chart .ct-label {
  text-anchor: middle;
  alignment-baseline: middle;
  font-size: 30px;
  font-weight: 700;
  fill: rgba(255, 255, 255, 0.8); }

#Analytics-donut-chart .ct-chart-donut .ct-series {
  stroke: #fff;
  stroke-width: 0px; }

.donutShadow {
  -webkit-filter: drop-shadow(-5px 4px 6px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(-5px 4px 6px rgba(0, 0, 0, 0.5));
  /* Same syntax as box-shadow, except for the spread property */ }

#project-income-chart .ct-series-a .ct-bar {
  stroke: url(#barGradient2); }

#project-income-chart .ct-series-a .ct-slice-pie {
  fill: #f96f9b; }

.BarChartShadow {
  -webkit-filter: drop-shadow(-4px 9px 4px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(-4px 9px 4px rgba(0, 0, 0, 0.3));
  /* Same syntax as box-shadow, except 
                                                     for the spread property */ }

#new-projects .ct-series.ct-series-a .ct-line {
  stroke: url(#linear6) !important;
  stroke-width: 4px; }

#new-projects .ct-series.ct-series-a .ct-point-circle {
  fill: url(#linear6) !important;
  stroke-width: 9;
  stroke: #fff; }

#new-projects .ct-series.ct-series-b .ct-line {
  stroke: url(#linear7) !important;
  stroke-width: 4px; }

#new-projects .ct-series-a .ct-point-circle-transperent {
  fill: transparent; }

#new-projects .ct-series-b .ct-point-circle-transperent {
  fill: transparent; }

.GradientlineShadow {
  -webkit-filter: drop-shadow(0px -8px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0px -8px 4px rgba(0, 0, 0, 0.2));
  /* Same syntax as box-shadow, except 
                                                       for the spread property */ }

.chat-application .chats {
  position: relative;
  padding: 0 .5rem; }
