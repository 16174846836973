


#pull { display:none; }
@media only screen and (min-width: 1160px) and (max-width: 1330px) {
    .modal-lg, .modal-xl{max-width: 900px;
}

}

	
@media only screen and (min-width: 768px) and (max-width: 1024px) {
#home .col-sm-3.col-xs-10{ padding-left:0 !important;padding-right:0 !important;}
h3.heading_title{ font-size:24px;}
.carousel-caption {
    display: block;
    bottom: 30% !important;
    left: 3% !important;
    width: 67%;
}
.carousel-inner {
    background: #f5f5f5;
}
.carousel-caption h3{ font-size:30px;}
.carousel-caption p {
font-size: 18px; margin-bottom:40px;}
.carousel-inner .item {
    height:550px;
}
img.bannerImg{ display:none !important;}

.header{ position:fixed; min-height: 70px;}
.logo img{ width:155px !important;}
div#myCarousel {
    padding-top: 70px;
}
ul.socialmedia li a {
    width: 32px;
    height: 32px;
    font-size: 18px;
    line-height: 32px;
    margin: 15px 10px;

}
.features1 .col-md-4 {
    float: left;
    width: 33%;
}
.featured_mobile img {
    width: 100%;
}
.snip1489 {
margin: 40px 40px 15px;}
.service-box1 h4{ font-size:15px;}
.service-box1 p {
    font-size: 14px;
line-height: 24px;}
.carousel-inner img{ height:600px !important;}
.carousel-caption1{ top:5% !important; right:3%;}
.carousel-caption1 img {
    height: 488px !important;
    display: block !important;
}
#about .container, #howItworks .container, #features .container, #contact .container {
padding: 70px 15px 50px;}
#about h3, #howItworks h3, #features h3, .contact-form-side h3{ font-size:34px;}
p.lead {
font-size: 20px;}
.footerAbout, .footermiddle{ margin-bottom:1.5em;}
.footerright {
    float: right;
    width: 49%;
}
.footermiddle {
    float: left;
    width: 49%;
}


}
@media only screen and (min-width: 959px) and (max-width: 1024px) {
ul.main-nav > li > a{ padding:10px 25px !important;}
.nav-container{ padding-top:17px !important;}
	.footerright {
    float: none;
    width: 100% !important;
}
.footermiddle {
    float: none;
    width: 100% !important;
}
}



/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 599px) {	
h3.heading_title{ font-size:24px;}
.carousel-caption{ display:block; bottom:7% !important;}
.carousel-inner {
    background: #f5f5f5;
}
.carousel-caption h3{ font-size:32px;}
.carousel-caption p {
font-size: 18px; margin-bottom:40px;}
.carousel-inner .item {
    height: 950px;
}
img.bannerImg{ display:none !important;}

.header{ position:fixed; min-height: 70px;}
.logo img{ width:155px !important;}
div#myCarousel {
    padding-top: 70px;
}
ul.socialmedia li a {
    width: 32px;
    height: 32px;
    font-size: 18px;
    line-height: 32px;
    margin: 15px 10px;

}
.carousel-inner img{ height:600px !important;}
.carousel-caption1{ top:5% !important; right:21%;}
.carousel-caption1 img {
    height: 488px !important;
    display: block !important;
}
#about .container, #howItworks .container, #features .container, #contact .container {
padding: 70px 15px 50px;}
#about h3, #howItworks h3, #features h3, .contact-form-side h3{ font-size:36px;}
p.lead {
font-size: 20px;}
.footerAbout, .footermiddle{ margin-bottom:1.5em;}
.app-download img {
    margin-bottom: 10px;
    display: inline-block;
}
#scroll-top {
display: none !important;}
.download-back{ padding:55px 10px; text-align:center;}
.download-back a img {
    margin: 0 auto;
    text-align: center;
    display: block;
}
}





@media only screen and (min-width: 600px) and (max-width: 767px) {
h3.heading_title{ font-size:24px;}
.carousel-caption {
    display: block;
    bottom: 30% !important;
    left: 3% !important;
    width: 67%;
}
.carousel-inner {
    background: #f5f5f5;
}
.carousel-caption h3{ font-size:30px;}
.carousel-caption p {
font-size: 18px; margin-bottom:40px;}
.carousel-inner .item {
    height:550px;
}
img.bannerImg{ display:none !important;}

.header{ position:fixed; min-height: 70px;}
.logo img{ width:155px !important;}
div#myCarousel {
    padding-top: 70px;
}
ul.socialmedia li a {
    width: 32px;
    height: 32px;
    font-size: 18px;
    line-height: 32px;
    margin: 15px 10px;

}
.carousel-inner img{ height:600px !important;}
.carousel-caption1{ top:5% !important; right:3%;}
.carousel-caption1 img {
    height: 488px !important;
    display: block !important;
}
#about .container, #howItworks .container, #features .container, #contact .container {
padding: 70px 15px 50px;}
#about h3, #howItworks h3, #features h3, .contact-form-side h3{ font-size:34px;}
p.lead {
font-size: 20px;}
.footerAbout, .footermiddle{ margin-bottom:1.5em;}
	
}


@media only screen and (max-width: 479px) {	
.header{position:static;}
#home .col-sm-4.col-xs-10 {
    width: 100%;
}
#home .col-sm-8.col-xs-2 {
    width: 100%;
}
.right_navs{    padding-top: 0 !important;
    margin: 0 0 20px 0;
	    float: none !important;
    text-align: center;
}
.all_modals .modal-body {
padding: 70px 20px;}
.all_modals .modal-dialog {
width: 90%;}
.modal{top:124px;}
.user_login_div {
padding: 0 0px 0 0;}
.v_login_div {
    padding-right: 0;
    padding-left: 0px;
}
a.user_login_heading h3 {
font-size: 21px;}
h2.tagline {
    margin-top: 63px;
    font-size: 1.7em;
}
.just_pattern{height:98.5%;}
#scroll-top{right:43%;}
#video_background{width:100%;}
a.footer_logo .img-responsive {
    width: 200px !important;
    min-height: auto;
}
}


@media only screen and (max-width: 320px) {
	/*==============newrestform====================*/
.newrestform{ width:100%;}
ul.socialmedia li a{ margin:15px 8px;}
.input-lg{ font-size:14px;}

	
}


@media only screen and (max-width: 1024px) {	
.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}	
.navbar-toggle{ margin-top:30px !important;}
.nav.navbar-nav {
    margin: 0 0 0 0px !important;
}
.navbar-default .navbar-nav > li > a{margin: 5px 5px !important;}
																					

}

