/*=========================================================================================
    File Name: vertical-menu.scss
    Description: A calssic vertical style menu with expand and collops support. It support
    light & dark version, filpped layout, right side icons, native scroll and borders menu
    item seperation.
    ----------------------------------------------------------------------------------------
    Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
    Version: 1.2
    Author: ThemeSelection
    Author URL: https://themeselection.com/
==========================================================================================*/
@media (min-width: 576px) {
  .vertical-layout.vertical-menu.menu-flipped .main-menu {
    right: 0; } }

.vertical-layout.vertical-menu .navbar .navbar-brand {
  white-space: nowrap; }

.vertical-layout.vertical-menu.menu-expanded .navbar-header {
  width: 260px;
  z-index: 999;
  float: left; }

.vertical-layout.vertical-menu.menu-expanded .navbar.navbar-brand-center .navbar-header {
  width: auto;
  z-index: 999; }

.vertical-layout.vertical-menu.menu-expanded .navbar .navbar-container {
  margin-left: 260px; }

.vertical-layout.vertical-menu.menu-expanded .navbar .navbar-brand .brand-text {
  -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
  -moz-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
  -o-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein; }

.vertical-layout.vertical-menu.menu-expanded .main-menu {
  width: 260px;
  -webkit-transition: 300ms ease all;
  -o-transition: 300ms ease all;
  -moz-transition: 300ms ease all;
  transition: 300ms ease all;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }
  .vertical-layout.vertical-menu.menu-expanded .main-menu .navigation .navigation-header .ft-minus {
    display: none; }
  .vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > i:before {
    font-size: 1.5rem; }
  .vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > span {
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: -o-transform 0.3s ease;
    -moz-transition: transform 0.3s ease, -moz-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease; }
    .vertical-layout.vertical-menu.menu-expanded .main-menu .navigation > li > a > span.badge {
      position: absolute;
      right: 20px;
      top: 18px; }
  .vertical-layout.vertical-menu.menu-expanded .main-menu .navigation li.has-sub > a:not(.mm-next):after {
    content: "\f112";
    font-family: 'LineAwesome';
    font-size: 0.85rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 14px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out; }
  .vertical-layout.vertical-menu.menu-expanded .main-menu .navigation li.has-sub .has-sub > a:not(.mm-next):after {
    top: 8px; }
  .vertical-layout.vertical-menu.menu-expanded .main-menu .navigation li.open > a:not(.mm-next):after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }
  .vertical-layout.vertical-menu.menu-expanded .main-menu .main-menu-footer {
    width: 260px; }
  .vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation > li > a > i {
    display: inline-block;
    margin-right: 20px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 12%;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12); }
  .vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation .hover > a > i, .vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation:hover > a > i,
  .vertical-layout.vertical-menu.menu-expanded .main-menu.menu-light .navigation .open > a > i {
    -webkit-box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
    box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3); }
  .vertical-layout.vertical-menu.menu-expanded .main-menu.menu-dark .navigation > li > a > i {
    display: inline-block;
    margin-right: 20px;
    background: #2c303b;
    width: 40px;
    height: 40px;
    border-radius: 12%;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px black;
    box-shadow: 0px 0px 10px black; }
  .vertical-layout.vertical-menu.menu-expanded .main-menu.menu-dark .navigation .hover > a > i, .vertical-layout.vertical-menu.menu-expanded .main-menu.menu-dark .navigation:hover > a > i,
  .vertical-layout.vertical-menu.menu-expanded .main-menu.menu-dark .navigation .open > a > i {
    -webkit-box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
    box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3); }

.vertical-layout.vertical-menu.menu-expanded .content,
.vertical-layout.vertical-menu.menu-expanded .footer {
  margin-left: 260px; }

.vertical-layout.vertical-menu.menu-expanded .navbar-brand-center .content,
.vertical-layout.vertical-menu.menu-expanded .navbar-brand-center .footer {
  margin-left: 0; }

.vertical-layout.vertical-menu.menu-collapsed .navbar-header {
  width: 60px;
  z-index: 999;
  float: left; }

.vertical-layout.vertical-menu.menu-collapsed .navbar.navbar-brand-center .navbar-header {
  width: auto;
  z-index: 999; }

.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-brand {
  padding: 17px 0px; }

.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-container {
  margin-left: 60px; }

.vertical-layout.vertical-menu.menu-collapsed .navbar .navbar-brand .brand-text {
  -webkit-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
  -moz-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
  -o-animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadeout; }

.vertical-layout.vertical-menu.menu-collapsed .main-menu {
  width: 60px;
  -webkit-transform: translateZ(-160px) translateX(-160px);
  -moz-transform: translateZ(-160px) translateX(-160px);
  transform: translateZ(-160px) translateX(-160px);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: 300ms ease all;
  -o-transition: 300ms ease all;
  -moz-transition: 300ms ease all;
  transition: 300ms ease all; }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-footer {
    width: 60px; }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-footer,
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media- .media-heading,
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media- .text-muted,
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media-right {
    display: none; }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-header .media- {
    opacity: 0; }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .user-content {
    padding: 20px 10px; }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > span.menu-title,
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content a.menu-title {
    left: 60px;
    width: 260px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    background-color: #fa626b;
    border-color: #fa626b;
    padding: 18px 30px; }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content {
    left: 60px;
    width: 260px;
    -webkit-transition: visibility .25s, opacity .25s;
    -o-transition: visibility .25s, opacity .25s;
    -moz-transition: visibility .25s, opacity .25s;
    transition: visibility .25s, opacity .25s;
    -webkit-box-shadow: 25px 5px 75px 2px rgba(64, 70, 74, 0.2);
    box-shadow: 25px 5px 75px 2px rgba(64, 70, 74, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.02); }
    .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li {
      white-space: nowrap;
      position: relative; }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li a {
        display: block;
        padding: 8px 30px 8px 30px;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        transition: all 0.2s ease; }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.active a {
        color: #fa626b; }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.has-sub > a:not(.mm-next):after {
        content: "\f112";
        font-family: 'LineAwesome';
        font-size: 0.85rem;
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 14px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: -webkit-transform 0.2s ease-in-out; }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.has-sub .has-sub > a:not(.mm-next):after {
        top: 8px; }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.open > a:not(.mm-next):after {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg); }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li:hover > a, .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.hover > a {
        -webkit-transform: translateX(4px);
        -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
        -o-transform: translateX(4px);
        transform: translateX(4px); }
        .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li:hover > a > a, .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.hover > a > a {
          -webkit-transform: translateX(-4px);
          -moz-transform: translateX(-4px);
          -ms-transform: translateX(-4px);
          -o-transform: translateX(-4px);
          transform: translateX(-4px); }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu.menu-icon-right .navigation > li > a {
    padding: 12px 30px 12px 18px; }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation {
    overflow: visible; }
    .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header {
      padding: 22px 20px 8px 20px; }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header .ft-minus {
        display: block;
        font-size: 1.3rem; }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header span {
        display: none; }
    .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a {
      padding: 10px 0px 10px 18px; }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > span {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        right: -260px;
        width: 260px;
        font-weight: 600;
        color: #fff;
        text-align: left;
        background-color: #6967ce;
        border-color: #6967ce;
        padding: 14px 20px; }
      .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > i {
        margin-right: 0;
        font-size: 1.5rem;
        line-height: 1;
        visibility: visible;
        opacity: 1; }
    .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > ul {
      display: none; }
    .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a > span {
      display: none; }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .mTSWrapper {
    overflow: visible; }
  .vertical-layout.vertical-menu.menu-collapsed .main-menu .main-menu-content > ul.menu-content li.has-sub > a:not(.mm-next):after {
    top: 10px; }

.vertical-layout.vertical-menu.menu-collapsed .content,
.vertical-layout.vertical-menu.menu-collapsed .footer {
  margin-left: 60px; }

.vertical-layout.vertical-menu.menu-collapsed .navbar-brand-center .content,
.vertical-layout.vertical-menu.menu-collapsed .navbar-brand-center .footer {
  margin-left: 0; }

.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .content {
  margin: 0;
  margin-right: 60px; }

@media (min-width: 576px) {
  .vertical-layout.vertical-menu.menu-collapsed.menu-flipped .main-menu {
    right: 0; }
    .vertical-layout.vertical-menu.menu-collapsed.menu-flipped .main-menu span.menu-title {
      left: -260px !important; }
    .vertical-layout.vertical-menu.menu-collapsed.menu-flipped .main-menu ul.menu-content {
      right: 60px;
      left: inherit; } }

.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .navbar-header {
  float: right; }

.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .navbar .navbar-container {
  margin: 0;
  margin-right: 60px; }

.vertical-layout.vertical-menu.menu-collapsed.menu-flipped .footer {
  margin: 0;
  margin-right: 60px; }

[data-textdirection="rtl"] .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li > a {
  padding: 14px 16px !important; }

[data-textdirection="rtl"] .vertical-layout.vertical-menu.menu-collapsed .main-menu .navigation > li.navigation-header .ft-minus {
  padding: 12px 0px; }

@media (min-width: 992px) {
  .vertical-layout.vertical-menu .main-menu {
    width: 260px; }
  .vertical-layout.vertical-menu .navbar-header {
    width: 260px; }
  .vertical-layout.vertical-menu .content,
  .vertical-layout.vertical-menu .footer {
    margin-left: 260px; }
  .vertical-layout.vertical-menu.menu-flipped .content {
    margin: 0;
    margin-right: 260px; }
  .vertical-layout.vertical-menu.menu-flipped .navbar-header {
    float: right; }
  .vertical-layout.vertical-menu.menu-flipped .navbar .navbar-container {
    margin: 0;
    margin-right: 260px; }
  .vertical-layout.vertical-menu.menu-flipped .footer {
    margin: 0;
    margin-right: 260px; } }

@media (max-width: 991.98px) {
  .vertical-layout.vertical-menu .main-menu {
    width: 60px; }
    .vertical-layout.vertical-menu .main-menu .main-menu-footer {
      width: 260px; }
  .vertical-layout.vertical-menu .navbar-header {
    width: 60px; }
  .vertical-layout.vertical-menu .content,
  .vertical-layout.vertical-menu .footer {
    margin-left: 60px; } }

@media (max-width: 767.98px) {
  .vertical-layout.vertical-menu .content,
  .vertical-layout.vertical-menu .footer {
    margin-left: 0; }
  .vertical-layout.vertical-menu.menu-expanded .navbar .navbar-container {
    margin-left: 0px !important; }
  .vertical-layout.vertical-menu.menu-expanded .content,
  .vertical-layout.vertical-menu.menu-expanded .footer {
    margin-left: 0; }
  html.loading .main-menu, html.loading .vertical-overlay-menu.menu-hide .main-menu, .vertical-overlay-menu.menu-hide html.loading .main-menu {
    opacity: 0;
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0); } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-moz-keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-o-keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

/*=========================================================================================
	File Name: vertical-overlay-menu.scss
	Description: A overlay style vertical menu with show and hide support. It support
	light & dark version, filpped layout, right side icons, native scroll and borders menu
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
	Version: 1.2
	Author: ThemeSelection
	Author URL: https://themeselection.com/
==========================================================================================*/
.vertical-overlay-menu .content {
  margin-left: 0; }

.vertical-overlay-menu .navbar .navbar-header {
  width: 260px;
  z-index: 999; }

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-container {
  margin-left: 0; }

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-header {
  width: auto;
  z-index: 999; }

.vertical-overlay-menu .main-menu, .vertical-overlay-menu.menu-hide .main-menu {
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: width 0.25s, opacity 0.25s, -webkit-transform 0.25s;
  transition: width 0.25s, opacity 0.25s, -webkit-transform 0.25s;
  -o-transition: width 0.25s, opacity 0.25s, -o-transform 0.25s;
  -moz-transition: width 0.25s, opacity 0.25s, transform 0.25s, -moz-transform 0.25s;
  transition: width 0.25s, opacity 0.25s, transform 0.25s;
  transition: width 0.25s, opacity 0.25s, transform 0.25s, -webkit-transform 0.25s, -moz-transform 0.25s, -o-transform 0.25s;
  width: 260px;
  left: -260px; }
  .vertical-overlay-menu .main-menu .navigation .navigation-header .ft-minus {
    display: none; }
  .vertical-overlay-menu .main-menu .navigation > li > a > i {
    font-size: 1.5rem;
    margin-right: 12px;
    float: left; }
    .vertical-overlay-menu .main-menu .navigation > li > a > i:before {
      -webkit-transition: 200ms ease all;
      -o-transition: 200ms ease all;
      -moz-transition: 200ms ease all;
      transition: 200ms ease all; }
  .vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after {
    content: "\f112";
    font-family: 'LineAwesome';
    font-size: 0.85rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 14px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out; }
  .vertical-overlay-menu .main-menu .navigation li.has-sub .has-sub > a:not(.mm-next):after {
    top: 8px; }
  .vertical-overlay-menu .main-menu .navigation li.open > a:not(.mm-next):after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }
  .vertical-overlay-menu .main-menu .main-menu-footer {
    bottom: 55px; }
  .vertical-overlay-menu .main-menu .main-menu-footer {
    width: 260px; }
  .vertical-overlay-menu .main-menu.boxed-layout {
    position: relative; }
    .vertical-overlay-menu .main-menu.boxed-layout .main-menu.menu-fixed {
      position: absolute; }

.vertical-overlay-menu.menu-open .main-menu {
  opacity: 1;
  -webkit-transform: translate3d(260px, 0, 0);
  -moz-transform: translate3d(260px, 0, 0);
  transform: translate3d(260px, 0, 0);
  -webkit-transition: width 0.25s, opacity 0.25s, -webkit-transform 0.25s;
  transition: width 0.25s, opacity 0.25s, -webkit-transform 0.25s;
  -o-transition: width 0.25s, opacity 0.25s, -o-transform 0.25s;
  -moz-transition: width 0.25s, opacity 0.25s, transform 0.25s, -moz-transform 0.25s;
  transition: width 0.25s, opacity 0.25s, transform 0.25s;
  transition: width 0.25s, opacity 0.25s, transform 0.25s, -webkit-transform 0.25s, -moz-transform 0.25s, -o-transform 0.25s; }
  .vertical-overlay-menu.menu-open .main-menu.menu-light .navigation > li > a > i {
    display: inline-block;
    margin-right: 20px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 12%;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12); }
  .vertical-overlay-menu.menu-open .main-menu.menu-light .navigation .hover > a > i, .vertical-overlay-menu.menu-open .main-menu.menu-light .navigation:hover > a > i,
  .vertical-overlay-menu.menu-open .main-menu.menu-light .navigation .open > a > i {
    -webkit-box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
    box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3); }
  .vertical-overlay-menu.menu-open .main-menu.menu-dark .navigation > li > a > i {
    display: inline-block;
    margin-right: 20px;
    background: #2c303b;
    width: 40px;
    height: 40px;
    border-radius: 12%;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px black;
    box-shadow: 0px 0px 10px black; }
  .vertical-overlay-menu.menu-open .main-menu.menu-dark .navigation .hover > a > i, .vertical-overlay-menu.menu-open .main-menu.menu-dark .navigation:hover > a > i,
  .vertical-overlay-menu.menu-open .main-menu.menu-dark .navigation .open > a > i {
    -webkit-box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3);
    box-shadow: 0px 0px 20px rgba(253, 99, 107, 0.3); }

.vertical-overlay-menu.menu-flipped .main-menu {
  right: -260px;
  left: inherit; }

.vertical-overlay-menu.menu-flipped .navbar .navbar-container {
  margin: 0;
  margin-right: 260px; }

.vertical-overlay-menu.menu-flipped .navbar .navbar-header {
  float: right; }

.vertical-overlay-menu.menu-flipped.menu-open .main-menu {
  -webkit-transform: translate3d(-260px, 0, 0);
  -moz-transform: translate3d(-260px, 0, 0);
  transform: translate3d(-260px, 0, 0); }

@media (max-width: 991.98px) {
  .vertical-overlay-menu .main-menu .main-menu-footer {
    bottom: 0px; } }

.main-menu.menu-light .navigation > li > a i {
    display: inline-block;
    margin-right: 20px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 12%;
    line-height: 40px;
    vertical-align: middle;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
}
#navbar-mobile .flag-select {
    padding-top: 5px;
    background: #fff;
    height: 36px;
    border-radius: 4px;
    top: 16px;
}

.navigation {
    font-size: 1rem;
    letter-spacing: 0.2px;
    font-family: "Comfortaa", cursive, "Times New Roman", Times, serif;
    font-weight: 700;
    overflow-y: hidden;
    padding-bottom: 20px;
}