a:focus {
  outline: none; }

.font-small-1 {
  font-size: 0.7rem !important; }

.font-small-2 {
  font-size: 0.8rem !important; }

.font-small-3 {
  font-size: 0.9rem !important; }

.font-medium-1 {
  font-size: 1.1rem !important; }

.font-medium-2 {
  font-size: 1.2rem !important; }

.font-medium-3 {
  font-size: 1.3rem !important; }

.font-medium-4 {
  font-size: 1.4rem !important; }

.font-medium-5 {
  font-size: 1.5rem !important; }

.font-large-1 {
  font-size: 2rem !important; }

.font-large-2 {
  font-size: 3rem !important; }

.font-large-3 {
  font-size: 4rem !important; }

.font-large-4 {
  font-size: 5rem !important; }

.font-large-5 {
  font-size: 6rem !important; }

.text-bold-300 {
  font-weight: 300; }

.text-bold-400 {
  font-weight: 400; }

.text-bold-500 {
  font-weight: 500; }

.text-bold-600 {
  font-weight: 600; }

.text-bold-700 {
  font-weight: 700; }

.text-italic {
  font-style: italic; }

.text-highlight {
  padding: 4px 6px; }

ol li, ul li, dl li {
  line-height: 1.8; }

code {
  padding: .2rem .4rem;
  font-size: 90%;
  color: #F74B5C;
  background-color: #f2f3f5;
  border-radius: 0.25rem; }

pre {
  background-color: #f7f7f9; }
  pre code {
    background-color: transparent !important; }

.bd-example-row .row + .row {
  margin-top: 1rem; }

.bd-example-row .row > .col,
.bd-example-row .row > [class^="col-"] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2); }

.bd-example-row .flex-items-top,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-bottom {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1); }

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1); }

.bd-highlight {
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.15); }

.example-container {
  width: 800px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.example-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.example-content-main {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .example-content-main {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .example-content-main {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.66667%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }

.example-content-secondary {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .example-content-secondary {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .example-content-secondary {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
      -moz-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.table-white-space th, .table-white-space td {
  white-space: nowrap; }

.table-borderless td, .table-borderless th {
  border: none; }

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3ebf3;
  border-top: 1px solid #e3ebf3; }

.table-bordered th, .table-bordered td {
  border: 1px solid #e3ebf3; }

.table th, .table td {
  border-bottom: 1px solid #e3ebf3; }

.table.table-padded {
  border-collapse: separate;
  border-spacing: 0 7px;
  overflow: visible; }
  .table.table-padded tbody > tr {
    background-color: #fff; }
  .table.table-padded td {
    border-top: 0; }
  .table.table-padded thead th {
    text-transform: uppercase;
    font-size: 0.85rem; }

.table-bordered {
  border: 1px solid #e3ebf3; }

.table-inverse {
  color: #FFFFFF;
  background-color: #464855; }

.table.table-column th, .table.table-column td {
  border: none;
  border-left: 1px solid #e3ebf3; }

.table.table-column th:first-child, .table.table-column td:first-child {
  border-left: none; }

.table th, .table td {
  padding: 0.75rem 2rem; }

.table.table-xl th, .table.table-xl td {
  padding: 1.25rem 2rem; }

.table.table-lg th, .table.table-lg td {
  padding: 0.9rem 2rem; }

.table.table-de th, .table.table-de td {
  padding: 0.75rem 2rem; }

.table.table-sm th, .table.table-sm td {
  padding: 0.6rem 2rem; }

.table.table-xs th, .table.table-xs td {
  padding: 0.4rem 2rem; }

/*
* Table sizing
*/
.table-borderless thead th {
  border-top: 1px solid #e3ebf3; }

.table-borderless > tbody > tr > td, .table-borderless > tbody > tr > th {
  border: 0; }

.table > thead > tr.border-solid > th {
  border-bottom-width: 2px; }

.table > thead > tr.border-double > th {
  border-bottom-width: 4px;
  border-bottom-style: double; }

.table > tbody > tr.border-dashed > td {
  border-top-style: dashed; }

.border-custom-color td {
  border-top: inherit;
  border-bottom: inherit; }

form {
  /* .form-body{
        overflow: hidden;
    } */ }
  form label {
    color: #2b335e; }
  form .form-group {
    margin-bottom: 1.5rem; }
  form .form-control {
    border: 1px solid #cacfe7;
    color: #3b4781; }
    form .form-control::-webkit-input-placeholder {
      color: #aeb5da; }
    form .form-control:-moz-placeholder {
      /* Firefox 18- */
      color: #aeb5da; }
    form .form-control::-moz-placeholder {
      /* Firefox 19+ */
      color: #aeb5da; }
    form .form-control:-ms-input-placeholder {
      color: #aeb5da; }
  form .form-section {
    color: #2C303B;
    line-height: 3rem;
    margin-bottom: 20px;
    border-bottom: 1px solid #2C303B; }
    form .form-section i {
      font-size: 20px;
      margin-left: 5px;
      margin-right: 10px; }
  form .form-actions {
    border-top: 1px solid #d1d5ea;
    padding: 20px 0;
    margin-top: 20px; }
    form .form-actions.filled {
      background-color: #F4F5FA; }
    form .form-actions.center {
      text-align: center; }
    form .form-actions.right {
      text-align: right; }
    form .form-actions.top {
      border-top: 0;
      border-bottom: 1px solid #d1d5ea;
      margin-top: 0;
      margin-bottom: 20px; }
    @media (max-width: 767.98px) {
      form .form-actions .buttons-group {
        float: left !important;
        margin-bottom: 10px; } }
  @media (min-width: 576px) {
    form.form-horizontal .form-group .label-control {
      text-align: right; } }
  form.row-separator .form-group {
    margin: 0;
    border-bottom: 1px solid #e9ebf5; }
    form.row-separator .form-group .label-control {
      padding-top: 1.5rem; }
    form.row-separator .form-group > div {
      padding: 1.2rem;
      padding-right: 0; }
    form.row-separator .form-group.last {
      border-bottom: 0; }
  form.form-bordered .form-group {
    margin: 0;
    border-bottom: 1px solid #e9ebf5; }
    form.form-bordered .form-group .label-control {
      padding-top: 1.5rem; }
    form.form-bordered .form-group > div {
      padding: 1.2rem;
      padding-right: 0;
      border-left: 1px solid #e9ebf5; }
    form.form-bordered .form-group.last {
      border-bottom: 0; }
  form.striped-rows .form-group {
    margin: 0; }
    form.striped-rows .form-group .label-control {
      padding-top: 1.5rem; }
    form.striped-rows .form-group > div {
      padding: 1.2rem;
      padding-right: 0; }
    form.striped-rows .form-group:nth-child(even) {
      background-color: #F4F5FA; }
  form.striped-labels .form-group {
    margin: 0; }
    form.striped-labels .form-group .label-control {
      padding-top: 1.5rem; }
    form.striped-labels .form-group > div {
      padding: 1.2rem;
      padding-right: 0;
      background-color: white; }
    form.striped-labels .form-group:nth-child(even) {
      background-color: #F4F5FA; }
  form .custom-control-indicator {
    width: 1.1rem;
    height: 1.075rem;
    line-height: 1.05rem;
    top: 0.15rem;
    left: 0; }
    form .custom-control-indicator + .custom-control-description {
      margin-left: 14px; }

select.form-control {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

/*Bootstrap Enhance */
.custom-checkbox {
  margin-bottom: 0; }
  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(30, 159, 242, 0.5); }

.custom-checkbox input:disabled:checked ~ .custom-control-indicator {
  opacity: 0.5; }

.custom-checkbox input:disabled ~ .custom-control-indicator {
  cursor: not-allowed; }

.custom-control-input > input:focus ~ .custom-control-indicator {
  padding: 0.51rem; }

.custom-control-indicator {
  width: 1.1rem;
  height: 1.075rem;
  line-height: 1.05rem;
  top: 0.15rem; }
  .custom-control-indicator + .custom-control-description {
    margin-left: 1rem; }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background: #ADB5BD; }

.form-control:disabled, .form-control[readonly] {
  background-color: #ECEFF1; }

.input-group-addon {
  background-color: #ECEFF1;
  border-color: #BABFC7; }

.btn {
  font-weight: 600;
  letter-spacing: 0.8px; }
  .btn.btn-square {
    border-radius: 0; }
  .btn.btn-round {
    border-radius: 2rem; }
  .btn:focus, .btn.focus, .btn:active, .btn.active {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .btn.btn-icon i {
    font-size: 1.1rem; }
  .btn.btn-icon.btn-pure {
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .btn.btn-icon.btn-pure i {
      font-size: 1.25rem; }
  .btn.btn-social {
    padding: 0.7rem 0.25rem 0.85rem 3.75rem; }

.btn-min-width {
  min-width: 8.5rem; }

.btn-float {
  padding: 15px;
  white-space: normal;
  -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); }
  .btn-float.btn-link {
    padding: 10px; }
  .btn-float i {
    display: block;
    margin: 0;
    top: 0; }
  .btn-float.btn-float-lg i, .btn-float.btn-float-lg span {
    font-size: 2rem; }
  .btn-float.btn-float-lg i + span {
    font-size: 1.1rem; }
  .btn-float > i + span {
    display: block;
    padding-top: 10px;
    margin-bottom: -6px; }

.collapse-icon .card-header {
  position: relative; }

.collapse-icon [data-toggle="collapse"]:after {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -8px;
  font-family: 'feather';
  content: "\e929";
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s; }

.collapse-icon [data-toggle="collapse"]:before {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -8px;
  font-family: 'feather';
  content: "\e927";
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s; }

.collapse-icon.left .card-header .btn {
  padding-left: 28px; }
  .collapse-icon.left .card-header .btn:before, .collapse-icon.left .card-header .btn:after {
    left: 20px;
    right: auto; }

.collapse-icon [aria-expanded="true"]:before {
  opacity: 0; }

.collapse-icon [aria-expanded="false"]:after {
  opacity: 0; }

.collapse-icon.accordion-icon-rotate [aria-expanded="true"]:before {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg); }

.collapse-icon.accordion-icon-rotate [aria-expanded="false"]:after {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg); }

.dropdown.dropdown-demo {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown .dropdown-menu {
  border: none;
  border-radius: 0.35rem;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: -0.1rem 0 0;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(64, 70, 74, 0.25);
  box-shadow: 0px 0px 15px 1px rgba(64, 70, 74, 0.25);
  min-width: 11rem;
  -webkit-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
  -moz-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
  -o-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
  animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1; }
  .dropdown .dropdown-menu .dropdown-header {
    padding: 10px 20px;
    margin-top: 10px;
    text-transform: uppercase;
    color: #626E82; }
  .dropdown .dropdown-menu .dropdown-item {
    padding: 10px 20px;
    width: 100%; }
    .dropdown .dropdown-menu .dropdown-item:active a, .dropdown .dropdown-menu .dropdown-item.active a {
      color: #fff; }

.dropdown .dropdown-divider {
  border: 1px solid #E4E5EC; }

.dropdown i {
  margin-right: 0.5rem; }

.dropdown .dropdown-menu {
  border: none;
  border-radius: 0.35rem;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: -0.1rem 0 0;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(64, 70, 74, 0.25) !important;
  box-shadow: 0px 0px 15px 1px rgba(64, 70, 74, 0.25) !important;
  min-width: 11rem;
  -webkit-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
  -moz-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
  -o-animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1;
  animation: menu-header-fade-in .3s ease 1,menu-header-move-up .3s ease-out 1; }
  .dropdown .dropdown-menu .dropdown-header {
    padding: 10px 20px;
    margin-top: 10px;
    text-transform: uppercase;
    color: #626E82; }
  .dropdown .dropdown-menu .dropdown-item {
    padding: 10px 20px;
    width: 100%; }
    .dropdown .dropdown-menu .dropdown-item:active a, .dropdown .dropdown-menu .dropdown-item.active a {
      color: #fff; }

.show > .dropdown-menu {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  display: block;
  z-index: 990; }

.dropdown-toggle::after {
  font-size: 0.8rem;
  font-family: 'LineAwesome';
  content: "\f110" !important;
  border: none !important;
  position: relative;
  top: 0px;
  right: 4px;
  padding: 0 2px 0 6px;
  margin: 0 0.3em 0 0;
  vertical-align: 0; }

.dropdown-toggle.nav-hide-arrow::after {
  display: none; }

.dropdown-toggle:focus {
  outline: 0; }

.dropright .dropdown-toggle::after {
  content: "\f112" !important; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  font-size: 0.8rem;
  font-family: 'LineAwesome';
  content: "\f111" !important;
  border: none !important;
  position: relative;
  top: 0px;
  left: 0px;
  padding: 0 2px 0 6px;
  margin: 0 0.9em 0 0;
  vertical-align: 0; }

.navbar-horizontal .dropdown-menu .dropdown-submenu > a:after, .navbar-horizontal .dropdown-menu .dropdown-submenu > button:after {
  font-size: 0.85rem;
  right: 12px; }

.navbar-horizontal .dropdown-toggle::after {
  top: 12px !important; }

.navbar-horizontal .nav .dropdown-divider {
  margin: 0.5rem 0 0.5rem -1rem; }

.dropdown-menu-right {
  right: 0; }

.dropdown-menu {
  -webkit-box-shadow: none;
  box-shadow: none; }
  .dropdown-menu .dropdown-submenu {
    position: relative; }
    .dropdown-menu .dropdown-submenu:hover .dropdown-menu {
      display: block; }
    .dropdown-menu .dropdown-submenu > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
      background: transparent;
      -webkit-box-shadow: none !important;
      box-shadow: none !important; }
      .dropdown-menu .dropdown-submenu > .dropdown-menu.open-left {
        left: auto;
        right: 100%; }
      .dropdown-menu .dropdown-submenu > .dropdown-menu .arrow_box {
        background: #fff;
        -webkit-box-shadow: 0px 0px 15px 1px rgba(64, 70, 74, 0.25);
        box-shadow: 0px 0px 15px 1px rgba(64, 70, 74, 0.25);
        padding: 10px 0;
        border-radius: 0.35rem; }
        .dropdown-menu .dropdown-submenu > .dropdown-menu .arrow_box li {
          padding: 0 8px; }
    .dropdown-menu .dropdown-submenu > a:after, .dropdown-menu .dropdown-submenu > button:after {
      font-family: 'LineAwesome';
      content: "\f112" !important;
      right: 15px;
      font-size: 1rem;
      float: right;
      border: none;
      position: absolute; }
    .dropdown-menu .dropdown-submenu :hover > a:after, .dropdown-menu .dropdown-submenu :hover > button:after {
      border-left-color: #555; }
    .dropdown-menu .dropdown-submenu .pull-left {
      float: none; }
      .dropdown-menu .dropdown-submenu .pull-left > .dropdown-menu {
        left: -100%;
        margin-left: 10px; }

.dropdown-header {
  text-transform: uppercase; }
  .dropdown-header.highlight {
    color: #2b2d2f;
    text-decoration: none;
    background-color: #f5f5f5; }

.btn-secondary ~ .dropdown-menu .dropdown-item.active {
  background-color: #cccccc; }

.btn-primary ~ .dropdown-menu .dropdown-item.active {
  background-color: #6967ce; }

.btn-success ~ .dropdown-menu .dropdown-item.active {
  background-color: #5ed84f; }

.btn-danger ~ .dropdown-menu .dropdown-item.active {
  background-color: #fa626b; }

.btn-info ~ .dropdown-menu .dropdown-item.active {
  background-color: #28afd0; }

.btn-warning ~ .dropdown-menu .dropdown-item.active {
  background-color: #fdb901; }

.dropdown-menu.arrow {
  margin-top: 12px; }
  .dropdown-menu.arrow:after, .dropdown-menu.arrow:before {
    position: absolute;
    left: 10px;
    display: inline-block;
    width: 0;
    height: 0;
    content: '';
    border: 7px solid transparent;
    border-top-width: 0; }
  .dropdown-menu.arrow:before {
    top: -7px;
    border-bottom-color: #b3b3b3; }
  .dropdown-menu.arrow:after {
    top: -6px;
    border-bottom-color: #fff; }

.dropdown-menu .arrow-left {
  margin-left: 7px !important; }
  .dropdown-menu .arrow-left:after, .dropdown-menu .arrow-left:before {
    position: absolute;
    top: 10px;
    display: inline-block;
    width: 0;
    height: 0;
    content: '';
    border: 7px solid transparent;
    border-left-width: 0; }
  .dropdown-menu .arrow-left:before {
    left: -8px;
    border-right-color: #b3b3b3; }
  .dropdown-menu .arrow-left:after {
    left: -7px;
    border-right-color: #fff; }

.dropdown-menu .arrow-right {
  margin-right: 7px !important; }
  .dropdown-menu .arrow-right:after, .dropdown-menu .arrow-right:before {
    position: absolute;
    top: 10px;
    display: inline-block;
    width: 0;
    height: 0;
    content: '';
    border: 7px solid transparent;
    border-right-width: 0; }
  .dropdown-menu .arrow-right:before {
    right: -7px;
    border-left-color: #b3b3b3; }
  .dropdown-menu .arrow-right:after {
    right: -6px;
    border-left-color: #fff; }

.dropup .dropdown-toggle::after {
  content: "\f113" !important; }

.dropup .dropdown-menu {
  top: auto; }
  .dropup .dropdown-menu.arrow {
    margin-bottom: 12px; }
    .dropup .dropdown-menu.arrow:after, .dropup .dropdown-menu.arrow:before {
      top: auto;
      border-top-width: 7px;
      border-bottom-width: 0; }
    .dropup .dropdown-menu.arrow:before {
      bottom: -8px;
      border-top-color: #b3b3b3; }
    .dropup .dropdown-menu.arrow:after {
      bottom: -6px;
      border-top-color: #fff; }

.dropup .dropdown-submenu .dropdown-menu {
  bottom: auto;
  top: 0; }

.dropdown-item.buttons-columnVisibility.active {
  color: #000000; }

.drilldown-menu .dropdown-item.active.hover, .drilldown-menu .dropdown-item.active:hover, .drilldown-menu .dropdown-item:active.hover, .drilldown-menu .dropdown-item:active:hover {
  color: #fff; }

@-webkit-keyframes menu-header-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes menu-header-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes menu-header-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes menu-header-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes menu-header-move-up {
  from {
    margin-top: 10px; }
  to {
    margin-top: 0; } }

@-moz-keyframes menu-header-move-up {
  from {
    margin-top: 10px; }
  to {
    margin-top: 0; } }

@-o-keyframes menu-header-move-up {
  from {
    margin-top: 10px; }
  to {
    margin-top: 0; } }

@keyframes menu-header-move-up {
  from {
    margin-top: 10px; }
  to {
    margin-top: 0; } }

/* ===============================================================================================
    File Name: navbar.scss
    Description: Contain header navigation bar, vertical main navigation bar and
    horiznotal main navigation bar related SCSS.
    ----------------------------------------------------------------------------------------------
    Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
    Version: 1.2
    Author: ThemeSelection
    Author URL: https://themeselection.com/
================================================================================================*/
.navbar-dark .hamburger-inner,
.navbar-dark .hamburger-inner::before,
.navbar-dark .hamburger-inner::after {
  background-color: #FFFFFF; }

.navbar-header {
  height: 100%;
  width: 260px;
  height: 5rem;
  z-index: 9;
  position: relative;
  padding: 0rem 0.85rem;
  -webkit-transition: 300ms ease all;
  -o-transition: 300ms ease all;
  -moz-transition: 300ms ease all;
  transition: 300ms ease all; }
  .navbar-header ul.navbar-nav {
    overflow: hidden; }
  .navbar-header .navbar-brand {
    padding: 17px 0px;
    margin-right: 0; }
    .navbar-header .navbar-brand .brand-logo {
    width: 100%;
}
    .navbar-header .navbar-brand .brand-text {
      display: inline;
      padding-left: 6px;
      font-weight: 700;
      font-size: 1.4rem;
      vertical-align: middle; }
  .navbar-header .close-navbar {
    padding: 1.5rem 0rem; }
  .navbar-header.navbar-header-menu-dark {
    background: #2C303B; }
    .navbar-header.navbar-header-menu-dark .brand-text {
      color: #FFFFFF; }
  .navbar-header.navbar-header-menu-light {
    background: #FFFFFF; }
    .navbar-header.navbar-header-menu-light .brand-text {
      color: #464855; }

.header-navbar {
  padding: 0px;
  min-height: 5rem;
  font-family: "Comfortaa", cursive, "Times New Roman", Times, serif;
  -webkit-transition: 300ms ease all;
  -o-transition: 300ms ease all;
  -moz-transition: 300ms ease all;
  transition: 300ms ease all;
  /* For sm screen*/ }
  .header-navbar .navbar-wrapper {
    width: 100%; }
  .header-navbar.navbar-border {
    border-bottom: 1px solid #E4E7ED; }
  .header-navbar.navbar-dark.navbar-border {
    border-bottom: 1px solid #8596B5; }
  .header-navbar.navbar-shadow {
    -webkit-box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.1); }
  .header-navbar.navbar-without-dd-arrow .nav-link.dropdown-toggle::after {
    display: none; }
  .header-navbar.navbar-brand-center .navbar-header {
    width: 210px;
    position: absolute;
    left: 50%;
    padding: 0;
    margin: 0;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    z-index: 999; }
  .header-navbar .navbar-container {
    padding: 0rem 18px;
    height: 5rem;
    -webkit-transition: 300ms ease all;
    -o-transition: 300ms ease all;
    -moz-transition: 300ms ease all;
    transition: 300ms ease all;
    background: inherit; }
    .header-navbar .navbar-container .show > .dropdown-menu .arrow_box_right {
      position: relative; }
      .header-navbar .navbar-container .show > .dropdown-menu .arrow_box_right:after {
        bottom: 100%;
        right: 1rem;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #FFFFFF;
        border-width: 16px;
        margin-left: -16px; }
    .header-navbar .navbar-container .show > .dropdown-menu .arrow_box {
      position: relative; }
      .header-navbar .navbar-container .show > .dropdown-menu .arrow_box:after {
        bottom: 100%;
        left: 1.8rem;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #FFFFFF;
        border-width: 16px;
        margin-left: -16px; }
    .header-navbar .navbar-container .dropdown-submenu.show .arrow_box {
      position: relative; }
      .header-navbar .navbar-container .dropdown-submenu.show .arrow_box:after {
        bottom: 100%;
        top: 7px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        margin-left: -41px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 13px solid #FFFFFF; }
    .header-navbar .navbar-container.navbar-shadow {
      -webkit-box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.1); }
    .header-navbar .navbar-container ul.nav li > a.nav-link {
      padding: 1.9rem 1rem;
      font-size: 1.15rem; }
      .header-navbar .navbar-container ul.nav li > a.nav-link i.flag-icon {
        margin-right: 0.5rem; }
    .header-navbar .navbar-container ul.nav li a.dropdown-user-link {
      padding: 1.2rem 1rem;
      line-height: 23px; }
      .header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-name {
        display: inline-block;
        margin-bottom: 0.4rem;
        margin-left: 0.2rem; }
    .header-navbar .navbar-container ul.nav li a.menu-toggle {
      padding: 1.7rem 1rem 1.6rem 1rem; }
      .header-navbar .navbar-container ul.nav li a.menu-toggle i {
        font-size: 1.6rem; }
    .header-navbar .navbar-container ul.nav li a.nav-link-label {
      padding: 1.7rem 1rem 1.6rem 1rem; }
    .header-navbar .navbar-container ul.nav li a.dropdown-user-link .avatar {
      margin-right: 0.5rem;
      width: 36px; }
    .header-navbar .navbar-container ul.nav li a.nav-link-search,
    .header-navbar .navbar-container ul.nav li a.nav-link-expand {
      padding: 1.7rem 1rem 1.7rem 1rem; }
    .header-navbar .navbar-container ul.nav li div.input-group {
      padding: 0.7rem 1rem; }
    .header-navbar .navbar-container ul.nav li i.ficon {
      font-size: 1.5rem; }
    .header-navbar .navbar-container ul.nav li .media-list {
      max-height: 18.2rem; }
    .header-navbar .navbar-container ul.nav li .scrollable-container {
      position: relative; }
    .header-navbar .navbar-container ul.nav li input.form-control:focus {
      -webkit-box-shadow: 0px 0px 8px #fff;
      box-shadow: 0px 0px 8px #fff; }
    .header-navbar .navbar-container .dropdown-menu-media {
      width: 24rem; }
      .header-navbar .navbar-container .dropdown-menu-media .media-list .media {
        padding: 1rem;
        border: none;
        border-bottom: 1px solid #E4E5EC; }
      .header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-header {
        border-bottom: 1px solid #E4E5EC; }
      .header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-footer a {
        padding: 0.3rem;
        border-top: 1px solid #E4E5EC; }
  .header-navbar.navbar-without-menu .navbar-container {
    margin-left: 0; }
  .header-navbar .nav-item + .nav-item {
    margin-left: 0rem; }
  @media (max-width: 767.98px) {
    .header-navbar .navbar-header {
      width: 100% !important;
      padding: 0.5rem 1rem; }
      .header-navbar .navbar-header .menu-toggle {
        top: 2px;
        position: relative; }
      .header-navbar .navbar-header .open-navbar-container i {
        font-size: 1.8rem; }
      .header-navbar .navbar-header .navbar-brand {
        position: absolute;
        left: 50%;
        top: 0;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0); }
    .header-navbar.navbar-with-menu .navbar-container {
      width: 100%;
      display: table;
      background: #fff;
      padding: 0rem 1rem;
      margin: 0;
      height: auto; } }

.navbar-light {
  background: #FFFFFF; }
  .navbar-light.navbar-horizontal {
    background: #FFFFFF; }
  .navbar-light .navbar-nav > li {
    line-height: 0.9;
    z-index: 1051; }
  .navbar-light .navbar-nav .active.nav-link {
    background-color: rgba(0, 0, 0, 0.03); }
  .navbar-light .navbar-nav .disabled.nav-link {
    color: #6b6f80; }

.navbar-dark {
  background: #2C303B; }
  .navbar-dark.navbar-horizontal {
    background: #2C303B; }
  .navbar-dark .nav-search .form-control,
  .navbar-dark .nav-search .btn-secondary {
    color: #FFFFFF;
    background: #2C303B; }
  .navbar-dark .navbar-brand .brand-text {
    color: #FFFFFF !important; }
  .navbar-dark .navbar-nav > li {
    line-height: 0.9;
    z-index: 1051; }
  .navbar-dark .navbar-nav .active.nav-link {
    background-color: rgba(255, 255, 255, 0.05); }
  .navbar-dark .navbar-nav .disabled.nav-link {
    color: #98A4B8; }

.navbar-semi-dark {
  background: #FFFFFF; }
  .navbar-semi-dark .navbar-header {
    background: #2C303B; }
    .navbar-semi-dark .navbar-header .brand-text {
      color: #FFFFFF; }
  .navbar-semi-dark .navbar-nav > li {
    line-height: 0.9;
    z-index: 1051; }
  .navbar-semi-dark .navbar-nav .nav-link {
    color: #FFFFFF; }
  .navbar-semi-dark .navbar-nav .active.nav-link {
    background-color: rgba(0, 0, 0, 0.03); }
  .navbar-semi-dark .navbar-nav .disabled.nav-link {
    color: #6b6f80; }

.navbar-semi-light {
  background: #6b6f80; }
  .navbar-semi-light .navbar-header {
    background: #FFFFFF; }
    .navbar-semi-light .navbar-header .brand-text {
      color: #464855; }
  .navbar-semi-light .navbar-nav > li {
    line-height: 0.9;
    z-index: 1051; }
  .navbar-semi-light .navbar-nav .nav-link {
    color: #FFFFFF; }
  .navbar-semi-light .navbar-nav .active.nav-link {
    background-color: rgba(0, 0, 0, 0.03); }
  .navbar-semi-light .navbar-nav .disabled.nav-link {
    color: #6b6f80; }

.mega-dropdown {
  position: static !important; }
  .mega-dropdown .dropdown-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }

.navigation-main .mega-dropdown-menu,
.navbar-nav .mega-dropdown-menu {
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  width: -webkit-calc(100% - 50px);
  width: -moz-calc(100% - 50px);
  width: calc(100% - 50px);
  padding: 0.5rem 0;
  /* fallback if needed */
  margin: 0px 25px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-shadow: 0px 5px 75px 2px rgba(64, 70, 74, 0.2);
  box-shadow: 0px 5px 75px 2px rgba(64, 70, 74, 0.2);
  z-index: 9999; }
  .navigation-main .mega-dropdown-menu ul,
  .navbar-nav .mega-dropdown-menu ul {
    padding: 0;
    margin: 0; }
    .navigation-main .mega-dropdown-menu ul li,
    .navbar-nav .mega-dropdown-menu ul li {
      list-style: none; }
      .navigation-main .mega-dropdown-menu ul li a,
      .navbar-nav .mega-dropdown-menu ul li a {
        display: block;
        color: #464855; }
        .navigation-main .mega-dropdown-menu ul li a .children-in,
        .navbar-nav .mega-dropdown-menu ul li a .children-in {
          float: right; }
        .navigation-main .mega-dropdown-menu ul li a:hover,
        .navbar-nav .mega-dropdown-menu ul li a:hover {
          text-decoration: none;
          color: #6b6f80; }
        .navigation-main .mega-dropdown-menu ul li a:focus,
        .navbar-nav .mega-dropdown-menu ul li a:focus {
          text-decoration: none;
          color: #6b6f80; }
    .navigation-main .mega-dropdown-menu ul a.dropdown-item,
    .navbar-nav .mega-dropdown-menu ul a.dropdown-item {
      padding: 10px; }
  .navigation-main .mega-dropdown-menu p.accordion-text,
  .navbar-nav .mega-dropdown-menu p.accordion-text {
    line-height: 1.45; }
  .navigation-main .mega-dropdown-menu .dropdown-menu-header,
  .navbar-nav .mega-dropdown-menu .dropdown-menu-header {
    padding: 0.8rem 0;
    border-bottom: 1px solid #E4E5EC; }
  .navigation-main .mega-dropdown-menu h6 i.la,
  .navbar-nav .mega-dropdown-menu h6 i.la {
    font-size: 1.1rem; }

.horizontal-top-icon-menu .mega-dropdown-menu {
  margin: 0px 25px !important; }

@media (min-width: 576px) {
  .container .mega-dropdown-menu {
    max-width: 540px;
    margin: 0 -webkit-calc((100% - 540px)/2);
    margin: 0 -moz-calc((100% - 540px)/2);
    margin: 0 calc((100% - 540px)/2); } }

@media (min-width: 768px) {
  .container .mega-dropdown-menu {
    max-width: 720px;
    margin: 0 -webkit-calc((100% - 720px)/2);
    margin: 0 -moz-calc((100% - 720px)/2);
    margin: 0 calc((100% - 720px)/2); } }

@media (min-width: 992px) {
  .container .mega-dropdown-menu {
    max-width: 960px;
    margin: 0 -webkit-calc((100% - 960px)/2);
    margin: 0 -moz-calc((100% - 960px)/2);
    margin: 0 calc((100% - 960px)/2); } }

@media (min-width: 1200px) {
  .container .mega-dropdown-menu {
    max-width: 1140px;
    margin: 0 -webkit-calc((100% - 1140px)/2);
    margin: 0 -moz-calc((100% - 1140px)/2);
    margin: 0 calc((100% - 1140px)/2); } }

@-moz-document url-prefix() {
  ul li a .children-in {
    position: relative;
    right: 3px;
    top: -14px; } }

.menu-list {
  list-style: none;
  padding: 0;
  overflow: hidden; }

.menu-panel {
  padding: 0; }

.sliding-menu {
  overflow: hidden;
  position: relative; }
  .sliding-menu ul {
    float: left;
    margin: 0;
    padding: 0; }
    .sliding-menu ul li {
      list-style: none;
      margin: 0; }

.navbar-menu-icon {
  width: 24px;
  margin: 16px 2px !important;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }

.navbar-menu-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #6b6f80;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

.navbar-menu-icon span:nth-child(1) {
  top: 0px; }

.navbar-menu-icon span:nth-child(2) {
  top: 10px; }

.navbar-menu-icon span:nth-child(3) {
  top: 20px; }

.navbar-menu-icon.show span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg); }

.navbar-menu-icon.show span:nth-child(2) {
  opacity: 0;
  left: -60px; }

.navbar-menu-icon.show span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg); }

@media (max-width: 767.98px) {
  .header-navbar .navbar-nav .show {
    position: static; }
  .header-navbar .navbar-nav .open-navbar-container {
    padding-top: 0.625rem; }
  .header-navbar .navbar-container .show .dropdown-menu {
    right: 0;
    left: 0 !important;
    float: none;
    width: auto;
    margin-top: 0;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: hidden; }
  .header-navbar .navbar-container ul.nav li.nav-item i {
    margin-right: 0.2rem; }
  .header-navbar .navbar-container ul.nav li > a.nav-link {
    padding: 1.5rem 0.4rem; }
    .header-navbar .navbar-container ul.nav li > a.nav-link i.flag-icon {
      margin-right: 0.2rem; }
  .header-navbar .navbar-container ul.nav li a.dropdown-user-link {
    padding: 0.8rem 0.6rem; }
  .header-navbar .navbar-container ul.nav li a.nav-link-label {
    padding: 1.5rem 0.6rem; }
  .header-navbar .navbar-container ul.nav li a.nav-link-search,
  .header-navbar .navbar-container ul.nav li a.nav-link-expand {
    padding: 1.4rem 1rem 1rem 1rem; }
  .header-navbar .navbar-container ul.nav li .dropdown-toggle::after {
    margin-right: 0;
    margin-left: -2px; }
  .navbar-dark .hamburger-inner,
  .navbar-dark .hamburger-inner::before,
  .navbar-dark .hamburger-inner::after,
  .navbar-semi-dark .hamburger-inner,
  .navbar-semi-dark .hamburger-inner::before,
  .navbar-semi-dark .hamburger-inner::after {
    background-color: #FFFFFF; }
  .navbar-dark .navbar-header .navbar-nav .nav-link,
  .navbar-semi-dark .navbar-header .navbar-nav .nav-link {
    color: #FFFFFF; }
  .navbar-light .navbar-header .navbar-nav .nav-link,
  .navbar-semi-light .navbar-header .navbar-nav .nav-link {
    color: #2C303B; }
  .navbar-light .navbar-nav .nav-link,
  .navbar-semi-light .navbar-nav .nav-link {
    color: #FFFFFF; } }

@media (min-width: 576px) {
  .search-box input {
    width: 240px; }
  .search-box .form-control-position {
    cursor: pointer; } }

.headroom {
  will-change: transform;
  -webkit-transition: -webkit-transform 200ms linear;
  transition: -webkit-transform 200ms linear;
  -o-transition: -o-transform 200ms linear;
  -moz-transition: transform 200ms linear, -moz-transform 200ms linear;
  transition: transform 200ms linear;
  transition: transform 200ms linear, -webkit-transform 200ms linear, -moz-transform 200ms linear, -o-transform 200ms linear; }

.headroom--pinned-top {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

.headroom--unpinned-top {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.headroom--pinned-bottom {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%); }

.headroom--unpinned-bottom {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%); }

@media (max-width: 1199.98px) {
  .header-navbar .navbar-container ul.nav li > a.nav-link i.flag-icon {
    margin-right: 0.2rem; }
  .header-navbar .navbar-container ul.nav li a.dropdown-user-link {
    padding: 0.8rem 0.6rem; }
  .header-navbar .navbar-container ul.nav li .dropdown-toggle::after {
    margin-right: 0;
    margin-left: -2px; } }

@media (max-width: 1199.98px) {
  .header-navbar .navbar-container ul.nav li .selected-language {
    display: none; }
  .header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-name {
    display: none; } }

@media (max-width: 767px) {
  #navbar-mobile.navbar-collapse .navbar-nav {
    margin: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
    #navbar-mobile.navbar-collapse .navbar-nav .dropdown-menu {
      position: absolute; }
    #navbar-mobile.navbar-collapse .navbar-nav .nav-item {
      float: left; }
  #navbar-mobile.navbar-collapse .dropdown-notification .badge-up {
    position: relative;
    top: -18px;
    right: 14px; } }

/* ----------- iPhone 5, 5S  iPhone 6----------- */
/* Landscape */
@media only screen and (min-device-width: 26.78em) and (max-device-width: 47.64em) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .header-navbar .navbar-container .show .dropdown-menu {
    max-height: 180px; } }

/* ----------- iPhone 6+ ----------- */
/* Landscape */
@media only screen and (min-device-width: 29.57em) and (max-device-width: 52.57em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .header-navbar .navbar-container .show .dropdown-menu {
    max-height: 280px; } }

.card-group, .card-deck {
  margin-bottom: 0.75rem; }

.card {
  margin-bottom: 1.875rem;
  border: none;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07);
  box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07); }
  .card .card {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .card .card-title {
    font-weight: 700;
    letter-spacing: 0.05rem;
    font-size: 1.12rem; }
  .card .card-bordered {
    border: 1px solid rgba(0, 0, 0, 0); }
  .card.card-fullscreen {
    display: block;
    z-index: 9999;
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto; }
  .card .card-body[class*='border-bottom-'] {
    border-bottom-width: 2px !important; }
  .card .card-img-overlay.bg-overlay {
    background: rgba(0, 0, 0, 0.45); }
  .card .card-img-overlay .text-muted {
    color: #464855 !important; }
  .card.card-minimal {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .card.crypto-card-3 .card-body:before {
    font-family: "cryptocoins";
    font-size: 10rem;
    color: rgba(255, 255, 255, 0.15);
    position: absolute;
    bottom: -32px;
    right: 0px; }

.card-moved .card, .card-moved .card-header {
  background-color: #fdb901;
  color: #FFFFFF; }

.handle, .titleArea {
  cursor: move; }

.card-subtitle {
  padding-bottom: 1rem; }

.card-header {
  border-bottom: none; }
  .card-header .card-title {
    margin-bottom: 0; }
  .card-header .heading-elements,
  .card-header .heading-elements-toggle {
    background-color: inherit;
    position: absolute;
    top: 20px;
    right: 20px; }
    .card-header .heading-elements.heading-top-elements .page-item,
    .card-header .heading-elements-toggle.heading-top-elements .page-item {
      display: inline-block; }
    .card-header .heading-elements a,
    .card-header .heading-elements-toggle a {
      padding: 0 8px; }
      .card-header .heading-elements a.btn,
      .card-header .heading-elements-toggle a.btn {
        padding-top: 6px;
        padding-bottom: 6px; }

.card-footer {
  border-top: 1px solid #EEEEEE; }

.text-white .card-text {
  color: white; }

.text-white .card-img-overlay .text-muted {
  color: #FFF !important; }

.text-white code {
  background-color: white; }

.text-white .heading-elements i {
  color: #FFF; }

.card-head-inverse {
  color: #FFF; }
  .card-head-inverse .heading-elements i {
    color: #FFF; }

.card-transparent {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.card-image-position-right {
  margin-left: 50px;
  position: absolute;
  top: -27px; }

.card-image-position-right-mid {
  position: absolute;
  top: 23px;
  margin-left: 280px;
  width: 250px; }

.card-font-style-black {
  font-size: 90px;
  font-weight: 900;
  color: #ffffff52;
  margin-top: -12px; }

.card-font-style-white {
  font-size: 72px;
  font-weight: 900;
  margin-top: -10px; }

.background-round {
  background-color: rgba(0, 0, 0, 0.18);
  padding: 8px;
  border-radius: 50%; }

.card-accordion .card .card, .card-collapse .card .card {
  margin-bottom: 0; }

.card-accordion .card-header, .card-collapse .card-header {
  position: relative; }
  .card-accordion .card-header .btn, .card-collapse .card-header .btn {
    padding: 0; }
  .card-accordion .card-header:after, .card-collapse .card-header:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e7e9f1; }

@media (max-width: 991.98px) {
  .heading-elements .list-inline {
    display: none; }
  .heading-elements.visible {
    display: block;
    margin-top: 0;
    top: 100%;
    top: 37px;
    height: auto;
    left: 0px;
    padding: 10px;
    z-index: 997;
    text-align: right;
    position: absolute;
    width: 100%; }
    .heading-elements.visible .list-inline {
      display: block; } }

@media (min-width: 992px) {
  .heading-elements-toggle {
    display: none; } }

.breadcrumb {
  font-size: 1rem;
  font-family: "Comfortaa", cursive, "Times New Roman", Times, serif;
  background-color: transparent;
  padding: 0 0 0.75rem 0rem; }
  .breadcrumb > li + li::before {
    padding-right: .6rem;
    padding-left: .6rem; }
  .breadcrumb .breadcrumb-item.active {
    color: #FFFFFF; }
  .breadcrumb .breadcrumb-item a {
    color: rgba(255, 255, 255, 0.8); }
    .breadcrumb .breadcrumb-item a:hover {
      color: #FFFFFF; }
  .breadcrumb .breadcrumb-item + .breadcrumb-item {
    padding-left: 0; }
    .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
      content: "-"; }

/* Custom scss for page headers style */
.breadcrumbs-right .breadcrumb {
  float: right; }

.breadcrumbs-top .breadcrumb {
  margin: 0;
  padding: 0; }

.breadcrumb-new .content-header-title {
  padding: 6px 24px 8px 0;
  margin: 0 24px 0 0;
  border-right: 1px solid rgba(255, 255, 255, 0.3); }

@media (max-width: 767.98px) {
  .breadcrumb {
    font-size: 0.85rem; }
  .breadcrumbs-top .breadcrumb {
    padding-bottom: 0.5rem; } }

.pagination {
  margin-top: 1rem;
  white-space: nowrap; }
  .pagination .page-link {
    color: #6967ce;
    border: 1px solid #BABFC7; }

.pager {
  padding-left: 0;
  margin: 1.45 0;
  list-style: none;
  text-align: center; }
  .pager li {
    display: inline; }
    .pager li > a,
    .pager li > span {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid #626E82;
      border-radius: 0.25rem; }
    .pager li > a:hover,
    .pager li > a:focus {
      text-decoration: none;
      background-color: #F9FAFD; }
  .pager.pager-round li a {
    border-radius: 2rem; }
  .pager .next > a,
  .pager .next > span {
    float: right; }
  .pager .previous > a,
  .pager .previous > span {
    float: left; }
  .pager .disabled > a,
  .pager .disabled > a:hover,
  .pager .disabled > a:focus,
  .pager .disabled > span {
    color: #6b6f80;
    background-color: #fff; }
  .pager [class*="text-"] .pager {
    text-align: inherit; }

.badge {
  color: #FFFFFF;
  font-weight: 400; }
  .badge[class*='badge-'] [class*='icon-'] {
    line-height: 1; }
    .badge[class*='badge-'] [class*='icon-'] ~ span {
      position: relative;
      bottom: 2px;
      padding: 0 5px; }
  .badge[class*='badge-'] span {
    position: relative;
    bottom: 2px;
    padding: 0 5px; }
  .badge[class*='badge-'] a {
    color: #fff; }
  .badge[class*='badge-'] .dropdown-toggle::after, .badge[class*='badge-'].dropdown-toggle::after {
    position: relative;
    top: -2px;
    right: 3px;
    font-size: 1rem; }
  .badge[class*='badge-'] .dropdown-menu a {
    color: #555; }
  .badge.badge-border {
    background-color: transparent;
    border-width: 2px !important;
    font-size: 85%; }
  .badge.badge.float-right {
    margin-left: 0.5rem;
    margin-top: 0.2rem; }

.badge-xl {
  font-size: 1.8rem; }

.badge-lg {
  font-size: 1.2rem; }

.badge-md {
  font-size: 1rem; }

.badge-sm {
  font-size: 0.8rem;
  padding-top: 0.45rem; }

.badge-secondary {
  background-color: #6b6f80; }
  .badge-secondary.badge-glow {
    -webkit-box-shadow: 0px 0px 10px #6b6f80;
    box-shadow: 0px 0px 10px #6b6f80; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    background-color: #545764; }

.badge-primary {
  background-color: #6967ce; }
  .badge-primary.badge-glow {
    -webkit-box-shadow: 0px 0px 10px #6967ce;
    box-shadow: 0px 0px 10px #6967ce; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    background-color: #4340c2; }

.badge-success {
  background-color: #5ed84f; }
  .badge-success.badge-glow {
    -webkit-box-shadow: 0px 0px 10px #5ed84f;
    box-shadow: 0px 0px 10px #5ed84f; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    background-color: #3dc82c; }

.badge-info {
  background-color: #28afd0; }
  .badge-info.badge-glow {
    -webkit-box-shadow: 0px 0px 10px #28afd0;
    box-shadow: 0px 0px 10px #28afd0; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    background-color: #208ba5; }

.badge-warning {
  background-color: #fdb901; }
  .badge-warning.badge-glow {
    -webkit-box-shadow: 0px 0px 10px #fdb901;
    box-shadow: 0px 0px 10px #fdb901; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    background-color: #ca9401; }

.badge-danger {
  background-color: #fa626b; }
  .badge-danger.badge-glow {
    -webkit-box-shadow: 0px 0px 10px #fa626b;
    box-shadow: 0px 0px 10px #fa626b; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    background-color: #f8313c; }

.badge-up {
  position: absolute;
  top: 13px;
  right: 13px; }

.badge-icon i {
  font-size: 100%;
  margin-right: 5px; }

.form-control + .block-tag {
  display: block; }

.block-tag {
  margin-top: 0.5rem; }

.block-area {
  padding: 0.24rem 0.3rem; }

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #6967ce;
  font-size: 100%;
  font-weight: 400;
  padding: 0; }

.token.punctuation {
  color: #6967ce; }

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #28afd0; }

.token.atrule, .token.attr-value, .token.keyword {
  color: #fa626b; }

code[class*="language-"], pre[class*="language-"] {
  color: #6b6f80; }

.nav {
  border-radius: 0.25rem; }
  .nav.wrap-border {
    border: 1px solid #ddd; }
    .nav.wrap-border li.nav-header {
      margin: 0 0.5rem; }
  .nav.no-hover-bg .nav-item:hover {
    background-color: transparent; }
  .nav .dropdown .dropdown-item.active {
    background-color: transparent;
    color: #55595c; }
    .nav .dropdown .dropdown-item.active:focus {
      background-color: transparent;
      color: #55595c; }
  .nav.square-border {
    border-radius: 0; }
    .nav.square-border .nav-item .nav-link.active {
      border-radius: 0; }
  .nav .nav-item .nav-link {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
  .nav .nav-item i {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center; }
  .nav .dropdown-divider {
    margin: 0.5rem 0; }
  .nav .modern-nav-toggle {
    padding: 25px 0px; }
  .nav.nav-pills .nav-item .nav-link {
    padding: 1rem 1.5rem;
    line-height: normal;
    border-radius: 2rem; }
    .nav.nav-pills .nav-item .nav-link i {
      font-size: 1.4rem; }
    .nav.nav-pills .nav-item .nav-link.active {
      border-radius: 2rem; }
    .nav.nav-pills .nav-item .nav-link i.float-right:before {
      position: relative;
      top: 7px;
      left: 7px; }
    .nav.nav-pills .nav-item .nav-link .badge.float-right {
      position: relative;
      top: 1px;
      left: 3px; }
    .nav.nav-pills .nav-item .nav-link.dropdown-toggle::after {
      top: -1px; }
  .nav.nav-pills.nav-justified {
    width: 100%; }
    .nav.nav-pills.nav-justified .nav-item {
      float: none; }
    .nav.nav-pills.nav-justified .nav-link {
      text-align: center;
      margin-bottom: 5px; }
    .nav.nav-pills.nav-justified > .dropdown .dropdown-menu {
      top: auto;
      left: auto; }
    @media (min-width: 576px) {
      .nav.nav-pills.nav-justified .nav-item {
        display: table-cell;
        width: 1%; }
      .nav.nav-pills.nav-justified .nav-link {
        margin-bottom: 0; } }
    .nav.nav-pills.nav-justified .nav-link {
      margin-right: 0;
      border-radius: 0.25rem; }
    @media (min-width: 576px) {
      .nav.nav-pills.nav-justified .nav-link.active,
      .nav.nav-pills.nav-justified .nav-link.active:hover,
      .nav.nav-pills.nav-justified .nav-link.active:focus {
        border-bottom-color: transparent; } }
    .nav.nav-pills.nav-justified .nav-link {
      display: block;
      border-radius: 2rem; }
      .nav.nav-pills.nav-justified .nav-link.active {
        border: none; }
        .nav.nav-pills.nav-justified .nav-link.active:hover, .nav.nav-pills.nav-justified .nav-link.active:focus {
          border: none; }
  .nav.nav-pills.nav-pill-with-active-bordered .nav-item a.nav-link {
    background-color: #ffffff;
    color: #55595c; }
    .nav.nav-pills.nav-pill-with-active-bordered .nav-item a.nav-link.active {
      border: 1px solid #6967ce;
      color: #6967ce; }
  .nav.nav-pills.nav-pill-toolbar.nav-stacked .nav-item ~ .nav-item a.nav-link {
    border-left: 1px solid #d3d3d3;
    border-top: 0; }
  .nav.nav-pills.nav-pill-toolbar.nav-stacked .nav-item:first-child a.nav-link {
    border-radius: 0.25rem 0.25rem 0 0; }
  .nav.nav-pills.nav-pill-toolbar.nav-stacked .nav-item:last-child a.nav-link {
    border-radius: 0 0 0.25rem 0.25rem; }
  .nav.nav-pills.nav-pill-toolbar .nav-item {
    margin-top: 0;
    margin-left: 0; }
    .nav.nav-pills.nav-pill-toolbar .nav-item a.nav-link {
      border: 1px solid #d3d3d3;
      border-radius: 0; }
      .nav.nav-pills.nav-pill-toolbar .nav-item a.nav-link.active, .nav.nav-pills.nav-pill-toolbar .nav-item a.nav-link:active {
        border-color: #6967ce; }
  .nav.nav-tabs .nav-item .nav-link {
    padding: 1rem 1.5rem;
    line-height: normal;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex; }
    .nav.nav-tabs .nav-item .nav-link i {
      font-size: 1.4rem;
      padding-right: 0.5rem; }
    .nav.nav-tabs .nav-item .nav-link:hover:not(.active) {
      border-color: transparent; }
    .nav.nav-tabs .nav-item .nav-link.active {
      border-radius: 0.25rem;
      -webkit-box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3); }
    .nav.nav-tabs .nav-item .nav-link i.float-right {
      margin-left: 0.5rem; }
    .nav.nav-tabs .nav-item .nav-link.dropdown-toggle::after {
      top: -1px; }
  .nav.nav-tabs .nav-item:hover {
    color: #333; }
  .nav.nav-tabs.wrap-border {
    border: 1px solid #ddd; }
  .nav.nav-tabs.no-hover-bg .nav-item:hover {
    background-color: transparent; }
  .nav.nav-tabs .dropdown .dropdown-item.active {
    background-color: transparent;
    color: #55595c; }
    .nav.nav-tabs .dropdown .dropdown-item.active:focus {
      background-color: transparent;
      color: #55595c; }
  .nav .nav-item .nav-link {
    display: block; }
  .nav .dropdown-divider {
    margin: 0.5rem 0; }

.nav-vertical {
  overflow: hidden; }
  .nav-vertical .nav-left.nav-tabs {
    float: left;
    border-bottom: 0;
    border-radius: 0;
    display: table; }
    .nav-vertical .nav-left.nav-tabs li.nav-item {
      float: none;
      margin: 0;
      margin-right: -1px; }
      .nav-vertical .nav-left.nav-tabs li.nav-item a.nav-link {
        min-width: 6.5rem; }
        .nav-vertical .nav-left.nav-tabs li.nav-item a.nav-link.active {
          border-right: 0;
          border-radius: 0.25rem; }
        .nav-vertical .nav-left.nav-tabs li.nav-item a.nav-link:hover:not(.active) {
          border-color: transparent; }
    .nav-vertical .nav-left.nav-tabs.nav-border-left li.nav-item a.nav-link {
      color: #fa626b; }
      .nav-vertical .nav-left.nav-tabs.nav-border-left li.nav-item a.nav-link.active {
        border-left: 3px solid #fa626b;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: #555; }
  .nav-vertical .nav-left ~ .tab-content {
    margin-left: 45px; }
    .nav-vertical .nav-left ~ .tab-content .tab-pane {
      display: none;
      background-color: #fff;
      padding: 0 0 0.6rem 1rem;
      overflow-y: auto; }
      .nav-vertical .nav-left ~ .tab-content .tab-pane.active {
        display: block; }
  .nav-vertical .nav-right.nav-tabs {
    float: right;
    border-bottom: 0;
    border-radius: 0;
    display: table; }
    .nav-vertical .nav-right.nav-tabs li.nav-item {
      float: none;
      margin: 0;
      margin-left: -1px; }
      .nav-vertical .nav-right.nav-tabs li.nav-item a.nav-link {
        min-width: 6.5rem; }
        .nav-vertical .nav-right.nav-tabs li.nav-item a.nav-link.active {
          border-left: 0;
          border-radius: 0.25rem; }
        .nav-vertical .nav-right.nav-tabs li.nav-item a.nav-link:hover:not(.active) {
          border-color: transparent; }
    .nav-vertical .nav-right.nav-tabs.nav-border-right li.nav-item a.nav-link {
      color: #fa626b; }
      .nav-vertical .nav-right.nav-tabs.nav-border-right li.nav-item a.nav-link.active {
        border-right: 3px solid #fa626b;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        color: #555; }
  .nav-vertical .nav-right ~ .tab-content .tab-pane {
    display: none;
    background-color: #fff;
    padding: 0;
    overflow-y: auto; }
    .nav-vertical .nav-right ~ .tab-content .tab-pane.active {
      display: block; }

.nav.nav-tabs.nav-only-icon .nav-item .nav-link {
  font-size: 1.25rem; }
  .nav.nav-tabs.nav-only-icon .nav-item .nav-link i {
    margin-left: 0.5rem; }

.nav.nav-tabs .nav-item.show .nav-link,
.nav.nav-tabs .nav-item.show .nav-link:focus {
  background-color: transparent;
  color: #6967ce; }

.nav-link {
  color: #6967ce; }
  .nav-link.active {
    color: #fa626b; }
  .nav-link.disabled {
    color: #626E82; }

.alert {
  position: relative; }
  .alert .alert-link:hover {
    text-decoration: underline; }
  .alert.alert-icon-left {
    border-left-width: 4rem; }
  .alert.alert-icon-right {
    border-right-width: 4rem; }
  .alert.alert-icon-left[class*=bg-] {
    border-left-color: rgba(0, 0, 0, 0.15) !important; }
  .alert.alert-icon-right[class*=bg-] {
    border-right-color: rgba(0, 0, 0, 0.15) !important; }
  .alert[class*=alert-icon-] .alert-icon {
    content: '\f2b2';
    font-family: LineAwesome;
    color: #fff;
    width: 4rem;
    left: -4rem;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .alert .close {
    font-size: 2rem;
    line-height: 1rem;
    font-weight: 300;
    text-shadow: none;
    opacity: 1; }
  .alert[class*=bg-] {
    color: #fff; }
    .alert[class*=bg-] .alert-link {
      color: #fff; }
    .alert[class*=bg-] .close {
      color: #fff;
      opacity: 1;
      text-shadow: none; }
  .alert.alert-icon-right .alert-icon {
    left: auto;
    right: -4rem; }
  .alert[class*=alert-arrow-]:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    border-left: 8px solid;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left-color: inherit;
    margin-top: -8px; }
  .alert.alert-arrow-right:before {
    left: auto;
    right: 0;
    border-left: 0;
    border-right: 8px solid;
    border-right-color: inherit; }

.media-list .media {
  padding: 1.5rem;
  margin-top: 0; }
  .media-list .media .media-left {
    padding-right: 1rem;
    float: left; }
  .media-list .media .media-right {
    padding-left: 1rem;
    float: right; }

.media-list a.media {
  color: #6b6f80 !important; }

.media [class*="avatar-"] {
  position: relative;
  padding-right: 0 !important;
  margin-right: 0; }
  .media [class*="avatar-"] i {
    position: absolute;
    right: -4px;
    bottom: -1px;
    width: 10px;
    height: 10px;
    border: 2px solid white;
    border-radius: 100%; }

.media-notation a {
  margin-right: 0.5rem; }

ul.media-list li {
  line-height: 1.5rem; }

/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
  Version: 1.2
  Author: ThemeSelection
  Author URL: https://themeselection.com/
  ----------------------------------------------------------------------------------------

  Modifiers:       `progress-lg`
                   `progress-md`
                   `progress-sm`
                   `progress-xs`

==========================================================================================*/
.progress {
  margin-bottom: 3rem; }
  .progress:last-child {
    margin-bottom: 1rem; }
  .progress.progress-xl {
    height: 2rem !important; }
  .progress.progress-lg {
    height: 1.5rem !important; }
  .progress.progress-md {
    height: 1rem !important; }
  .progress.progress-sm {
    height: 0.5rem !important; }
  .progress.progress-xs {
    height: 0.25rem !important; }
  .progress.box-shadow {
    -webkit-box-shadow: 0px 10px 15px 0px rgba(107, 111, 128, 0.3);
    box-shadow: 0px 10px 15px 0px rgba(107, 111, 128, 0.3); }

/* ===============================================================================================
    File Name: list-group.scss
    Description: Contain list item, list group related extended SCSS.
    ----------------------------------------------------------------------------------------------
    Item Name: Chameleon Admin - Modern Bootstrap 4 WebApp & Dashboard HTML Template + UI Kit
    Version: 1.2
    Author: ThemeSelection
    Author URL: https://themeselection.com/
================================================================================================*/
ul.list-inline li {
  display: inline-block; }

ul.list-inline.list-inline-pipe > li + li:before {
  content: ' | ';
  color: #626E82; }

.list-group .list-group-item.active {
  color: #fff !important; }

.list-group .list-group-item-action:active {
  color: #fff;
  background-color: #6967ce; }
  .list-group .list-group-item-action:active a {
    color: #fff; }

.users-list li + li {
  margin-left: -14px; }

.users-list li img {
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(107, 111, 128, 0.3);
  box-shadow: 0px 2px 10px 0px rgba(107, 111, 128, 0.3); }

.users-list li .badge {
  background: rgba(255, 255, 255, 0.8);
  color: #6b6f80;
  margin-bottom: 6px; }

.toast-bs-container {
  z-index: 1040;
  position: fixed;
  bottom: 1.5rem;
  left: 0;
  width: 100%; }
  .toast-bs-container .toast-position {
    position: relative;
    bottom: 1.5rem;
    right: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse; }
    .toast-bs-container .toast-position .toast.show {
      opacity: 1;
      z-index: 1030; }

.toast-placement {
  position: static;
  bottom: 1.5rem; }
  .toast-placement .toast {
    position: absolute;
    bottom: 1.5rem;
    z-index: 1030; }

.toast {
  display: none;
  background-color: white; }

.toast.show.toast-wrapper {
  position: static; }

.toast.toast-translucent {
  opacity: 1;
  display: block; }

.toast-header img {
  width: 20px;
  height: 20px; }

.modal-content {
  -webkit-box-shadow: 0px 10px 50px 0px rgba(70, 72, 85, 0.8) !important;
  box-shadow: 0px 10px 50px 0px rgba(70, 72, 85, 0.8) !important; }

.modal-sm {
  max-width: 400px; }

.modal-xs {
  max-width: 300px; }

.modal-xl {
  max-width: 94%;
  margin-left: 3%;
  margin-right: 3%; }

.icon-align {
  margin-top: 0.6rem;
  margin-left: 0.5rem; }

.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
  overflow: visible; }

.popover {
  -webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2); }
  .popover.popover-top:after, .popover.popover-top:before, .popover.bs-tether-element-attached-top:after, .popover.bs-tether-element-attached-top:before {
    margin-left: -10px; }
  .popover.popover-right:after, .popover.popover-right:before, .popover.bs-tether-element-attached-right:after, .popover.bs-tether-element-attached-right:before {
    margin-top: -10px; }
  .popover.popover-bottom:after, .popover.popover-bottom:before, .popover.bs-tether-element-attached-bottom:after, .popover.bs-tether-element-attached-bottom:before {
    margin-left: -10px; }
  .popover.popover-left:after, .popover.popover-left:before, .popover.bs-tether-element-attached-left:after, .popover.bs-tether-element-attached-left:before {
    margin-top: -10px; }

.icon-bg-circle {
  color: #FFFFFF;
  padding: 0.5rem;
  border-radius: 50%; }

.icon-left {
  margin-right: 0.5rem; }

.icon-right {
  margin-right: 0.5rem; }

.icon-spin {
  display: inline-block;
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear; }

.icon-shadow {
  width: 65px;
  height: 65px;
  max-width: 65px;
  max-width: 50px;
  width: 50px;
  height: 50px;
  line-height: 65px;
  border-radius: 12%;
  text-align: center;
  vertical-align: middle;
  background-color: #fff;
  position: relative; }
  .icon-shadow.icon-shadow-primary {
    box-shadow: 0 2px 15px #b5b4e7;
    -webkit-box-shadow: 0 2px 15px #b5b4e7; }
  .icon-shadow.icon-shadow-success {
    box-shadow: 0 2px 15px #84e179;
    -webkit-box-shadow: 0 2px 15px #84e179; }
  .icon-shadow.icon-shadow-warning {
    box-shadow: 0 2px 15px #fed566;
    -webkit-box-shadow: 0 2px 15px #fed566; }
  .icon-shadow.icon-shadow-info {
    box-shadow: 0 2px 15px #79d0e5;
    -webkit-box-shadow: 0 2px 15px #79d0e5; }
  .icon-shadow.icon-shadow-danger {
    box-shadow: 0 2px 15px #fc939a;
    -webkit-box-shadow: 0 2px 15px #fc939a; }

.icon-opacity {
  opacity: 0.5; }

.blockOverlay {
  z-index: 1050 !important; }

.blockElement, .blockPage {
  z-index: 1051 !important; }

.embed-responsive:before {
  padding-top: 57%; }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@-o-keyframes spin {
  from {
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.hidden {
  display: none;
  visibility: hidden; }

a:hover {
  cursor: pointer; }

.round {
  border-radius: 1.5rem; }

.square {
  border-radius: 0; }

.mega-dropdown-menu p.accordion-text {
  line-height: 1.45; }

.mega-dropdown-menu a[aria-expanded="true"] {
  padding-bottom: 1rem; }

.line-on-side {
  border-bottom: 1px solid #dadada;
  line-height: 0.1em;
  margin: 10px 0 20px; }

.line-on-side span {
  background: #fff;
  padding: 0 10px; }

.icon-line-height {
  line-height: 1.5rem !important; }

.table-middle td {
  vertical-align: middle; }

.spinner {
  display: inline-block;
  -webkit-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }
